<template>
  <div>
    <div class="AutopartsGroups__header">
      <p>{{ carName }}</p>
    </div>
    <TreeNavigation :nodes="nodes"></TreeNavigation>
  </div>
</template>

<script>
/* global $ */
// https://material.io/components/navigation-drawer/#anatomy
import Vue from 'vue';
import TreeNavigation from './components/TreeNavigation';
import TreeNavigationItem from './components/TreeNavigation/components/TreeNavigationItem';

export default {
  name: 'AutopartsGroups',
  components: {
    TreeNavigation,
    TreeNavigationItem,
  },
  data() {
    return {
      nodes: [],
      carName: '',
    };
  },
  created() {
    this.parseFromDom();
  },

  methods: {
    parseFromDom() {
      const tree = [];

      this.carName = $('#autogrpMinBil')
        .find('strong')
        .first()
        .text();

      $('#autopartgroups .group-container').each((i, groupContainer) => {
        const groups = [];
        $(groupContainer)
          .find('.group')
          .each((j, group) => {
            const subgroups = [];
            $(group)
              .find('.sgroup')
              .each((k, subgroup) => {
                const node = {
                  name: $(subgroup)
                    .find('a')
                    .text()
                    .trim(),
                  url: $(subgroup)
                    .find('a')
                    .attr('href'),
                  selected: $(subgroup).hasClass('midGreyBG'),
                };
                subgroups.push(node);
              });

            const bgImage = $(group)
              .find('.holder')
              .css('background-image');

            const node = {
              name: $(group)
                .find('.autogrp a')
                .text()
                .trim(),
              icon: bgImage ? bgImage.split(/"/)[1] : '',
              url: $(group)
                .find('.autogrp a')
                .attr('href'),
              children: subgroups,
              expanded: $(group).find('.selected').length > 0,
              selected: $(group).find('.selected').length > 0,
            };
            groups.push(node);
          });

        const node = {
          name: $(groupContainer)
            .find('.group-headline')
            .text()
            .trim(),
          icon: $(groupContainer)
            .find('.group-headline img')
            .attr('src')
            .trim(),
          children: groups,
          expanded: !$(groupContainer).hasClass('is-collapsed'),
          selected: $(groupContainer).find('.selected').length > 0,
        };
        tree.push(node);
      });
      this.nodes = tree;
    },
  },
};
</script>
<style scoped>
.AutopartsGroups__header {
  text-align: center;
  display: block;
  position: relative;
  margin: 0;
  padding: 22px;

  padding-top: 36px;
  color: #4a4a4a;
}
.AutopartsGroups__header h2 {
  display: block;
  font-size: 20px;
  margin-bottom: 0.3em;
}
.AutopartsGroups__header p {
  text-align: center;
  color: #3071a9;
  font-weight: bold;
  font-size: 20px;
  width: 100%;
  display: block;

  white-space: nowrap;
  overflow: hidden !important;
  text-overflow: ellipsis;
}
</style>
