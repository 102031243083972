<template>
  <div :class="['IconButton']">
    <button
      :disabled="disabled"
      class="IconButton__hitbox"
      @click.prevent="$emit('click')"
    ></button>
    <slot name="default">
      <i class="fas fa-heart"></i>
    </slot>
  </div>
</template>

<script>
export default {
  name: 'IconButton',
  props: {
    size: {
      type: String,
      default: '',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss" scoped>
.IconButton {
  position: relative;
  opacity: 0.6;
  display: inline-block;

  margin: 0;
  padding: 0;
  cursor: pointer;
  transition: opacity 200ms;
  &:hover {
    opacity: 1;
  }
  .IconButton__hitbox {
    appearance: none;
    outline: none;
    border: none;
    background: none;
    position: absolute;
    width: 48px;
    height: 48px;
    left: calc(50% - 24px);
    top: calc(50% - 24px);
  }
  .IconButton__hitbox:focus,
  .IconButton__hitbox:active {
    box-shadow: 0 0 0 3px rgba(21, 156, 228, 0.4);
  }
}
</style>
