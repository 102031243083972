<template>
  <div class="OrderCardSkeleton">
    <div class="OrderCardSkeleton__header">
      <div>
        <SkeletonGraphic :width="90" :height="17" class="OrderCardSkeleton__title" />
        <SkeletonGraphic :width="100" :height="23" />
      </div>
      <SkeletonGraphic :width="80" :height="22" />
    </div>
    <div class="OrderCardSkeleton__body">
      <DetailsSkeleton :items="5" :columnSize="Sizes.SMALL" />
    </div>
    <div class="OrderCardSkeleton__footer">
      <ButtonSkeleton />
    </div>
  </div>
</template>

<script>
import SkeletonGraphic from '../SkeletonGraphic';
import DetailsSkeleton, { Sizes } from '../DetailsSkeleton';
import ButtonSkeleton from '../ButtonSkeleton';

export default {
  name: 'OrderCardSkeleton',

  components: {
    SkeletonGraphic,
    DetailsSkeleton,
    ButtonSkeleton,
  },

  enums: {
    Sizes,
  },
};
</script>

<style lang="scss" scoped>
.OrderCardSkeleton {
  display: flex;
  flex-direction: column;
  width: 100%;
  border: 1px solid #efefef;
  border-radius: 3px;
  padding: 0px 15px;

  .OrderCardSkeleton__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 15px 0px 10px 0px;
  }

  .OrderCardSkeleton__title {
    margin-bottom: 4px;
  }

  .OrderCardSkeleton__body {
    margin: 20px 0;
  }

  .OrderCardSkeleton__detailHeader {
    margin-bottom: 2px;
  }

  .OrderCardSkeleton__footer {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    border-top: 1px solid #efefef;
    padding: 10px 0px;
  }
}
</style>
