import Vue from 'vue';

// .. in some logic

export const Actions = {
  ADD_FEATURES: 'ADD_FEATURE',
  REMOVE_FEATURES: 'REMOVE_FEATURE',
};

export const Getters = {
  IS_ENABLED: 'IS_ENABLED',
};

const Mutations = {
  ADD_FEATURES: 'SET_FEATURES',
  REMOVE_FEATURES: 'REMOVE_FEATURE',
};

export default {
  namespaced: true,

  state: {
    enabled: [], // List of enabled featires
  },

  getters: {
    [Getters.IS_ENABLED](state) {
      return feature => {
        return state.enabled.indexOf(feature) !== -1;
      };
    },
  },

  actions: {
    [Actions.ADD_FEATURES]({ commit }, features) {
      commit(
        Mutations.ADD_FEATURES,
        features.filter(feature => features.indexOf(feature) !== -1), // filtes unknown features away
      );
    },
    [Actions.REMOVE_FEATURES]({ commit }, features) {
      commit(Mutations.REMOVE_FEATURES, features);
    },
  },

  mutations: {
    [Mutations.ADD_FEATURES](state, addFeatures) {
      //state.enabled = [...state.enabled, ...addFeatures];

      state.enabled = [...new Set([].concat(...[state.enabled, addFeatures]))];
    },
    [Mutations.REMOVE_FEATURES](state, removeFeatures) {
      state.enabled = state.enabled.filter(feature => removeFeatures.indexOf(feature) === -1);
    },
  },
};
