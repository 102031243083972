<template>
  <div>
    <Validator v-slot="{ valid, showError }">
      <Form
        :title="formTitle"
        :locked="working"
        class="CompleteSignupForm"
        @submit="valid ? onNext() : showError()"
      >
        <template #header>
          <StepProgressBar
            :alignment="StepAlignment.JUSTIFY"
            :size="stepSize"
            :activeStep="stepId"
            :completeSteps="completeSteps"
            numbered
          >
            <Step :id="SignupStep.FORM" :title="$t('account.complete_signup.steps.form')" />
            <Step :id="SignupStep.BRANCH" :title="$t('account.complete_signup.steps.branch')" />
            <Step :id="SignupStep.COMPLETE" :title="$t('account.complete_signup.steps.done')" />
          </StepProgressBar>
        </template>
        <template #default>
          <Slides ref="slides" class="CompleteSignupForm__slides" @change="id => (slideId = id)">
            <Slide id="form_slide">
              <div class="CompleteSignupForm__inputs">
                <NewPasswordInputs
                  :passwordLabel="$t('account.complete_signup.new_password')"
                  :confirmPasswordLabel="$t('account.complete_signup.confirm_new_password')"
                  autoFocus
                  @input="password => (newPassword = password)"
                />
                <ConditionsCheckbox
                  id="conditionsCheck"
                  required
                  class="CompleteSignupForm__conditionsCheckbox"
                />
                <NewsletterCheckbox
                  id="newsletterCheck"
                  class="CompleteSignupForm__newsletterCheckbox"
                  v-model="newsletter"
                />
              </div>
            </Slide>

            <Slide id="branch_slide">
              <div class="CompleteSignupForm__storefinder">
                <p>{{ $t('account.auth.choose_your_branch') }}</p>
                <StoreFinder scrollable :onUpdate="setBranch" ignoreCookie />
              </div>
            </Slide>

            <Slide id="complete_slide">
              <p>{{ $t('account.complete_signup.message.completed') }}</p>
            </Slide>
          </Slides>
        </template>
        <template #footer>
          <Button :working="working" :size="ButtonSize.FULL">{{ buttonLabel }}</Button>
        </template>
      </Form>
    </Validator>
    <LabelModal ref="privacyModal" label="3605" />
    <LabelModal ref="termsModal" label="3687" />
    <LabelModal ref="newsletterTermsModal" label="3688" />
  </div>
</template>

<script>
import { postLogout } from '@services/thansen/authentication';
import { isBeforeNow } from '@helpers/date';
import Validator from '@containers/Validator';
import Form from '@layouts/Form';
import { StepProgressBar, Step, StepAlignment, StepSize } from '@components/StepProgressBar';
import { Slides, Slide } from '@components/Slides';
import NewPasswordInputs from '@components/NewPasswordInputs';
import { ConditionsCheckbox, NewsletterCheckbox } from '@components/Checkbox';
import { LabelModal } from '@components/Modal';
import StoreFinder from '@components/StoreFinder';
import { Button, sizes as ButtonSize } from '@components/Buttons';

const SignupStep = {
  FORM: 'form_step',
  BRANCH: 'branch_step',
  COMPLETE: 'complete_step',
};

export default {
  name: 'CompleteSignupForm',

  components: {
    Validator,
    Form,
    StepProgressBar,
    Step,
    Slides,
    Slide,
    NewPasswordInputs,
    ConditionsCheckbox,
    NewsletterCheckbox,
    StoreFinder,
    Button,
    LabelModal,
  },

  enums: {
    StepAlignment,
    ButtonSize,
    SignupStep,
  },

  data() {
    return {
      newPassword: '',
      newsletter: false,
      branch: null,
      slideId: '',
      stepId: SignupStep.FORM,
      completeSteps: undefined,
      working: false,
    };
  },

  computed: {
    formTitle() {
      if (this.slideId !== 'complete_slide') return this.$t('account.complete_signup');
      return this.$t('account.complete_signup.signup_is_completed');
    },
    buttonLabel() {
      if (this.slideId === 'form_slide') return this.$t('account.complete_signup.controls.next');
      if (this.slideId === 'branch_slide')
        return this.$t('account.complete_signup.controls.complete');
      if (this.slideId === 'complete_slide') return this.$t('account.auth.log_in');
    },
    stepSize() {
      switch (this.$mq) {
        case 'mobileS':
          return StepSize.SMALL;
        case 'mobileM':
          return StepSize.MEDIUM;
        default:
          return StepSize.LARGE;
      }
    },
  },

  created() {
    postLogout();
    if (!this.$route.query.token) this.goToLogin('invalid_token');
    else if (this.isTokenExpired()) this.goToLogin('expired_token');
  },

  methods: {
    goToLogin(flash) {
      if (!flash) window.location.replace('/login');
      else window.location.replace(`/login?flash=${flash}`);
    },
    isTokenExpired() {
      const expireAt = Number(this.$route.query.expiry) * 1000;
      return this.$route.query.expiry && isBeforeNow(expireAt);
    },
    onNext() {
      if (this.slideId === 'form_slide') this.slideToBranch();
      else if (this.slideId === 'branch_slide') this.submit();
      else if (this.slideId === 'complete_slide') this.goToLogin();
    },
    setBranch(selectedStore) {
      this.branch = selectedStore;
      this.stepId = this.branch ? SignupStep.COMPLETE : SignupStep.BRANCH;
    },
    submit() {
      this.working = true;
      const extra = {};
      if (this.newsletter) extra.subscribe_newsletter = true;
      if (this.branch) extra.favorite_branch = this.branch.slug;
      this.authenticationContainer
        .changePassword(this.$route.query.token, this.newPassword, extra)
        .then(this.onChangeCompleted)
        .catch(this.onChangeFailed);
    },
    onChangeCompleted() {
      this.working = false;
      this.slideToComplete();
    },
    onChangeFailed() {
      this.goToLogin('invalid_token');
    },
    slideToBranch() {
      this.stepId = SignupStep.BRANCH;
      this.$refs.slides.slideTo('branch_slide');
    },
    slideToComplete() {
      this.stepId = '';
      this.completeSteps = [SignupStep.FORM, SignupStep.BRANCH, SignupStep.COMPLETE];
      this.$refs.slides.slideTo('complete_slide');
    },
  },

  inject: {
    authenticationContainer: {
      default() {
        console.error('Missing AuthenticationContainer');
        return;
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.CompleteSignupForm {
  .CompleteSignupForm__slides {
    padding-top: 40px;
  }

  .CompleteSignupForm__inputs {
    display: flex;
    flex-direction: column;
    gap: 1em;
  }

  .CompleteSignupForm__newletterCheckbox {
    font-weight: bold;
  }

  .CompleteSignupForm__storefinder {
    height: 350px;
    overflow: hidden;
    margin-bottom: 20px;
    p {
      margin-bottom: 7px;
      font-size: 15px;
    }
  }

  .CompleteSignupForm__conditionsCheckbox,
  .CompleteSignupForm__newsletterCheckbox {
    @include maxBreakpoint($tablet-numeric) {
      font-size: 13px;
    }
  }
}
</style>
