import settings from '@settings';
import jsonp from 'jsonp';

export function getNearestByQuery(query, provider) {
  return new Promise((resolve, reject) => {
    const params = {
      provider: provider || 'AFHENTET',
      country: settings.legacy.countryCode,
      limit: 150,
      domain: settings.legacy.domainCode,
      query,
    };
    const qs = Object.keys(params)
      .map(function(key) {
        return key + '=' + params[key];
      })
      .join('&');

    jsonp(
      `https://n2.thg.dk/serviceapi/api/servicepoints/getNearestByQuery?${qs}`,
      null,
      (err, response) => {
        if (err || response.error) {
          reject(err || response.error);
        }

        if (response.service_points) {
          // BUGFIX: https://sentry.io/share/issue/36fba4a64d4f4d9ea6506d9acb2e0680/
          // Changes the branch objects so they look a bit more like the new branches
          response.service_points = response.service_points.map(branch => {
            const { webid, ...rest } = branch;
            return {
              ...rest,
              slug: webid,
              url: `/butikker/${webid}`,
            };
          });
        }

        resolve(response);
      },
    );
  });
}

export function getAll() {
  return new Promise((resolve, reject) => {
    const params = {
      country: settings.legacy.countryCode,
      limit: 150,
      domain: settings.legacy.domainCode,
    };
    const qs = Object.keys(params)
      .map(function(key) {
        return key + '=' + params[key];
      })
      .join('&');

    jsonp(`https://n2.thg.dk/serviceapi/api/servicepoints/getAll?${qs}`, null, (err, response) => {
      if (err) {
        reject(err);
      }
      // Changes the branch objects so they look a bit more like the new branches
      const branches = response.data.map(branch => {
        const { webid, ...rest } = branch;
        return {
          ...rest,
          slug: webid,
          url: `/butikker/${webid}`,
        };
      });
      resolve(branches);
    });
  });
}
