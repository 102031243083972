<template>
  <component
    :is="tag"
    :class="[
      'UserCard',
      `UserCard--${size}`,
      clickable && 'UserCard--clickable',
      darkmode && 'UserCard--dark',
    ]"
  >
    <div class="UserCard__logo">
      <div class="UserCard__initials">{{ initials }}</div>
    </div>
    <div class="UserCard__details">
      <div class="UserCard__name ft-h6">{{ name }}</div>
      <div class="UserCard__email ft-p2">{{ email }}</div>
    </div>
  </component>
</template>

<script>
export const Sizes = {
  SMALL: 'small',
  MEDIUM: 'medium',
};

export default {
  name: 'UserCard',

  props: {
    tag: {
      type: String,
      default: 'div',
    },
    name: {
      type: String,
      required: true,
    },
    email: {
      type: String,
      default: null,
    },
    size: {
      type: String,
      default: Sizes.MEDIUM,
    },
    clickable: {
      type: Boolean,
      default: false,
    },
    darkmode: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    initials() {
      const names = this.name.split(' ');
      const firstInitial = names[0] ? names[0][0].toUpperCase() : '';
      const secondInitial = names[1] ? names[1][0].toUpperCase() : '';
      return firstInitial + secondInitial;
    },
  },
};
</script>

<style lang="scss" scoped>
.UserCard {
  display: flex;
  align-items: center;
  overflow: hidden;
  white-space: nowrap;

  .UserCard__logo {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: $color-prim-grey-dark;
    background-color: var(--color-prim);
  }

  .UserCard__initials {
    margin: auto;
    color: #ffffff;
  }

  .UserCard__details {
    display: flex;
    flex-direction: column;
    overflow: hidden;
  }

  .UserCard__name {
    color: #000000;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .UserCard__email {
    color: $color-prim-grey-dark;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &--small {
    @include spacing-1('gap');
    .UserCard__logo {
      min-width: 40px;
      min-height: 40px;
      border-radius: 4px;
    }
    .UserCard__initials {
      font-size: 16px;
    }
    .UserCard__name {
      font-size: 14px;
    }
  }

  &--medium {
    @include spacing-2('gap');
    .UserCard__logo {
      min-width: 50px;
      min-height: 50px;
      border-radius: 5px;
    }
    .UserCard__initials {
      font-size: 18px;
    }
    .UserCard__name {
      font-size: 15px;
    }
  }

  &--clickable {
    cursor: pointer;
    &:hover .UserCard__details {
      text-decoration: underline;
    }
  }

  &--dark {
    .UserCard__name {
      color: white;
    }
    .UserCard__email {
      color: $color-off-grey;
    }
  }
}
</style>
