<template>
  <div class="ConditionsCheckbox">
    <Checkbox :id="id" required :invalidMessage="$t('account.auth.message.terms_required')">
      <i18n path="account.auth.accept">
        <template #privacyPolicy>
          <a
            href="/kundeservice/handelsbetingelser-og-privatliv/privatlivspolitik/n-236568175/"
            target="_blank"
            :title="$t('account.auth.requirements')"
            @click.prevent="$refs.privacyModal.open()"
            >{{ $t('account.auth.accept.privacy_policy').toLowerCase() }}</a
          >
        </template>
        <template #selfserviceTerms>
          <a @click.prevent="$refs.termsModal.open()">{{
            $t('account.auth.accept.terms').toLowerCase()
          }}</a>
        </template>
      </i18n>
    </Checkbox>
    <LabelModal ref="privacyModal" label="3605" />
    <LabelModal ref="termsModal" label="3687" />
  </div>
</template>

<script>
import { LabelModal } from '@components/Modal';
import Checkbox from '../Checkbox';

export default {
  name: 'ConditionsCheckbox',

  components: {
    LabelModal,
    Checkbox,
  },

  props: {
    id: {
      type: String,
      required: true,
    },
  },
};
</script>
