export const Autocompletes = {
  OFF: 'off',
  ON: 'on',

  NAME: 'name',
  NAME_PREFIX: 'honorific-prefix',
  NAME_FIRSTNAME: 'given-name',
  NAME_MIDDELNAME: 'additional-name',
  NAME_LASTNAME: 'family-name',
  NAME_SUFFIX: 'honorific-suffix',
  NAME_NICKNAME: 'nickname',

  EMAIL: 'email',
  USERNAME: 'username',

  PASSWORD: 'password',
  PASSWORD_NEW: 'new-password',
  PASSWORD_CURRENT: 'current-password',
  PASSWORD_ONETIMECODE: 'one-time-code',

  ORGANIZATION_TITLE: 'organization-title',
  ORGANIZATION: 'organization',

  ADDRESS: 'address',
  STREET_ADDRESS: 'street-address',
  ADDRESS_LINE1: 'address-line1',
  ADDRESS_LINE2: 'address-line2',
  ADDRESS_LINE3: 'address-line3',
  ADDRESS_LEVEL4: 'address-level4',
  ADDRESS_LEVEL3: 'address-level3',
  ADDRESS_LEVEL2: 'address-level2',
  ADDRESS_LEVEL1: 'address-level1',

  COUNTRY: 'country',
  COUNTRY_NAME: 'country-name',
  POSTAL_CODE: 'postal-code',
  CREDIT_CARD_NAME: 'cc-name',
  CREDIT_CARD_GIVEN_NAME: 'cc-given-name',
  CREDIT_CARD_ADDITIONAL_NAME: 'cc-additional-name',
  CREDIT_CARD_FAMILY_NAME: 'cc-family-name',
  CREDIT_CARD_NUMBER: 'cc-number',
  CREDIT_CARD_EXP: 'cc-exp',
  CREDIT_CARD_EXP_MONTH: 'cc-exp-month',
  CREDIT_CARD_EXP_YEAR: 'cc-exp-year',
  CREDIT_CARD_CSC: 'cc-csc',
  CREDIT_CARD_TYPE: 'cc-exp-type',

  TRANSACTION_CURRENCY: 'transaction-currency',
  TRANSACTION_AMOUNT: 'transaction-amount',
  LANGUAGE: 'language',
  BDAY: 'bday',
  BDAY_DAY: 'bday-day',
  BDAY_MONTH: 'bday-month',
  BDAY_YEAR: 'bday-year',
  SEX: 'sex',
  TEL: 'tel',
  TEL_COUNTRY_CODE: 'tel-country-code',
  TEL_NATIONAL: 'tel-national',
  TEL_LOCAL: 'tel-local',
  TEL_EXTENSION: 'tel-extension',
  IMPP: 'impp',
  URL: 'url',
  PHOTO: 'photo',
};
