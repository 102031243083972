import { getCart } from '@services/thansen/cart';

import Progress from '@types/Progress';

export const Getters = {
  IS_EMPTY: 'IS_EMPTY',
  QUANTITY: 'QUANTITY',
};

export const Actions = {
  FETCH_CART: 'FETCH_CART',
};

const Mutations = {
  SET_STATUS: 'SET_STATUS',
  SET_CART: 'SET_CART',
};

const defaultState = {
  lines: [],
};

export default {
  namespaced: true,

  state: {
    cart: {
      ...defaultState,
    },
    status: '',
  },

  getters: {
    [Getters.IS_EMPTY](state) {
      return !state.cart || !state.cart.lines || state.cart.lines.length === 0;
    },
    [Getters.QUANTITY](state) {
      return !state.cart || !state.cart.lines || state.cart.lines.length === 0
        ? 0
        : state.cart.lines.reduce((quantity, line) => quantity + line.quantity, 0);
    },
  },

  actions: {
    [Actions.FETCH_CART]({ commit }) {
      commit(Mutations.SET_STATUS, Progress.WORKING);
      getCart()
        .then(cart => {
          commit(Mutations.SET_CART, cart);
          commit(Mutations.SET_STATUS, Progress.COMPLETE);
        })
        .catch(error => {
          commit(Mutations.SET_STATUS, Progress.ERROR);
        });
    },
  },

  mutations: {
    [Mutations.SET_STATUS](state, status) {
      state.status = status;
    },
    [Mutations.SET_CART](state, cart) {
      state.cart = cart;
    },
  },
};
