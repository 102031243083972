<template>
  <Drawer ref="drawer" class="UserDrawer" :closeLabel="$t('close')">
    <template #default="{ isActive }">
      <div v-if="isActive">
        <a
          :href="$router.resolve({ name: '/selfservice/profile' }).href"
          class="UserDrawer__userCard"
        >
          <UserCard
            :name="`${accountContainer.account.first_name} ${accountContainer.account.last_name}`"
            :email="accountContainer.account.email_address"
            clickable
          />
          <i class="UserDrawer__userCardIcon far fa-cog" />
        </a>
        <NavigationContainer v-slot="{ selfserviceRoutes, isExactActive }">
          <ul>
            <li v-for="route in selfserviceRoutes" :key="route.name">
              <a
                :href="$router.resolve({ name: route.name }).href"
                :title="route.meta.title"
                :class="[
                  'UserDrawer__menuItem',
                  isExactActive(route) && 'UserDrawer__menuItem--active',
                ]"
              >
                {{ route.meta.title }}
              </a>
            </li>
          </ul>
        </NavigationContainer>
        <div class="UserDrawer__menuDivider" />
        <button class="UserDrawer__menuItem" @click="logout">
          {{ $t('account.auth.log_out') }}
        </button>
      </div>
    </template>
  </Drawer>
</template>

<script>
import { postLogout } from '@services/thansen/authentication';
import NavigationContainer from '@containers/NavigationContainer';
import Drawer from '@components/Drawer';
import UserCard from '@scenes/SelfserviceScene/components/UserCard';

export default {
  name: 'UserDrawer',

  components: {
    NavigationContainer,
    Drawer,
    UserCard,
  },

  methods: {
    open() {
      this.$refs.drawer.open();
    },
    logout() {
      postLogout().then(() => (window.location.href = '/'));
    },
  },

  inject: {
    accountContainer: {
      default() {
        console.error('Missing AccountContainer');
        return;
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.UserDrawer {
  .UserDrawer__userCard {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
    padding: 10px 20px;
    text-decoration: none;
    &:hover {
      background-color: $color-prim-grey;
    }
  }

  .UserDrawer__userCardIcon {
    font-size: 18px;
    margin: 0.5em;
  }

  .UserDrawer__menuItem {
    display: block;
    width: 100%;
    background-color: #ffffff;
    border: none;
    padding: 10px 20px;
    font-size: 18px;
    text-align: left;
    text-decoration: none;
    &:hover {
      background-color: $color-prim-grey;
    }
    &--active {
      font-weight: bold;
    }
  }

  .UserDrawer__menuDivider {
    width: 100%;
    height: 1px;
    background-color: $color-prim-grey;
    margin: 10px 0;
    padding: 0 20px;
  }
}
</style>
