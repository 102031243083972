/* global Sentry */
import Cookies from 'js-cookie';

export function VueErrorHandler(err, vm, info) {
  let componentName = vm.$vnode.tag;

  if (componentName) {
    componentName = componentName.replace(/vue-component-\d+-/i, '');
  }

  if (typeof Sentry !== 'undefined') {
    Sentry.withScope(scope => {
      scope.setTag('vue', 'component');
      scope.setTag('vue-component', componentName);
      scope.setLevel('fatal');
      scope.setExtra('v-info', info);
      scope.setExtra('v-data', vm._data);
      scope.setExtra('v-props', vm._props);
      Sentry.captureException(err);
    });
    // err: error trace
    // vm: component in which error occured
    // info: Vue specific error information such as lifecycle hooks, events etc.
    // TODO: Perform any custom logic or log to server
  } else {
    console.error('Errorhandler', {
      component: componentName,
      error: err,
      info: info,
      data: vm._data,
      props: vm._props,
    });
  }
}

export function AxiosErrorHandler(error) {
  // Redirect user to login
  if (error.response && error.response.status === 403) {
    window.location =
      '/login?redirect=' + encodeURIComponent(location.href) + '&flash=not_logged_in';
  }

  if (
    !error.response ||
    (typeof error.config.errorHandlerIgnore === 'function' &&
      error.config.errorHandlerIgnore(error))
  ) {
    return Promise.reject(error);
  }

  if (error.config && typeof error.config.errorHandler === 'function') {
    error.config.errorHandler(error);
    return Promise.reject(error);
  } else if (error.config.hasOwnProperty('handleErrors') && error.config.handleErrors === false) {
    return Promise.reject(error);
  }
  let logged = false;

  let errorObj = {
    message: error.message + '(' + error.config.url + ')',
    tags: {
      vue: 'axios',
      'request.method': error.config.method,
      'request.url': error.config.url,
      'response.status': error.response.status,
      'cookie-c': Cookies.get('c'),
    },
    extra: {
      data: error.config.data || {},
      headers: error.config.headers,
    },
  };

  if (error.response) {
    logged = true;

    if (typeof Sentry !== 'undefined') {
      Sentry.captureEvent(errorObj);
    } else {
      console.error(errorObj);
    }
  }

  return Promise.reject({ ...error, logged });
}

// Debugging

/* Undefined C debugging 
if (typeof Sentry !== 'undefined' && !Cookies.get('c')) {
  Sentry.captureEvent({
    message: 'C is undefined',
    level: 'fatal',
    tags: {
      globalvar_c: window.globalvar_c,
    },
    extra: {
      cookie_vue: document.cookie,
      cookie_script: window.cookie_script,
      cookie_request: window.cookie_request,
    },
  });
}
*/
