import dayjs from 'dayjs';
import isBetween from 'dayjs/plugin/isBetween';
// First extend
dayjs.extend(isBetween);

import navigatorLanguages from 'navigator-languages';

import '@components/Time/locales/nb';
import '@components/Time/locales/da';

export const dateIsBetween = (fromDate, toDate, dateToEvaluate) => {
  // Get the preferred language
  const preferredLanguage = navigatorLanguages()[0];
  let language = 'da';

  switch (preferredLanguage) {
    case 'no':
    case 'nb-NO':
      language = 'nb';
      break;
    case 'nn-NO':
      language = 'nn';
      break;
  }

  // Set locale
  dayjs.locale(language);

  // Evaluate date
  return dayjs(dateToEvaluate).isBetween(fromDate, toDate, 'day', '[]');
};
