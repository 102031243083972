import settings from '@settings';
import config from '@config';
import { isIE } from '@utils/browser';

if (isIE) {
  require('intersection-observer');
}

import { VueErrorHandler } from '@errorhandler';
import i18n from '@i18n';

import Vue from 'vue';
import VueCarousel from 'vue-carousel';
import VueMq from 'vue-mq';

import EnumsPlugin from '@plugins/enums';
import FeatureTogglePlugin from '@plugins/feature-toggle';

import VueObserveVisibility from 'vue-observe-visibility';
import VueObserveResize from '@plugins/vue-observe-resize';
import Tracking from '@plugins/tracking';

import router from './router';
import routes from './router/routes';
import store from './store';

import { Buy } from '@components/Buttons';
import DraftTransformButton from '@components/DraftTransformButton';

import Drawer from '@components/Drawer';
import NavigationDrawer from '@components/NavigationDrawer';

import TopBarSearchLoading from '@components/Search/TopBarSearchLoading';
import TopBarLoginButton from '@components/TopBarLoginButton';

import LoginHelper from '@components/Login/components/LoginHelper';

import { updateFeatures } from '@features';

import { gsap, ScrollToPlugin } from 'gsap/all';
gsap.registerPlugin(ScrollToPlugin);

/* Import global CSS */
import './assets/styles/oocss.scss';
import './assets/styles/typography/index.scss';

import featureToggle from './plugins/feature-toggle';
import vuetify from './plugins/vuetify';

const debug = process.env.NODE_ENV !== 'production';

Vue.config.productionTip = debug;
Vue.config.devtools = debug; // Enables vue extension in production

if (!settings.testmode) {
  Vue.config.errorHandler = VueErrorHandler;
}

store.dispatch('cardata/clearStatus');
if (settings.mode === 'b2b') {
  store.dispatch('cardata/clearSearch');
}

// Looping the persistedstate store modules which has version control. Store modules will be reset if version is out of sync.
Object.keys(settings.storeVersions).forEach(key => {
  store.dispatch(key + '/checkVersion');
});

document.addEventListener('keyup', ev => {
  if (ev.shiftKey && ev.keyCode == 80) {
    settings.shp = !settings.shp;
  }
});

// Preloaded components
Vue.component('navigation-drawer', NavigationDrawer);
Vue.component('drawer', Drawer);
Vue.component('buy-button', Buy);
Vue.component('draft-transform-button', DraftTransformButton);

Vue.component('login-helper', LoginHelper);
Vue.component('login-form', () => import('@components/Login'));
Vue.component('login-scene', () => import('@scenes/LoginScene'));

Vue.component('countdown-banner-widget', () =>
  import('@components/Countdown/components/CountdownBannerWidget'),
);
Vue.component('countdown-text', () => import('@components/Countdown/components/CountdownText'));

Vue.component('bookings-wheelstorage-overview', () =>
  import('@components/BookingsWheelStorageOverview'),
);

// Lazyload/async components (needs content-placeholder)
const TopBarSearchComponent = () => ({
  component: import('@components/Search/TopBarSearch'),
  loading: TopBarSearchLoading,
  delay: 0,
  timeout: 3000,
});
Vue.component('topbar-search', TopBarSearchComponent);
Vue.component('topbar-login-button', TopBarLoginButton);

Vue.component('car-search', () => import('@components/CarSearch'));
Vue.component('steps', () => import('@components/Steps'));
Vue.component('tire-search', () => import('@components/TireSearch'));

Vue.component('products-filtred', () => import('@components/ProductsFiltred'));
Vue.component('user-card', () => import('@scenes/SelfserviceScene/components/UserCard'));

Vue.component('store-finder', () => import('@components/StoreFinder'));
Vue.component('algolia-product-carousel', () =>
  import('@components/Algolia/components/ProductCarousel'),
);
Vue.component('selfservice-wrapper', () =>
  import('@scenes/SelfserviceScene/components/SelfserviceWrapper'),
);
Vue.component('search-view', () => import('./scenes/SearchScene'));
Vue.component('products-view', () => import('./scenes/ProductsScene'));

Vue.component('account-card', () => import('@components/AccountCard'));
Vue.component('label-modal', () => import('@components/Modal/components/LabelModalInline'));

Vue.component('xal-mode', () => import('./components/modes/xal.vue'));

Vue.component('ad-recent-posts', () => import('@components/legacy/ad-recent-posts'));

// Fireworks
Vue.component('fireworks-products', () => import('./scenes/FireworksScene/scenes/ProductsScene'));
Vue.component('fireworks-search', () => import('./scenes/FireworksScene/components/TopbarSearch'));
Vue.component('fireworks-agegate', () => import('./scenes/FireworksScene/components/AgeGate'));

Vue.use(VueObserveVisibility);
Vue.use(VueObserveResize);
Vue.use(VueCarousel);
Vue.use(Tracking);

// Setup breakpoint handling
// This will expose a $mq property on each component with a string
// containing the current breakpoint key
Vue.use(VueMq, {
  breakpoints: {
    mobileS: 321,
    mobileM: 376,
    mobileL: 426,
    tablet: 767,
    laptop: 1025,
    laptopL: 1441,
    screen4k: 2561,
    scren8k: Infinity,
  },
  defaultBreakpoint: 'laptopL', // customize this for SSR
});

Vue.use(EnumsPlugin);

// TODO: Move features to its own file
let features = [];

Vue.use(FeatureTogglePlugin, { store, features });
Vue.prototype.$config = config;
updateFeatures();
// Querying and initializes app wrappers
const wrappers = document.getElementsByClassName('js-app-wrapper');

// Set class for organization
const organization = settings.organization.toString().toLowerCase();
const bodyTag = document.getElementsByTagName('body')[0];

if (!bodyTag.classList.contains(organization)) {
  bodyTag.classList.add(organization);
}

wrappers.forEach((el, i) => {
  // TODO: Validatet that the current wrapper hasn't got a parent app-wrapper.
  const elId = `app-wrapper-${i}`; // Generates a unique id
  el.setAttribute('id', elId);
  new Vue({
    router,
    i18n,
    el: `#${elId}`,
    vuetify,

    // All wrappers get access to the same store
    store,
  });
});
