import Vue from 'vue';
import Cookies from 'js-cookie';
import { fecthCardataByLicensePlate, fetchCardataByNode } from '../../../services/thansen/car';
import { Mutation } from './types';

const expireTime = 30 * 60000;

const defaultDimensions = {
  width: -1,
  profile: -1,
  inches: -1,
};
export default {
  namespaced: true,
  state: {
    dimensions: {
      // tecdoc specific filter
      default: {
        ...defaultDimensions,
      },
    },
    ssr: {
      checked: false,
      count: 0,
    },
    offer: {
      checked: false,
      count: 0,
    },

    total: 0, // Updated by products components
    count: 0, // Updated by products components
    brands: {},
    filtredBrands: [],
  },
  getters: {
    getFilter: state => {
      return {
        ...state,
      };
    },
    getSsr: state => {
      return state.ssr;
    },
    getOffer: state => {
      return state.offer;
    },
    getDimensions: state => filterId => {
      return (
        (filterId ? state.dimensions[filterId] : state.dimensions.default) || {
          ...defaultDimensions,
        }
      );
    },
    getBrands: state => {
      // Converts brands object to array of brand objects with id's.
      return Object.keys(state.brands).map((key, index) => {
        return {
          id: key,
          ...state.brands[key],
        };
      });
    },
  },
  actions: {
    setFiltredBrands({ commit }, brands) {
      commit(Mutation.SET_FILTRED_BRANDS, brands);
    },
    setBrands({ commit }, brands) {
      commit(Mutation.SET_BRANDS, brands);
    },
    updateBrands({ commit }, brands) {
      commit(Mutation.UPDATE_BRANDS, brands);
    },
    setDimensions({ commit, state, getters }, { filterId, filter }) {
      commit(Mutation.SET_DIMENSIONS, { filterId, filter });
    },
    setFilterCount({ commit }, counts) {
      commit(Mutation.SET_COUNT, counts);
    },
    clear({ commit }, filterId) {
      commit(Mutation.CLEAR_FILTER, filterId);
    },
    clearAll({ commit }, filterId) {
      commit(Mutation.CLEAR_ALL);
    },
    setOfferCheck({ commit }, val) {
      commit(Mutation.SET_OFFER_CHECKED, val);
    },
    setSsrCheck({ commit }, val) {
      commit(Mutation.SET_SSR_CHECKED, val);
    },
  },
  mutations: {
    [Mutation.SET_DIMENSIONS](state, { filterId, filter }) {
      // Extend current filter?

      if (state.dimensions[filterId || 'default']) {
        // Vue.set(state.dimensions, filterId || 'default', filter);
        state.dimensions[filterId || 'default'] = {
          ...state.dimensions[filterId || 'default'],
          ...filter,
        };
      } else {
        state.dimensions[filterId || 'default'] = { ...defaultDimensions, ...filter };
      }
    },
    [Mutation.SET_COUNT](state, { count, total, ssrCount, offerCount }) {
      state.count = count;
      state.total = total;
      state.ssr.count = ssrCount || 0;
      state.offer.count = offerCount || 0;
      // Vue.set(state.dimensions, filterId || 'default', { ...defaultDimensions });
    },
    [Mutation.CLEAR_FILTER](state, filterId) {
      // Extend current filter?

      Vue.set(state.dimensions, filterId || 'default', { ...defaultDimensions });
    },
    [Mutation.CLEAR_ALL](state) {
      // Extend current filter?
      Vue.set(state, 'dimensions', []);
    },
    [Mutation.SET_OFFER_CHECKED](state, val) {
      // Extend current filter?
      Vue.set(state.offer, 'checked', val);
      // state.offer.checked = val;
    },
    [Mutation.SET_SSR_CHECKED](state, val) {
      // Extend current filter?
      Vue.set(state.ssr, 'checked', val);
      // state.ssr.checked = val;
    },
    [Mutation.SET_BRANDS](state, brands) {
      // Extend current filter?

      Vue.set(state, 'filtredBrands', Object.keys(brands));
      Vue.set(state, 'brands', brands);
    },
    [Mutation.UPDATE_BRANDS](state, brands) {
      // Extend current filter?
      Object.entries(brands).forEach(([key, value]) => {
        Vue.set(state.brands, key, { ...state.brands[key], ...value });
      });
    },
    [Mutation.SET_FILTRED_BRANDS](state, brands) {
      // Extend current filter?

      Vue.set(state, 'filtredBrands', brands);
    },
  },
};
