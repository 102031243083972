!(function(e, t) {
  'object' == typeof exports && 'undefined' != typeof module
    ? (module.exports = t(require('dayjs')))
    : 'function' == typeof define && define.amd
    ? define(['dayjs'], t)
    : (e.dayjs_locale_da = t(e.dayjs));
})(this, function(e) {
  'use strict';
  e = e && e.hasOwnProperty('default') ? e.default : e;
  var t = {
    name: 'da',
    weekdays: ['søndag', 'mandag', 'tirsdag', 'onsdag', 'torsdag', 'fredag', 'lørdag'],
    weekdaysShort: ['søn.', 'man.', 'tirs.', 'ons.', 'tors.', 'fre.', 'lør.'],
    weekdaysMin: ['sø.', 'ma.', 'ti.', 'on.', 'to.', 'fr.', 'lø.'],
    months: [
      'januar',
      'februar',
      'marts',
      'april',
      'maj',
      'juni',
      'juli',
      'august',
      'september',
      'oktober',
      'november',
      'december',
    ],
    monthsShort: [
      'jan.',
      'feb.',
      'mar.',
      'apr.',
      'maj',
      'juni',
      'juli',
      'aug.',
      'sept.',
      'okt.',
      'nov.',
      'dec.',
    ],
    weekStart: 1,
    ordinal: function(e) {
      return e + '.';
    },
    formats: {
      LT: 'HH:mm',
      LTS: 'HH:mm:ss',
      L: 'DD-MM-YYYY',
      LL: 'D. MMMM YYYY',
      LLL: 'D. MMMM YYYY HH:mm',
      LLLL: 'dddd [d.] D. MMMM YYYY [kl.] HH:mm',
    },
    relativeTime: {
      future: 'om %s',
      past: '%s siden',
      s: 'få sekunder',
      m: 'et minut',
      mm: '%d minutter',
      h: 'en time',
      hh: '%d timer',
      d: 'en dag',
      dd: '%d dage',
      M: 'en måned',
      MM: '%d måneder',
      y: 'et år',
      yy: '%d år',
    },
  };
  return e.locale(t, null, !0), t;
});
