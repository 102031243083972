<template>
  <div>
    <LoginModal ref="LoginModal" />
    <slot :openDialog="openDialog" :openLogin="openLogin" :openCreateUser="openCreateUser"></slot>
  </div>
</template>

<script>
import LoginModal, { Modes as LoginModes } from '@components/Login/components/LoginModal';
import Button, { sizes as ButtonSizes } from '@components/Buttons';

export default {
  name: 'LoginHelper',

  components: {
    LoginModal,
    Button,
  },

  enums: {
    LoginModes,
    ButtonSizes,
  },

  methods: {
    openDialog(mode) {
      this.$refs.LoginModal.open(mode);
    },
    openLogin() {
      this.$refs.LoginModal.open('LOGIN');
    },
    openCreateUser() {
      this.$refs.LoginModal.open('CREATE');
    },
  },
};
</script>
