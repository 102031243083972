import { rangeToValue } from './rangeToValue';

export default {
  rangeToValue,
};

export const brandStringToObject = brandString => {
  const brand = brandString.split('|');
  return {
    name: brand[0],
    url: brand[1],
    logo: brand[2] ?? '',
  };
};
