<template>
  <form
    novalidate
    :class="['Form', locked && 'Form--locked', flexRows && 'Form--rows']"
    @submit.prevent="submit"
  >
    <div class="Form__header" v-if="hasHeaderSlot || title">
      <h2 v-if="title" class="Form__title">{{ title }}</h2>
      <slot name="header" />
    </div>
    <div class="Form__inputs">
      <slot name="default" />
    </div>
    <div class="Form__footer" v-if="hasFooterSlot">
      <slot name="footer" />
    </div>
  </form>
</template>

<script>
export default {
  name: 'Form',

  props: {
    title: {
      type: String,
      default: '',
    },
    locked: {
      type: Boolean,
      default: false,
    },
    flexRows: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    hasFooterSlot() {
      return !!this.$slots['footer'] || !!this.$scopedSlots['footer'];
    },
    hasHeaderSlot() {
      return !!this.$slots['header'] || !!this.$scopedSlots['header'];
    },
  },
  methods: {
    submit(e) {
      e.preventDefault();
      this.$emit('submit');
    },
  },
};
</script>

<style lang="scss" scoped>
.Form {
  .Form__header {
    //margin-bottom: 2.5em;
  }
  .Form__footer {
    display: flex;
    flex-direction: column;
    gap: 15px;
    align-items: flex-start;
    margin-top: 1.5em;
  }

  .Form__inputs {
    display: flex;
    gap: 1em;
    flex-direction: column;
    > * {
      flex-grow: 1;
      flex-shrink: 1;
      flex-basis: auto;
    }
  }
  &--locked .Form__inputs {
    pointer-events: none;
    opacity: 0.5;
  }
  &--rows .Form__inputs {
    flex-direction: row;
    flex-wrap: wrap;
  }

  .Form__title {
    font-size: 26px;
    margin-top: 0;
    margin-bottom: 0.6em;
    color: black !important;
    text-transform: none !important;
  }
}
</style>
