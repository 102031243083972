<template>
  <div class="PopoverHandler">
    <div
      @click="onClick"
      @touchstart="onTouchstart"
      @mouseenter="onMouseenter"
      @mouseleave="onMouseleave"
    >
      <slot name="default" />
    </div>
    <div v-if="isOpen" @mouseenter="onMouseenter" @mouseleave="onMouseleave">
      <slot name="content" :close="close" />
    </div>
    <div v-if="isOpen" class="PopoverHandler__backdrop" @click.self.stop="close" />
  </div>
</template>

<script>
export default {
  name: 'PopoverHandler',

  props: {
    toggleOnClick: {
      type: Boolean,
      default: false,
    },
    locked: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    enterDelay: {
      type: Number,
      default: 20,
    },
    leaveDelay: {
      type: Number,
      default: 200,
    },
  },

  data() {
    return {
      isOpen: false,
    };
  },

  created() {
    this.latestTouchstart = 0;
    this.timeoutEnter = null;
    this.timeoutLeave = null;
  },

  watch: {
    isOpen(to, from) {
      if (!from && to) this.$emit('open');
      else if (from && !to) this.$emit('close');
    },
  },

  methods: {
    onClick(event) {
      if (this.isToggleOnClick()) {
        event.preventDefault();
        this.toggle();
      } else this.close();
    },
    onTouchstart() {
      this.latestTouchstart = Date.now();
    },
    onMouseenter() {
      if (!this.isToggleOnClick()) {
        clearTimeout(this.timeoutLeave);
        if (!this.isOpen) this.timeoutEnter = setTimeout(this.open, this.enterDelay);
      }
    },
    onMouseleave() {
      if (!this.isToggleOnClick()) {
        clearTimeout(this.timeoutEnter);
        if (this.isOpen) this.timeoutLeave = setTimeout(this.close, this.leaveDelay);
      }
    },
    isToggleOnClick() {
      return this.toggleOnClick || this.isRecentTouch();
    },
    isRecentTouch() {
      return Date.now() - this.latestTouchstart < 1000;
    },
    toggle() {
      if (this.isOpen) this.close();
      else this.open();
    },
    open() {
      if (!this.disabled) this.isOpen = true;
    },
    close() {
      if (!this.locked) this.isOpen = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.PopoverHandler {
  .PopoverHandler__backdrop {
    position: fixed;
    z-index: 0;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }
}
</style>
