import { processOptions, throttle, deepEqual } from '@utils';

class ResizeState {
  constructor(el, options, vnode) {
    this.el = el;
    this.observer = null;
    this.frozen = false;
    this.createObserver(options, vnode);
  }

  createObserver(options, vnode) {
    if (this.observer) {
      this.destroyObserver();
    }

    if (this.frozen) return;

    this.options = processOptions(options);

    this.callback = (result, entry) => {
      this.options.callback(result, entry);
      if (result && this.options.once) {
        this.frozen = true;
        this.destroyObserver();
      }
    };
    // Throttle
    if (this.callback && this.options.throttle) {
      const { leading } = this.options.throttleOptions || {};
      this.callback = throttle(this.callback, this.options.throttle, {
        leading: state => {
          return (
            leading === 'both' ||
            (leading === 'visible' && state) ||
            (leading === 'hidden' && !state)
          );
        },
      });
    }

    this.oldResult = undefined;

    this.observer = new ResizeObserver(entries => {
      let entry = entries[0];

      // TODO: Support for multiply entries?

      if (this.callback) {
        this.callback(entry.contentRect, entry);
      }
    });

    // Wait for the element to be in document
    vnode.context.$nextTick(() => {
      if (this.observer) {
        this.observer.observe(this.el);
      }
    });
  }

  destroyObserver() {
    if (this.observer) {
      this.observer.disconnect();
      this.observer = null;
    }

    // Cancel throttled call
    if (this.callback && this.callback._clear) {
      this.callback._clear();
      this.callback = null;
    }
  }
}

function bind(el, { value }, vnode) {
  if (!value) return;
  if (typeof ResizeObserver === 'undefined') {
    console.warn(
      '[vue-observe-visibility] ResizeObserver API is not available in your browser. Please install this polyfill: https://github.com/w3c/ResizeObserver/tree/master/polyfill',
    );
  } else {
    const state = new ResizeState(el, value, vnode);
    el._vue_resizeState = state;
  }
}

function update(el, { value, oldValue }, vnode) {
  if (deepEqual(value, oldValue)) return;
  const state = el._vue_resizeState;
  if (!value) {
    unbind(el);
    return;
  }
  if (state) {
    state.createObserver(value, vnode);
  } else {
    bind(el, { value }, vnode);
  }
}

function unbind(el) {
  const state = el._vue_resizeState;
  if (state) {
    state.destroyObserver();
    delete el._vue_resizeState;
  }
}

export default {
  bind,
  update,
  unbind,
};
