import { Api } from '..';

// Temporary ocp override
const ocp = { headers: { 'Ocp-Apim-Subscription-Key': 'c85feab3d0f6419084132205ed25d3b6' } };

export function getProfile(email) {
  return new Promise((resolve, reject) => {
    Api.get(`/klaviyo/profiles/${email}`, ocp)
      .then(response => {
        resolve(response.data);
      })
      .catch(error => {
        reject(error);
      });
  });
}

export function putProfile(email, profile) {
  return new Promise((resolve, reject) => {
    Api.put(`/klaviyo/profiles/${email}`, profile, ocp)
      .then(response => {
        resolve(response.data);
      })
      .catch(error => {
        reject(error);
      });
  });
}

// Old endpoint
export function deleteProfile(email) {
  return new Promise((resolve, reject) => {
    Api.post(`/klaviyo/Remove`, { Email: email }, ocp)
      .then(response => {
        resolve(response.data);
      })
      .catch(error => {
        reject(error);
      });
  });
}

// Old endpoint
export function getLists() {
  return new Promise((resolve, reject) => {
    Api.get(`/klaviyo/Lists`, ocp)
      .then(response => {
        resolve(response.data);
      })
      .catch(error => {
        reject(error);
      });
  });
}

export function postSubscription(listId, email) {
  return new Promise((resolve, reject) => {
    Api.post(`/klaviyo/lists/${listId}/subscribe`, { Email: email }, ocp)
      .then(response => {
        resolve(response.data);
      })
      .catch(error => {
        reject(error);
      });
  });
}

export function deleteSubscription(listId, email) {
  return new Promise((resolve, reject) => {
    Api.delete(`/klaviyo/lists/${listId}/subscribe`, { data: { Email: email }, ...ocp })
      .then(response => {
        resolve(response.data);
      })
      .catch(error => {
        reject(error);
      });
  });
}

export function deleteSubscriptions(email) {
  return new Promise((resolve, reject) => {
    Api.delete(`/klaviyo/lists/subscribe`, { data: { Email: email }, ...ocp })
      .then(response => {
        resolve(response.data);
      })
      .catch(error => {
        reject(error);
      });
  });
}

export function getValidate(listId, email) {
  return new Promise((resolve, reject) => {
    Api.get(`/klaviyo/lists/${listId}/validate/${email}`, ocp)
      .then(response => {
        resolve(response.data);
      })
      .catch(error => {
        reject(error);
      });
  });
}
