import settings from '@settings';

const routes = [
  {
    name: '/order-tracking/:id',
    path: '/order-tracking/:id',
    meta: { icon: 'file-spreadsheet', anonymous: true },
    component: () => import(`@scenes/SelfserviceScene/scenes/OrderScene`),
  },
  {
    name: '/selfservice/productcomplaint',
    path: '/reklamation',
    meta: { icon: 'file-signature' },
    component: () => import(`@scenes/SelfserviceScene/scenes/ProductComplaintScene`),
  },
  {
    name: '/selfservice/productcomplaint/:id',
    path: '/reklamation/:id',
    meta: { hideFromNavigation: true }, // Hiding the route from navigation, since its a dynamic page (:id)
    component: () => import(`@scenes/SelfserviceScene/scenes/RmaScene`),
  },
  {
    name: '/selfservice/productcomplaint/:id/print',
    path: '/reklamation/:id/print',
    props: { print: true },
    meta: { hideFromNavigation: true }, // Hiding the route from navigation, since its a dynamic page (:id)
    component: () => import(`@scenes/SelfserviceScene/scenes/RmaScene`),
  },
  {
    name: '/selfservice/returns',
    path: '/retur',
    meta: { icon: 'file-signature' },
    component: () => import(`@scenes/SelfserviceScene/scenes/ReturnsScene`),
  },
  {
    name: '/selfservice/returns/:id',
    path: '/retur/:id',
    meta: { hideFromNavigation: true }, // Hiding the route from navigation, since its a dynamic page (:id)
    component: () => import(`@scenes/SelfserviceScene/scenes/RmaScene`),
  },
  {
    name: '/selfservice/returns/:id/print',
    path: '/retur/:id/print',
    props: { print: true },
    meta: { hideFromNavigation: true }, // Hiding the route from navigation, since its a dynamic page (:id)
    component: () => import(`@scenes/SelfserviceScene/scenes/RmaScene`),
  },
  {
    name: '/selfservice/orders',
    path: '/orderhistory', // will be translated.
    meta: { icon: 'file-spreadsheet' },
    component: () => import(`@scenes/SelfserviceScene/scenes/OrdersScene`),
  },
  {
    name: '/selfservice/orders/:id',
    path: '/orderhistory/:id',
    meta: { hideFromNavigation: true }, // Hiding the route from navigation, since its a dynamic page (:id)
    component: () => import(`@scenes/SelfserviceScene/scenes/OrderScene`),
  },
  {
    name: '/selfservice/orders/:orderType/:orderNumber',
    path: '/orderhistory/:orderType/:orderNumber',
    meta: { hideFromNavigation: true }, // Hiding the route from navigation, since its a dynamic page (:id)
    component: () => import(`@scenes/SelfserviceScene/scenes/OrderScene`),
  },
  {
    name: '/selfservice/drafts',
    path: '/orderjournal',
    meta: { icon: 'shopping-cart' },
    component: () => import(`@scenes/SelfserviceScene/scenes/DraftsScene`),
  },
  {
    name: '/selfservice/drafts/:id',
    path: '/orderjournal/:id',
    meta: { hideFromNavigation: true },
    component: () => import(`@scenes/SelfserviceScene/scenes/DraftScene`),
  },
];

export default routes;
