<template>
  <transition-group
    :name="disableMove ? undefined : 'list-transition'"
    tag="ul"
    :css="false"
    @enter="enter"
    @leave="leave"
  >
    <slot />
  </transition-group>
</template>

<script>
import { gsap } from 'gsap/all';

export default {
  name: 'ListTransition',

  props: {
    disableMove: {
      type: Boolean,
      default: false,
    },
  },

  methods: {
    enter(element, done) {
      gsap.from(element, {
        height: 0,
        opacity: 0,
        duration: 0.4,
        onComplete() {
          element.style.height = null;
          done();
        },
      });
    },
    leave(element, done) {
      gsap.to(element, {
        height: 0,
        opacity: 0,
        duration: 0.4,
        overwrite: true,
        onComplete: done,
      });
    },
  },
};
</script>

<style lang="scss">
.list-transition-move {
  transition: transform 0.5s;
}
</style>
