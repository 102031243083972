import { XALApi } from '..';

/**
 *
 * @param {string} licensePlate
 */
export function closeSession(sessionId) {
  return new Promise((resolve, reject) => {
    XALApi.post(`/session/close/${sessionId}`)
      .then(response => {
        resolve('success');
      })
      .catch(error => {
        if (error.response && error.response.data) {
          reject(error.response.data);
        } else {
          reject(error);
        }
      });
  });
}
