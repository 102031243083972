<template>
  <div :class="['Checkbox', 'Input', 'Input--tight', showInvalid && 'Checkbox--invalid']">
    <input
      :id="id"
      type="checkbox"
      v-model="internalChecked"
      @change="e => $emit('change', e.target.checked)"
    />
    <label :for="id" class="Checkbox__label">
      <span>
        <slot name="default" />
        <div v-if="showInvalid && invalidMessage" class="Checkbox__invalid">
          {{ '* ' + invalidMessage }}
        </div>
      </span>
    </label>
  </div>
</template>

<script>
export default {
  name: 'Checkbox',

  model: {
    prop: 'checked',
    event: 'change',
  },

  props: {
    id: {
      type: String,
      required: true,
    },
    checked: {
      type: Boolean,
      default: false,
    },
    required: {
      type: Boolean,
      default: false,
    },
    invalidMessage: {
      type: String,
      default: '',
    },
  },

  data() {
    return {
      internalChecked: this.checked,
      showInvalid: false,
    };
  },

  computed: {
    isValid() {
      return (this.required && this.internalChecked) || !this.required;
    },
  },

  watch: {
    checked(to, from) {
      if (to !== from) this.internalChecked = to;
    },
    internalChecked() {
      this.showInvalid = false;
    },
    isValid(to) {
      if (this.validator) this.validator.updateItem(this.id, to);
    },
  },

  created() {
    if (this.required && this.validator) {
      this.validator.addItem(this.id, this.isValid, () => (this.showInvalid = !this.isValid));
    }
  },

  beforeDestroy() {
    if (this.validator) this.validator.removeItem(this.id);
  },

  inject: {
    validator: {
      default() {
        return; // having a default if no validator parent is avaliable
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.Checkbox {
  overflow: hidden;

  .Checkbox__label {
    display: flex;
    gap: 0.5em;
  }

  .Checkbox__label::before {
    background-color: $color-white;
    margin-right: 0em;
    min-width: 1.15em;
  }

  .Checkbox__invalid {
    margin-top: 0.5em;
  }

  &--invalid .Checkbox__label::before {
    border-color: $color-input-error;
  }

  &--invalid .Checkbox__invalid {
    color: $color-input-error;
  }
}
</style>
