<template>
  <Lightbox ref="lightbox" dark class="LoginModal" v-slot="{ close }">
    <div class="LoginModal__content">
      <Close class="LoginModal__close" @click="close" />
      <LoginScene :mode="loginMode" :doReload="doReload" :routing="false" />
    </div>
  </Lightbox>
</template>

<script>
import Lightbox from '@components/Lightbox';
import { Close } from '@components/Buttons';
import LoginScene, { Modes } from '@scenes/LoginScene';

export { Modes };

export default {
  name: 'LoginModal',

  components: {
    Lightbox,
    Close,
    LoginScene,
  },

  props: {
    doReload: {
      type: Boolean,
      default: true,
    },
  },

  data() {
    return {
      loginMode: Modes.LOGIN,
    };
  },

  methods: {
    open(mode) {
      this.loginMode = mode;
      this.$refs.lightbox.open();
    },
  },
};
</script>

<style lang="scss" scoped>
.LoginModal {
  .LoginModal__content {
    position: relative;
    max-width: 100%;
  }

  .LoginModal__close {
    position: absolute;
    top: 10px;
    right: 10px;
    z-index: 1;
  }
}
</style>
