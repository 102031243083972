<template>
  <div class="AccountUserSettingsSkeleton">
    <InputSkeleton
      v-for="i in inputs"
      :key="`inputs${i}`"
      class="AccountUserSettingsSkeleton__input"
    />
    <div v-for="i in settings" :key="`settings${i}`" class="AccountUserSettingsSkeleton__setting">
      <SkeletonGraphic :width="160" :height="19" />
    </div>
  </div>
</template>

<script>
import SkeletonGraphic from '../SkeletonGraphic';
import InputSkeleton from '../InputSkeleton';

export default {
  name: 'AccountUserSettingsSkeleton',

  components: {
    SkeletonGraphic,
    InputSkeleton,
  },

  props: {
    inputs: {
      type: Number,
      default: 3,
    },
    settings: {
      type: Number,
      default: 9,
    },
  },
};
</script>

<style lang="scss" scoped>
.AccountUserSettingsSkeleton {
  .AccountUserSettingsSkeleton__input {
    margin-bottom: 15px;
  }

  .AccountUserSettingsSkeleton__setting {
    display: flex;
    gap: 10px;
    margin-top: 4px;
  }
}
</style>
