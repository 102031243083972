export const StepAlignment = {
  LEFT: 'left',
  CENTER: 'center',
  RIGHT: 'right',
  JUSTIFY: 'justify',
  VERTICAL: 'vertical',
};

export const StepSize = {
  SMALL: 'small',
  MEDIUM: 'medium',
  LARGE: 'large',
};
