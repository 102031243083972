<template>
  <ModalDialog
    ref="modal"
    :title="$t('profile.choose_branch')"
    :confirmLabel="$t('profile.controls.confirm_branch')"
    :disableConfirm="!selectedStore"
    class="FavoriteStoreSelect"
    @confirm="updateStore"
    @open="onOpen"
    @close="onClose"
  >
    <StoreFinder
      :onUpdate="newStore => (selectedStore = newStore)"
      scrollable
      :preselect="favoriteStore"
      class="FavoriteStoreSelect__storeFinder"
    />
  </ModalDialog>
</template>

<script>
import { ModalDialog } from '@components/Modal';
import StoreFinder from '@components/StoreFinder';

export default {
  name: 'FavoriteStoreSelect',

  components: {
    ModalDialog,
    StoreFinder,
  },

  data() {
    return {
      selectedStore: null,
    };
  },

  computed: {
    favoriteStore() {
      return this.accountContainer.branch ?? null;
    },
  },

  methods: {
    open() {
      this.$refs.modal.open();
    },
    updateStore() {
      this.accountContainer.updateBranch(this.selectedStore);
      this.$refs.modal.close();
    },
    onOpen() {
      this.$emit('open');
    },
    onClose() {
      this.selectedStore = null;
      this.$emit('close');
    },
  },

  inject: {
    accountContainer: {
      default() {
        console.error('Missing AccountContainer');
        return;
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.FavoriteStoreSelect {
  .FavoriteStoreSelect__storeFinder {
    height: 400px;
    overflow: hidden;
  }
}
</style>
