import { flattenObject } from './flattenObject';
import { stringNewLine } from './stringNewLine';
import { sortByDateProp } from './sortByDateProp';
import { getInputFormatByType } from './getInputFormatByType';
import { formattedDatetime } from './formattedDatetime';

export default {
  flattenObject,
  stringNewLine,
  sortByDateProp,
  getInputFormatByType,
  formattedDatetime,
};
