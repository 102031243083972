<template>
  <Modal
    ref="modal"
    :title="title"
    :size="size"
    :dark="dark"
    :working="working"
    class="ModalDialog"
    @open="$emit('open')"
    @close="$emit('close')"
  >
    <template #default>
      <slot name="default" />
    </template>
    <template #controls="{ close }">
      <Button v-if="!hideCancel" :color="ButtonColors.WHITE" @click.stop="close">
        {{ cancelLabel || $t('cancel') }}
      </Button>
      <Button
        :color="confirmColor"
        :icon="confirmIcon"
        :disabled="disableConfirm"
        @click.stop="$emit('confirm')"
      >
        {{ confirmLabel || $t('confirm') }}
      </Button>
    </template>
  </Modal>
</template>

<script>
import Modal from '../Modal';
import { Button, colors as ButtonColors } from '@components/Buttons';
import { Sizes as ModalSizes } from '../../types';

export default {
  name: 'ModalDialog',

  components: {
    Modal,
    Button,
  },

  enums: {
    ButtonColors,
  },

  props: {
    title: {
      type: String,
      required: true,
    },
    size: {
      type: String,
      default: ModalSizes.SMALL,
    },
    dark: {
      type: Boolean,
      default: false,
    },
    working: {
      type: Boolean,
      default: false,
    },
    confirmLabel: {
      type: String,
      default: '',
    },
    cancelLabel: {
      type: String,
      default: '',
    },
    confirmColor: {
      type: String,
      default: '',
    },
    confirmIcon: {
      type: String,
      default: '',
    },
    disableConfirm: {
      type: Boolean,
      default: false,
    },
    hideCancel: {
      type: Boolean,
      default: false,
    },
  },

  methods: {
    open() {
      this.$refs.modal.open();
    },
    close() {
      this.$refs.modal.close();
    },
  },
};
</script>
