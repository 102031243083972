<script>
export const searchIndicatorSizes = {
  NORMAL: 'NORMAL',
  SMALL: 'SMALL',
};

export default {
  name: 'SearchIndicator',
  functional: true,

  props: {
    label: {
      default: '',
      required: false,
    },
    size: {
      default: searchIndicatorSizes.NORMAL,
      required: false,
    },
  },

  render(e, context) {
    const { label, size } = context.props;

    return (
      <div class="wrapper">
        <div class="inner">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 100 100"
            class={[
              'Spinner',
              'SpinnerSvg',
              size === searchIndicatorSizes.SMALL ? 'SpinnerSvg--small' : undefined,
            ].join(' ')}
          >
            <circle
              xmlns="http://www.w3.org/2000/svg"
              cx="50"
              cy="50"
              fill="none"
              stroke-width="4"
              r="30"
              stroke-dasharray="70"
            ></circle>
          </svg>

          {label && <div style="margin-top: 20px;">{label}</div>}
        </div>
      </div>
    );
  },
};
</script>

<style lang="scss" scoped>
.wrapper {
  position: absolute;
  z-index: 10;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: white;
}

.inner {
  text-align: center;
}

.SpinnerSvg {
  stroke: #5d5d5d;
  width: 100%;
  height: 140px;
  margin-top: 150px;

  &--small {
    margin-top: 0;
    max-width: 80%;

    height: 80px;
  }
}
</style>
