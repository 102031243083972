<template>
  <Modal
    ref="modal"
    :title="name"
    :size="ModalSizes.LARGE"
    :working="status === Progress.WORKING"
    @open="$emit('open')"
    @close="$emit('close')"
    class="LabelModal"
  >
    <template #default>
      <Alert v-if="status === Progress.ERROR" :level="AlertLevel.ERROR" hideIcon>
        {{ $t('error_messages.try_again') }}
      </Alert>
      <div class="LabelModal__text" v-html="text" />
    </template>
  </Modal>
</template>

<script>
import Progress from '@types/Progress';
import { getTexts } from '@services/thansen/forms';
import Modal from '../Modal';
import Alert, { AlertLevel } from '@components/Alert';
import { Sizes as ModalSizes } from '../../types';

export default {
  name: 'LabelModal',

  components: {
    Modal,
    Alert,
  },

  enums: {
    Progress,
    AlertLevel,
    ModalSizes,
  },

  props: {
    label: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      name: '',
      text: '',
      status: '',
    };
  },

  methods: {
    open() {
      if (this.status !== Progress.COMPLETE) this.fetchText();
      this.$refs.modal.open();
    },
    close() {
      this.$refs.modal.close();
    },
    fetchText() {
      this.status = Progress.WORKING;
      getTexts(this.label)
        .then(data => {
          this.name = data.name;
          this.text = data.text;
          this.status = Progress.COMPLETE;
        })
        .catch(() => {
          this.status = Progress.ERROR;
        });
    },
  },
};
</script>

<style lang="scss">
.LabelModal {
  .LabelModal__text ul,
  .LabelModal__text ol {
    padding-left: 1.2em;
    margin-bottom: 0.8em;

    list-style: initial;
    list-style-type: initial;
  }
  .LabelModal__text li {
    list-style-type: initial;
  }
}
</style>
