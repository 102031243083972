<template>
  <div class="ProductCard__price">
    <div
      v-if="bundleOffers.length > 0 && !($options.settings.mode === 'b2b' && !showHiddenPrice)"
      :class="['ProductCard__offers']"
    >
      <div v-for="(offer, index) in bundleOffers" :key="index" class="ProductCard__offer">
        {{ $t('bundle-text', [offer.quantity, $n(offer.price * offer.quantity, 'currency')]) }}
      </div>
    </div>

    <template v-if="$options.settings.mode === 'b2b' && productStatus && productStatus.price">
      <div @dblclick="() => (localShowHiddenPrice = !localShowHiddenPrice)">
        <div>
          <Price v-if="isOnSale" :value="compareAtPrice" class="ProductCard__compareAtPrice" />
          <Price :value="productStatus.detailPrice" />
        </div>
        <div v-if="showHiddenPrice">
          <Price
            v-if="
              productStatus.compare_at_price && productStatus.compare_at_price > productStatus.price
            "
            tag="div"
            :value="productStatus.compare_at_price"
            class="ProductCard__compareAtPrice"
          />
          <Price tag="div" :value="productStatus.price" />
        </div>
      </div>
    </template>
    <template v-else>
      <Price v-if="isOnSale" :value="compareAtPrice" class="ProductCard__compareAtPrice" />
      <Price :value="productPrice" />
    </template>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import helpers from '@helpers';
import settings from '@settings';

import Price from '@components/Price';

export default {
  name: 'ProductCardPrice',
  components: {
    Price,
  },
  props: {
    compareAtPrice: {
      required: false,
    },
    productPrice: {
      required: true,
    },
    isOnSale: {
      required: true,
    },
    bundleOffers: {
      required: true,
    },
    productId: {
      required: true,
    },
    productStatus: {
      required: true,
    },
  },
  data: function() {
    return {
      localShowHiddenPrice: false,
    };
  },
  computed: {
    showHiddenPrice() {
      return settings.shp || this.localShowHiddenPrice;
    },
  },

  settings,
};
</script>
