// State in data didn't work. Would only be changed every other time.
let bodyScrollablePrevented = false;
let scrollY = 0;

let scrollBarWidth = (document.body.clientWidth - window.innerWidth) * -1;

// If old Edge (not Chromium, which is Edg, without the last "e")
// ... Firefox, Safari, Chrome and even Internet Explorer all report the clientWidth and innerWidth correctly
if (window.navigator.userAgent.indexOf('Edge') > -1) {
  scrollBarWidth = 16;
}

export default {
  name: 'PreventBodyScroll',

  props: {
    prevent: {
      required: false,
      type: Boolean,
    },
  },

  mounted() {
    if (this.prevent !== undefined && this.prevent) {
      this.setPrevent(this.prevent);
    }
  },

  beforeDestroy() {
    // Reset in any case on destroy

    this.setPrevent(false);
  },

  watch: {
    prevent(newValue) {
      if (newValue !== undefined) {
        this.setPrevent(newValue);
      }
    },
  },

  methods: {
    setPrevent(state) {
      // Toggle the scrollable
      bodyScrollablePrevented = state;
      scrollY = window.scrollY;
      if (bodyScrollablePrevented) {
        // Set scrollY
        scrollY = window.scrollY;

        // Remove ability to scroll and manually scroll to previous location.
        document.body.style.overflowY = 'hidden';
        document.body.style.paddingRight = `${scrollBarWidth}px`;

        requestAnimationFrame(() => {
          window.scrollTo(0, scrollY);
        });

        // Set topbar padding, when the sticky top class is added
        const stickyTopBarHolder = document.querySelector('.TopBarHolder.sticky-top');

        if (stickyTopBarHolder !== null) {
          stickyTopBarHolder.style.paddingRight = `${scrollBarWidth}px`;
        }

        window.scrollTo(0, scrollY);
      } else {
        // Reset scrollability and return to old position
        document.body.style.overflowY = 'auto';
        document.body.style.paddingRight = '0';

        // Reset topbar padding
        const topBarHolder = document.querySelector('.TopBarHolder');
        if (document.querySelector('.TopBarHolder') !== null) {
          topBarHolder.style.paddingRight = '0';
        }

        window.scrollTo(0, scrollY);
      }
    },
  },

  render() {
    return this.$scopedSlots.default({
      enablePrevent: () => this.setPrevent(false),
      disablePrevent: () => this.setPrevent(true),
      isPrevented: () => bodyScrollable,
    });
  },
};
