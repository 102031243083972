<template>
  <PopoverHandler
    :locked="locked"
    :toggleOnClick="toggleOnClick"
    @open="onOpen"
    @close="onClose"
    :class="['Dropdown', active && 'Dropdown--active']"
  >
    <template #default>
      <div class="Dropdown__target">
        <slot name="default" />
      </div>
    </template>
    <template #content="{ close }">
      <div class="Dropdown__arrow" />
      <div class="Dropdown__arrowShadow" />
      <div class="Dropdown__menu">
        <slot name="content" :close="close" />
      </div>
    </template>
  </PopoverHandler>
</template>

<script>
import PopoverHandler from '@components/PopoverHandler';

export { default as DropdownSection } from './components/DropdownSection';
export { default as DropdownButton } from './components/DropdownButton';

export default {
  name: 'Dropdown',

  components: {
    PopoverHandler,
  },

  props: {
    locked: {
      type: Boolean,
      default: false,
    },
    toggleOnClick: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      active: false,
    };
  },

  methods: {
    onOpen() {
      this.active = true;
    },
    onClose() {
      this.active = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.Dropdown {
  position: relative;
  display: inline-block;

  &--active {
    z-index: 9900;
  }

  .Dropdown__target {
    position: relative;
    z-index: 1;
  }

  .Dropdown__arrow,
  .Dropdown__arrowShadow {
    position: absolute;
    top: calc(100% + 12px);
    left: 50%;
    width: 12px;
    height: 12px;
    transform-origin: 0% 0%;
    transform: rotate(45deg) translate3d(-50%, -50%, 0);
  }

  .Dropdown__arrow {
    z-index: 3;
    background-color: #ffffff;
  }

  .Dropdown__arrowShadow {
    z-index: 1;
    box-shadow: 0 0px 5px rgba(0, 0, 0, 0.1);
  }

  .Dropdown__menu {
    position: absolute;
    z-index: 2;
    top: calc(100% + 12px);
    right: 0%;
    display: flex;
    flex-direction: column;
    width: 240px;
    background-color: #ffffff;
    box-shadow: 0 0px 5px rgba(0, 0, 0, 0.1);
  }
}
</style>
