<template>
  <div class="SearchField">
    <div class="Input Input--search Input--medium Input--grey Input--tight">
      <div class="Input__fieldWrap SearchField__field">
        <div class="Input__spinner">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100" class="Spinner">
            <circle
              xmlns="http://www.w3.org/2000/svg"
              cx="50"
              cy="50"
              fill="none"
              stroke="#ff708e"
              stroke-width="8"
              r="30"
              stroke-dasharray="70"
            ></circle>
          </svg>
        </div>
        <i
          :class="[
            'Input__icon',
            'far',
            'fa-search',
            options !== undefined && 'Input__icon--integratedSelect',
          ]"
        ></i>

        <form
          autocomplete="off"
          method="post"
          action=""
          @submit.prevent="$emit('search', valueInput)"
        >
          <Select
            v-if="options !== undefined"
            :items="options"
            :integrated="true"
            :action="optionsChanged"
          />

          <input
            :class="options !== undefined && 'SearchInputWithIntegratedSelect'"
            ref="input"
            v-model.trim="valueInput"
            :placeholder="computedPlaceholder"
            type="search"
            name="search"
            autocomplete="off"
            autocorrect="off"
            autocapitalize="none"
            spellcheck="false"
            role="combobox"
            style="text-overflow: ellipsis;"
          />
        </form>
        <button class="Input__clear js-clear"></button>
        <div v-if="hint" class="SearchField__hint">{{ hint }}</div>
      </div>
      <span class="Input__message js-message"></span>
    </div>
    <slot />
  </div>
</template>

<script>
import Select from '@components/Select';

export default {
  name: 'SearchField',

  components: { Select },

  props: {
    placeholder: {
      type: String,
      default: '',
    },
    value: {
      // Remember to set the .sync value.
      type: String,
      default: '',
    },
    hint: {
      type: String,
      default: '',
    },
    debounce: {
      type: Number,
      default: 0,
    },
    // Only emit "search" on enter (or button click?)
    typeahead: {
      type: Boolean,
      default: true,
    },
    stalling: {
      type: Boolean,
    },
    autoFocus: {
      type: Boolean,
      default: false,
    },

    selectedOption: {
      required: false,
      default: undefined,
      type: String,
    },

    options: {
      required: false,
      type: Array,
      default: undefined,
    },
  },
  computed: {
    valueInput: {
      get: function() {
        return this.value;
      },
      set: function(newValue) {
        this.emitInputValue(newValue);
      },
    },

    computedPlaceholder() {
      const { options, selectedOption, placeholder } = this;

      if (options !== undefined && selectedOption !== undefined) {
        const placeholderFromOption = options.find(option => option.value === selectedOption)
          .placeholder;

        // Return the placeholder from selected option, or the default placeholder
        return placeholderFromOption ?? placeholder;
      }

      // Return the default placeholder if no options and selection of such are provided
      return placeholder;
    },
  },
  created() {
    if (!this.placeholder) {
      this.placeholder = this.$t('search');
    }
  },
  mounted() {
    if (this.autoFocus) {
      this.$refs.input.focus();
    }
  },
  methods: {
    optionsChanged(newValue) {
      this.$emit('update:selected-option', newValue);
    },

    emitInputValue(newValue) {
      this.$emit('update:value', newValue);
      if (this.typeahead) {
        if (this.debounce > 0) {
          if (this.emitDebounce !== undefined) {
            clearTimeout(this.emitDebounce);
          }
          this.emitDebounce = setTimeout(() => {
            this.$emit('search', newValue);
          }, this.debounce);
        } else {
          this.$emit('search', newValue);
        }
      }
    },
  },
};
</script>

<style lang="scss">
.SearchField__hint {
  position: absolute;
  top: 50%;
  right: 20px;
  transform: translateY(-50%);
  font-size: 14px;
}
.SearchField__field {
  position: relative;
}

.SearchInputWithIntegratedSelect {
  padding-left: calc(1.35em + 100px) !important;

  @media only screen and (min-width: 600px) {
    padding-left: calc(1.35em + 100px) !important;
  }
}

.Input__icon--integratedSelect {
  left: auto !important;
  right: 20px !important;
  transform: translateY(-50%) !important;
}
</style>
