var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{key:_vm.id,class:[
    'Step',
    ("Step--" + _vm.alignment),
    _vm.active && 'Step--active',
    _vm.complete && 'Step--complete',
    _vm.touched && 'Step--touched',
    _vm.lastTouched && 'Step--lastTouched',
    _vm.isClickable && 'Step--clickable' ]},[_c('div',{staticClass:"Step__bar"},[_c('div',{staticClass:"Step__iconFrame",on:{"click":_vm.stepClick}},[(_vm.showNumber)?_c('p',{staticClass:"Step__number"},[_vm._v(_vm._s(_vm.stepIndex + 1))]):_c('i',{class:['Step__icon', _vm.icon]})])]),_c('div',{staticClass:"Step__content"},[_c('div',{staticClass:"Step__label"},[(_vm.kicker)?_c('span',{staticClass:"Step__kicker",on:{"click":_vm.stepClick}},[_vm._v(_vm._s(_vm.kicker))]):_vm._e(),(_vm.title)?_c('span',{staticClass:"Step__title",on:{"click":_vm.stepClick}},[_vm._v(_vm._s(_vm.title))]):_vm._e(),(_vm.subtitle)?_c('span',{staticClass:"Step__subtitle",on:{"click":_vm.stepClick}},[_vm._v(_vm._s(_vm.subtitle))]):_vm._e()]),_vm._t("default")],2)])}
var staticRenderFns = []

export { render, staticRenderFns }