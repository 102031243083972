import Vue from 'vue';
import { getStatusByProductNode } from '../../../services/thansen/products';
import { Mutation } from './types';

import settings from '@settings';

// Holds current version
const _version = settings.storeVersions.products;

const statusExpireTime = 60000;
const statusCleanTime = 60000 * 5;

// Changing this version will result in an reset for older versions
const getDefaultState = () => {
  return {
    status: '',
    productStatus: [],
    _version: '', // Must be empty to support old modules withour _version.
  };
};

export default {
  namespaced: true,
  state: getDefaultState(),
  getters: {
    getProductStatusByProductNode: state => pn => {
      return state.productStatus.find(status => status.pn === pn.toString());
    },
    getProductStatusIndexByProductNode: state => pn => {
      return state.productStatus.findIndex(status => status.pn === pn.toString());
    },
    getProductStatusStatus: state => pn => {
      const productStatus = state.productStatus.find(status => status.pn === pn.toString());
      if (productStatus) return productStatus.status;
      else return 'not found';
    },
  },
  actions: {
    fetchProductStatus({ commit, state, getters }, pn) {
      return new Promise((resolve, reject) => {
        // Ignores stacked calls
        const existingStatus = getters.getProductStatusByProductNode(pn);

        if (
          existingStatus &&
          existingStatus.expires > new Date().getTime() &&
          existingStatus.status !== 'updating'
        ) {
          return resolve(existingStatus);
        }

        if (!existingStatus) {
          commit(Mutation.ADD_PRODUCTSTATUS, { pn, status: 'fetching' });
        } else {
          commit(Mutation.SET_PRODUCTSTATUS_STATUS, {
            index: getters.getProductStatusIndexByProductNode(pn),
            status: 'updating',
          });
        }

        getStatusByProductNode(pn)
          .then(productStatus => {
            //expires: new Date().getTime() + expireTime,
            const statusIndex = getters.getProductStatusIndexByProductNode(pn);
            if (statusIndex !== -1) {
              commit(Mutation.UPDATE_PRODUCTSTATUS, { index: statusIndex, productStatus });
            } else {
              // This should not happen. Since we are adding the
              commit(Mutation.ADD_PRODUCTSTATUS, productStatus);
            }
            commit(Mutation.CLEANUP_PRODUCTSTATUS);
            resolve(productStatus);
          })
          .catch(err => {
            const index = getters.getProductStatusIndexByProductNode(pn);
            if (index !== -1) {
              commit(Mutation.SET_PRODUCTSTATUS_STATUS, {
                index: getters.getProductStatusIndexByProductNode(pn),
                status: 'failed',
              });
            }

            reject(err);
          });
      });
    },
    cleanupStatus({ commit }) {
      commit(Mutation.CLEANUP_PRODUCTSTATUS);
    },
    checkVersion({ commit, state, getters }) {
      // Checks and resets the state if version is out of sync.
      if (!state._version || state._version !== _version) {
        commit(Mutation.RESET);
      }
    },
    reset({ commit }) {
      commit(Mutation.RESET);
    },
  },
  mutations: {
    [Mutation.CLEANUP_PRODUCTSTATUS](state) {
      state.productStatus = state.productStatus.filter(productStatus => {
        return (
          productStatus.status !== 'avaliable' ||
          productStatus.expires + statusCleanTime > new Date().getTime()
        );
      });
    },
    [Mutation.SET_PRODUCTSTATUS_STATUS](state, { index, status }) {
      Vue.set(state.productStatus, index, {
        ...state.productStatus[index],
        ...{
          status,
        },
      });
    },
    [Mutation.UPDATE_PRODUCTSTATUS](state, { index, productStatus }) {
      Vue.set(state.productStatus, index, {
        ...productStatus,
        ...{
          pn: productStatus.pn.toString(),
          expires: new Date().getTime() + statusExpireTime,
          expired: false,
          status: 'avaliable',
          priceCode:
            'ADD' + productStatus.price.toString().replace(/[&/\\#,+()$~%.'":*?<>{}]/g, '') + 'Q',
        },
      });
    },
    [Mutation.ADD_PRODUCTSTATUS](state, productStatus) {
      state.productStatus.push(productStatus);
    },
    [Mutation.RESET](state) {
      Object.assign(state, { ...getDefaultState(), ...{ _version } });
    },
  },
};
