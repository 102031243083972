import { Api, LegacyApi } from '../';
import Axios from 'axios';

/**
 * Get account
 */
export function getAccount() {
  return new Promise((resolve, reject) => {
    Api.get('account')
      .then(response => {
        if (response.data.errors || !response.data.data) reject(response.data.errors);
        const account = response.data.data;
        // Parsing "dealer_legacy" as "dealer". Remove when dealer type has been cleaned up (klm & mvn)
        if (account.type === 'dealer_legacy') account.type = 'dealer';

        resolve(account);
      })
      .catch(error => {
        reject(error);
      });
  });
}

/**
 * Create new account
 * @param {object} newAccount
 */
export function postCreateAccount(newAccount) {
  return new Promise((resolve, reject) => {
    Api.post('account', newAccount)
      .then(response => {
        resolve(response.data.data);
      })
      .catch(error => {
        reject(error);
      });
  });
}

/**
 * Update account
 */
export function putAccount(account) {
  return new Promise((resolve, reject) => {
    Api.put('account', account)
      .then(response => {
        if (response.data.errors || !response.data.data) reject(response.data.errors);

        resolve(response.data.data);
      })
      .catch(error => {
        console.log(error);

        reject(error);
      });
  });
}

/**
 * Delete account
 */
export function deleteAccount(id) {
  return new Promise((resolve, reject) => {
    Api.delete(`users/${id}`)
      .then(response => {
        if (response.data.errors || !response.data.data) reject(response.data.errors);
        resolve(response.data.data);
      })
      .catch(error => {
        reject(error);
      });
  });
}

/**
 * Get account overview
 * @param {object} options
 */
export function getOverview(options) {
  return new Promise((resolve, reject) => {
    Api.get('/account/overview', { options })
      .then(response => {
        resolve(response.data.data ?? response.data);
      })
      .catch(error => {
        reject(error);
      });
  });
}

/**
 * Get all account users
 */
export function getUsers(options) {
  return new Promise((resolve, reject) => {
    LegacyApi.get('users/Get', { options })
      .then(response => {
        resolve(response.data.data ?? response.data);
      })
      .catch(error => {
        reject(error);
      });
  });
}

/**
 * Get settings
 */
export function getUserSettings() {
  return new Promise((resolve, reject) => {
    LegacyApi.get('usersettings/getsettings')
      .then(response => {
        resolve(response.data.data ?? response.data);
      })
      .catch(error => {
        reject(error);
      });
  });
}

/**
 * Get a specific account user
 */
export function getUser(id) {
  return new Promise((resolve, reject) => {
    LegacyApi.get(`users/GetById/${id}`)
      .then(response => {
        resolve(response.data.data ?? response.data);
      })
      .catch(error => {
        reject(error);
      });
  });
}

/**
 * Patches/updates a specific account user
 */
function patchUser(id, user) {
  return new Promise((resolve, reject) => {
    const methods = [];

    if (user.Settings) {
      methods.push(
        LegacyApi.post('usersettings/Update', {
          UserId: id,
          Settings: user.Settings,
        })
          .then(response => {
            resolve();
          })
          .catch(error => {
            reject(error);
          }),
      );
    }
    if (user.Email) {
      methods.push(
        LegacyApi.post('users/update', {
          UserId: id,
          Email: user.Email,
        })
          .then(response => {
            resolve();
          })
          .catch(error => {
            reject(error);
          }),
      );
    }
    if (methods.length === 0) {
      resolve();
    } else {
      Promise.all(methods)
        .then(values => {
          resolve();
        })
        .catch(errors => {
          reject(errors);
        });
    }
  });
}

/**
 * Delete a specific account user
 */
export function deleteUser(id) {
  return new Promise((resolve, reject) => {
    LegacyApi.delete(`users/delete/${id}`)
      .then(response => {
        resolve();
      })
      .catch(error => {
        reject(error);
      });
  });
}

function resetUserPassword(email, accountId, username) {
  return new Promise(async (resolve, reject) => {
    const postStr =
      'Email=' +
      encodeURIComponent(email) +
      '&CustomerNo=' +
      encodeURIComponent(accountId) +
      '&UserName=' +
      encodeURIComponent(username) +
      '&XC=1';

    Axios.post('/forgotyourpassword.asp', postStr, {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    })
      .then(response => {
        resolve();
      })
      .catch(error => {
        reject(error);
      });
  });
}

/**
 * Create account user
 */
export function postUser(user) {
  return new Promise(async (resolve, reject) => {
    const { UserName, EMail, AccountNumber, Settings } = user;

    const createResponse = await LegacyApi.post('users/Create', {
      UserName,
      EMail,
      AccountNumber,
    }).catch(error => {
      reject(error);
    });
    const { Id } = createResponse.data.data ?? createResponse.data;

    await patchUser(Id, { Settings }).catch(error => {
      reject(error);
    });

    resetUserPassword(EMail, AccountNumber, UserName);

    //const userResponse = await getUser(Id);
    //resolve(userResponse.data.data ?? userResponse.data);
    resolve();
  });
}

export { patchUser, resetUserPassword };
