export const isToday = dateString => {
  const today = new Date();
  const date = new Date(dateString);
  return (
    date.getDate() == today.getDate() &&
    date.getMonth() == today.getMonth() &&
    date.getFullYear() == today.getFullYear()
  );
};

export const isBeforeNow = dateString => {
  const today = new Date();
  const date = new Date(dateString);
  return date < today;
};
