<template>
  <div v-if="!closed" :class="['Alert', 'Alert--' + level, inline && 'Alert--inline']">
    <i v-if="!hideIcon && iconClass" :class="['Alert__icon', iconClass]" />
    <div class="Alert__content">
      <div v-if="title" class="Alert__title">{{ title }}</div>
      <slot name="default" />
    </div>
    <div v-if="linkLabel || closeable" class="Alert__link">
      <button v-if="linkLabel" class="Alert__linkButton" @click="$emit('link-click')">
        {{ linkLabel }} <i class="fas fa-arrow-right" />
      </button>
      <Close v-else :size="ButtonSizes.SMALL" @click="(closed = true), $emit('close')" />
    </div>
  </div>
</template>

<script>
import { Close, sizes as ButtonSizes } from '@components/Buttons';
import AlertLevel from '@types/AlertLevel';

export { AlertLevel };

export default {
  name: 'Alert',

  components: {
    Close,
  },

  enums: {
    ButtonSizes,
  },

  props: {
    level: {
      type: String,
      default: AlertLevel.TRACE,
    },
    title: {
      type: String,
      default: '',
    },
    linkLabel: {
      type: String,
      default: '',
    },
    hideIcon: {
      type: Boolean,
      default: false,
    },
    closeable: {
      type: Boolean,
      default: false,
    },
    inline: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      closed: false,
    };
  },

  computed: {
    iconClass() {
      switch (this.level) {
        case AlertLevel.SUCCESS:
          return 'fas fa-check-circle';
        case AlertLevel.TRACE:
          return 'fas fa-exclamation-triangle';
        case AlertLevel.ERROR:
          return 'fas fa-times-circle';
        case AlertLevel.INFO:
          return 'fas fa-info-circle';
        case AlertLevel.WARNING:
          return 'fas fa-exclamation-triangle';
        default:
          return '';
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.Alert {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 10px 20px;
  border: 1px solid;
  border-radius: 2px;
  font-size: 15px;
  &--inline {
    display: inline-flex;
  }

  .Alert__icon {
    margin-right: 20px;
    font-size: 1.1em;
  }

  .Alert__content {
    padding-right: 20px;
    flex: 1;
  }

  .Alert__link {
    justify-self: flex-end;

    //ToDo MET - må man ændre udsene for .Close her?
    .Close {
      width: 24px;
      height: 24px;
    }
  }

  .Alert__linkButton {
    outline: none;
    border: none;
    background: none;
    padding: 15px 0;
    margin: 0;
    font-weight: bold;
  }

  .Alert__linkButton i {
    margin-left: 0.2em;
  }

  .Alert__title {
    font-weight: bold;
  }

  &.Alert--error {
    color: #971616;
    border-color: #ff5252;
    background-color: #ffebeb;
    .Alert__icon {
      color: #ff5252;
    }
  }

  &.Alert--info {
    color: #1f1f1f;
    background-color: #f8feff;
    border-color: #7cc3eb;
    .Alert__icon {
      color: #3071a9;
    }
  }

  &.Alert--warning {
    color: #664d03;
    background-color: #fff3cd;
    border-color: #ffecb5;
    .Alert__icon {
      color: #ff9900;
    }
  }

  &.Alert--success {
    color: #505314;
    background-color: #ebfbf1;
    border-color: #00c853;
    .Alert__icon {
      color: #00c853;
    }
  }

  a {
    text-decoration: underline;
    font-weight: bold;
  }
}
</style>
