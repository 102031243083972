<template>
  <li
    :role="isExpandable ? 'treeitem' : 'none'"
    :aria-expanded="isExpandable ? expanded.toString() : false"
    tabindex="0"
    :aria-level="level"
    :aria-selected="selected"
  >
    <a
      v-if="item.url && !isExpandable"
      class="TreeNavigation__itemName"
      :href="item.url"
      tabindex="-1"
      :role="!isExpandable ? 'treeitem' : 'none'"
    >
      <img v-if="item.icon" class="TreeNavigation__itemIcon" :src="item.icon" alt="" />{{
        item.name
      }}</a
    >
    <span
      v-else
      class="TreeNavigation__itemName"
      :role="!isExpandable ? 'treeitem' : 'none'"
      @click="toggleExpand"
      ><img v-if="item.icon" class="TreeNavigation__itemIcon" :src="item.icon" alt="" />{{
        item.name
      }}</span
    >
    <CollapseTransition>
      <ul v-if="item.children" v-show="expanded" role="group">
        <TreeNavigationItem
          v-for="(child, index) in item.children"
          :key="index"
          :item="child"
          :level="level + 1"
        />
      </ul>
    </CollapseTransition>
  </li>
</template>

<script>
import { CollapseTransition } from '@transitions';
import TreeNavigationItem from './index';

export default {
  name: 'TreeNavigationItem',
  components: {
    CollapseTransition,
    TreeNavigationItem,
  },
  props: {
    item: {
      type: Object,
      default() {
        return {};
      },
    },
    level: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      expanded: false,
      selected: false,
    };
  },
  computed: {
    isExpandable() {
      return this.item.children && this.item.children.length > 0;
    },
  },
  mounted() {},
  created() {
    this.expanded = this.item.expanded === true;
    this.selected = this.item.selected === true;
  },
  methods: {
    toggleExpand() {
      this.expanded = !this.expanded;
    },
  },
};
</script>

<style></style>
