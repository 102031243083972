<template>
  <component
    :is="branch.url ? 'a' : 'div'"
    :href="branch.url ? branch.url : null"
    :title="branch.url ? branch.name : null"
    :class="[
      'BranchCard',
      active && 'BranchCard--active',
      clickable && 'BranchCard--clickable',
      editable && 'BranchCard--editable',
    ]"
    @click="(clickable || editable) && $emit('click')"
  >
    <div class="BranchCard__content">
      <span class="BranchCard__title ft-h4">{{ branch.name }}</span>
      <Address class="BranchCard__address" :data="branch.address" />
      <OpeningHoursStatus
        v-if="!hidetime"
        :openingHours="branch.opening_hours_specification.opening_hours"
      />
    </div>
    <div class="BranchCard__imageContainer">
      <i v-if="editable" class="BranchCard__icon far fa-pen" />
      <ImageComponent
        :src="branch.image"
        :alt="branch.name"
        aspectRatio="1:1"
        fit="cover"
        class="BranchCard__image"
      />
    </div>
  </component>
</template>

<script>
import Address from '@components/Address';
import ImageComponent from '@components/ImageComponent';
import OpeningHoursStatus from '@components/OpeningHoursStatus';

export default {
  name: 'BranchCard',

  components: {
    Address,
    ImageComponent,
    OpeningHoursStatus,
  },

  props: {
    branch: {
      type: Object,
      required: true,
    },
    clickable: {
      type: Boolean,
      default: false,
    },
    active: {
      type: Boolean,
      default: false,
    },
    hidetime: {
      type: Boolean,
      default: false,
    },
    editable: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss" scoped>
.BranchCard {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  overflow: hidden;

  &:hover {
    text-decoration: initial;
  }

  &--active {
    box-shadow: inset 0 0 0 1px $color-prim-grey;
    border-radius: $border-radius;
  }

  .BranchCard__content {
    padding: 20px;
  }

  .BranchCard__address {
    color: $color-prim-grey-dark;
  }

  .BranchCard__imageContainer {
    position: relative;
  }

  .BranchCard__icon {
    position: absolute;
    z-index: 10;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: $color-white;
    font-size: 30px;
    opacity: 0;
    text-shadow: 0px 0px 4px rgba(0, 0, 0, 0.5);
    transition: 0.2s;
  }

  .BranchCard__image {
    width: 120px;
  }

  &--clickable {
    cursor: pointer;
  }

  &--clickable.BranchCard--active:hover {
    box-shadow: inset 0 0 0 2px $color-prim-grey;
  }

  &--editable {
    cursor: pointer;
    &:hover {
      transition: 0.2s;
      border-color: lighten($color-prim-blue, 10%);
      .BranchCard__title {
        transition: 0.2s;
        color: lighten($color-prim-blue, 10%);
      }
      .BranchCard__icon {
        transition: 0.2s;
        opacity: 1;
      }
      .BranchCard__image {
        transition: 0.2s;
        filter: brightness(0.9);
      }
    }
  }
}
</style>
