<template>
  <Validator v-slot="{ valid, showError }" ref="validator">
    <Form
      :title="$t('account.auth.reset_password')"
      :locked="working"
      class="ResetPasswordForm"
      @submit="valid ? resetPassword() : showError()"
    >
      <template #default>
        <NewPasswordInputs
          ref="newPasswordInputs"
          autoFocus
          @input="password => (newPassword = password)"
        />
      </template>
      <template #footer>
        <Button :working="working">{{ $t('account.auth.save_password') }}</Button>
      </template>
    </Form>
  </Validator>
</template>

<script>
import { isBeforeNow } from '@helpers/date';
import Validator from '@containers/Validator';
import Form from '@layouts/Form';
import NewPasswordInputs from '@components/NewPasswordInputs';
import { Button } from '@components/Buttons';

export default {
  name: 'ResetPasswordForm',

  components: {
    Validator,
    Form,
    NewPasswordInputs,
    Button,
  },

  data() {
    return {
      newPassword: '',
      working: false,
    };
  },

  created() {
    if (!this.$route.query.token) this.goToLogin('invalid_token');
    else if (this.isTokenExpired()) this.goToLogin('expired_token');
  },

  methods: {
    goToLogin(flash) {
      window.location.replace(`/login?flash=${flash}`);
    },
    isTokenExpired() {
      const expireAt = Number(this.$route.query.expiry) * 1000;
      return this.$route.query.expiry && isBeforeNow(expireAt);
    },
    resetPassword() {
      this.working = true;
      this.authenticationContainer
        .changePassword(this.$route.query.token, this.newPassword)
        .then(this.onResetCompleted)
        .catch(this.onResetFailed);
    },
    onResetCompleted() {
      this.$refs.newPasswordInputs.clear();
      this.goToLogin('changed');
    },
    onResetFailed() {
      this.goToLogin('invalid_token');
    },
  },

  inject: {
    authenticationContainer: {
      default() {
        console.error('Missing AuthenticationContainer');
        return;
      },
    },
  },
};
</script>
