<template>
  <div class="BuyButton">
    <input
      v-if="enableAmount"
      v-model="amount"
      class="BuyButton__amount inputamount"
      name="amount"
      type="number"
      pattern="[0-9]"
      step="1"
      maxlength="4"
      min="1"
    />

    <Button
      :type="settings.xalmode ? 'system' : 'cta'"
      :size="size"
      :data-arg="getBuyArgs"
      :count="quantity"
      :class="['BuyButton__add', { BuyBtn: !settings.xalmode }]"
      @click="addToCart"
    >
      <span class="Button__status">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100" class="Spinner">
          <circle
            xmlns="http://www.w3.org/2000/svg"
            cx="50"
            cy="50"
            fill="none"
            stroke="#ff708e"
            stroke-width="8"
            r="30"
            stroke-dasharray="70"
          ></circle>
        </svg>
      </span>
      <span class="Button__text">{{ settings.xalmode ? 'Til XAL' : $t('buy') }}</span>
    </Button>

    <Button
      v-if="settings.xalmode && quantity > 0"
      class="BuyButton__remove"
      :size="size"
      type="light"
      @click.native="removeFromCart"
    >
      <i class="far fa-trash"></i>
    </Button>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { Button } from '@components/Buttons';

import settings from '@settings';

export default {
  name: 'BuyButton',

  components: { Button },

  props: {
    enableAmount: {
      type: Boolean,
      default: false,
    },
    size: {
      // exported sized const from index.js used as ENUM here
      type: String,
      default: '',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    product: {
      // The product object
      type: Object,
      default: null,
    },
    args: {
      type: String,
      default: '',
    },
    location: {
      // The button location, used for analytics.
      type: String,
      default: 'ukendt',
    },
  },

  data: function() {
    return {
      amount: 1,
      settings,
    };
  },

  computed: {
    ...mapGetters({
      getQuantityByItemNumber: 'xal/cart/getQuantityByItemNumber',
      getQuantityByItemId: 'xal/cart/getQuantityByItemId',
      getSearchedLicensePlate: 'cardata/getSearchedLicensePlate',
      getSearchedVehicleId: 'cardata/getSearchedVehicleId',
    }),

    quantity() {
      if (!settings.xalmode) {
        return 0;
      }

      if (this.productObj.item_id) {
        return this.getQuantityByItemId(this.productObj.item_id);
      } else {
        return this.getQuantityByItemNumber(this.productObj.item_number);
      }
    },

    getBuyArgs() {
      if (this.args) {
        return this.args;
      }

      return JSON.stringify({
        c: String(window.globalvar_c),
        pn: String(this.product.node_id),
        vn: String(this.product.item_number),
        btnLocation: this.location,
        vname: this.product.title,
      });
    },

    productObj() {
      if (this.product) {
        return this.product;
      } else if (this.args) {
        const product = JSON.parse(this.args);
        return {
          item_id: product.item_id,
          is_car_specific: product.is_car_specific,
          pnodeid: product.pn,
          item_number: product.vn,
          location: product.btnLocation,
          title: product.vname,
        };
      }

      return null;
    },
  },

  methods: {
    ...mapActions({
      addLineXAL: 'xal/cart/addLine',
      removeLineXAL: 'xal/cart/removeLine',
    }),

    addToCart() {
      // This need a refactoring so that we don't send the entire product object...
      this.$emit('addToCart', { product: this.product, quantity: this.amount });

      if (this.settings.xalmode) {
        const line = {
          title: this.productObj.title,
          item_number: this.productObj.item_number,
          quantity: this.amount,
        };
        if (this.productObj.item_id) {
          line.item_id = this.productObj.item_id;
        }
        if (this.productObj.is_car_specific) {
          line.license_plate = this.getSearchedLicensePlate;
          line.vehicle_id = this.getSearchedVehicleId;
        }

        this.addLineXAL(line);
      }
    },

    removeFromCart() {
      // This need a refactoring so that we don't send the entire product object...
      this.$emit('removeCart', this.productObj);

      if (this.settings.xalmode) {
        this.removeLineXAL({
          item_id: this.productObj.item_id,
          item_number: this.productObj.item_number,
        });
      }
    },
  },
};
</script>
