<template>
  <li class="FeatureItem">
    <div class="FeatureItem__icon">
      <i class="far fa-check" />
    </div>
    <div class="FeatureItem__content">
      <p class="FeatureItem__title">{{ title }}</p>
      <p class="FeatureItem__desciption">
        <slot name="default" />
      </p>
    </div>
  </li>
</template>

<script>
export default {
  name: 'FeatureItem',

  props: {
    title: {
      type: String,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.FeatureItem {
  display: flex;
  gap: 14px;
  align-items: center;

  .FeatureItem__title {
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 0;
  }

  .FeatureItem__desciption {
    font-size: 14px;
    margin-bottom: 0;
  }

  .FeatureItem__icon {
    font-size: 23px;
  }
}
</style>
