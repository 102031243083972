export default {
  name: 'Validator',
  props: {
    errorVisible: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      items: [],
    };
  },
  computed: {
    valid() {
      return this.items.every(item => item.valid);
    },
  },
  watch: {
    valid(to, from) {
      if (this.validator) {
        this.validator.updateItem(this._uid, to);
      }
      this.$emit('update:errorVisible', false); // hides error then valid changes.
    },
  },
  methods: {
    validate() {
      this.items.forEach(item => item.validate());
    },
    /**
     * Add an validation item to the validation list.
     * @param {String} id Unique ID for the validation item. Usually the _uid when working with VUE components.
     * @param {Function} validate The validate function must return a boolean.
     */
    addItem(id, valid, showError, showErrors) {
      if (id !== undefined && valid !== undefined) {
        this.items.push({
          id,
          valid,
          showError,
          showErrors,
        });
      }
      // Else log error?
    },
    /**
     * Updates the valid state on an validation item.
     * @param {String} id Unique ID for the validation item.
     */
    updateItem(id, valid) {
      const item = this.items.find(item => item.id === id);
      if (item) item.valid = valid;
    },
    /**
     * Remove an validation item to the validation list.
     * @param {String} id Unique ID for the validation item.
     */
    removeItem(id) {
      const itemIndex = this.items.findIndex(item => {
        return item.id === id;
      });
      if (itemIndex !== -1) {
        this.items.splice(itemIndex, 1);
      }
    },
    showError() {
      const errorItem = this.items.find(item => !item.valid);
      if (errorItem !== undefined) {
        errorItem.showError(true);
      }
      this.$emit('update:errorVisible', true);
    },
    showErrors() {
      this.items.forEach(item => {
        if (typeof item.showErrors === 'function') item.showErrors(true);
        else if (typeof item.showError === 'function') item.showError(true);
      });
      this.$emit('update:errorVisible', true);
    },
  },
  created() {
    if (this.validator !== undefined) {
      this.validator.addItem(this._uid, this.valid, this.showError, this.showErrors);
    }
  },
  beforeDestroy() {
    if (this.validator !== undefined) {
      this.validator.removeItem(this._uid);
    }
  },
  render() {
    return this.$scopedSlots.default({
      valid: this.valid,
      validate: this.validate,
      addItem: this.addItem,
      removeItem: this.removeItem,
      updateItem: this.updateItem,
      showErrors: this.showErrors,
      showError: this.showError,
      errorVisible: this.errorVisible,
    });
  },
  provide() {
    return {
      validator: {
        valid: this.valid,
        validate: this.validate,
        addItem: this.addItem,
        removeItem: this.removeItem,
        updateItem: this.updateItem,
        showErrors: this.showErrors,
        showError: this.showError,
        errorVisible: this.errorVisible,
      },
    };
  },
  inject: {
    validator: {
      default() {
        return;
      }, // having a default if no validator parent is avaliable
    },
  },
};
