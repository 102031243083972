import AccountModule, { Actions, Getters } from '@store/modules/account';
import {
  getUser,
  getUsers,
  getUserSettings,
  postUser,
  patchUser,
  deleteUser,
  resetUserPassword,
} from '@services/thansen/account';
import { mapState, mapActions, mapGetters } from 'vuex';
import Progress from '@types/Progress';

export default {
  name: 'AccountContainer',

  data() {
    return {
      usersStatus: '',
      users: [],
      settingsStatus: '',
      settings: [],
    };
  },

  computed: {
    ...mapState('account', [
      'isLoggedIn',
      'hasFetchedAccount',
      'accountStatus',
      'account',
      'accountUpdatingStatus',
      'profileStatus',
      'profile',
      'profileUpdatingStatus',
      'overview',
    ]),
    ...mapGetters('account', {
      type: Getters.GET_ACCOUNT_TYPE,
      isDealer: Getters.IS_DEALER,
      isPrivate: Getters.IS_PRIVATE,
      isVendor: Getters.IS_VENDOR,
      branch: Getters.GET_BRANCH,
      fullName: Getters.FULL_NAME,
      hasEmail: Getters.HAS_EMAIL,
    }),
    activeOrders() {
      return this.overview.activeOrders;
    },
    pendingOn() {
      return keys => {
        const _keys = typeof keys === 'string' ? [keys] : keys;
        return this.account?.pending_updates.some(key => _keys.includes(key));
      };
    },
  },

  watch: {
    account(to, from) {
      if (to !== null && from === null && this.doFetchProfile) {
        this.fetchProfileImmediate();
      }
    },
  },

  created() {
    this.doFetchProfile = false;
    if (this.isLoggedIn && !this.hasFetchedAccount) this.fetchAccount();
  },

  methods: {
    ...mapActions('account', {
      fetchAccount: Actions.FETCH_ACCOUNT,
      updateAccount: Actions.UPDATE_ACCOUNT,
      deleteAccount: Actions.DELETE_ACCOUNT,
      fetchProfileImmediate: Actions.FETCH_PROFILE,
      updateProfile: Actions.UPDATE_PROFILE,
      fetchOverview: Actions.FETCH_OVERVIEW,
      updateBranch: Actions.UPDATE_BRANCH,
      deleteBranch: Actions.DELETE_BRANCH,
      setPrimaryEmail: Actions.SET_PRIMARY_EMAIL,
      deleteEmail: Actions.DELETE_EMAIL,
      addEmail: Actions.ADD_EMAIL,
      editEmail: Actions.EDIT_EMAIL,
    }),
    fetchProfile() {
      if (this.account) this.fetchProfileImmediate();
      else this.doFetchProfile = true;
    },
    fetchUser(id) {
      return new Promise((resolve, reject) => {
        getUser(id)
          .then(data => {
            resolve(data);
          })
          .catch(error => {
            reject(error);
          });
      });
    },
    fetchUsers() {
      this.usersStatus = Progress.WORKING;
      getUsers()
        .then(data => {
          this.users = data;
          this.usersStatus = Progress.COMPLETE;
        })
        .catch(error => {
          this.usersStatus = Progress.ERROR;
        });
    },
    fetchUserSettings() {
      if (this.settingsStatus === Progress.WORKING || this.settingsStatus === Progress.COMPLETE) {
        return;
      }
      this.settingsStatus = Progress.WORKING;
      getUserSettings()
        .then(data => {
          this.settings = data;
          this.settingsStatus = Progress.COMPLETE;
        })
        .catch(error => {
          this.settingsStatus = Progress.ERROR;
        });
    },
    createUser(user) {
      return new Promise((resolve, reject) => {
        postUser(user)
          .then(data => {
            this.fetchUsers();
            resolve(data);
          })
          .catch(error => {
            reject(error);
          });
      });
    },
    updateUser(id, user) {
      return new Promise((resolve, reject) => {
        patchUser(id, user)
          .then(data => {
            this.fetchUsers();
            resolve(data);
          })
          .catch(error => {
            reject(error);
          });
      });
    },
    deleteUser(id) {
      return new Promise((resolve, reject) => {
        deleteUser(id)
          .then(data => {
            this.fetchUsers();
            resolve(data);
          })
          .catch(error => {
            reject(error);
          });
      });
    },
    resetUserPassword(email, accountId, username) {
      return new Promise((resolve, reject) => {
        resetUserPassword(email, accountId, username)
          .then(data => {
            resolve(data);
          })
          .catch(error => {
            reject(error);
          });
      });
    },
  },

  render() {
    return this.$scopedSlots.default({
      account: this.account,
      branch: this.branch,
      usersStatus: this.usersStatus,
      users: this.users,
      settingsStatus: this.settingsStatus,
      settings: this.settings,
    });
  },

  provide() {
    return {
      accountContainer: this,
    };
  },
};
