<template>
  <Validator v-slot="{}" ref="validator">
    <div class="NewPasswordInputs">
      <Input
        id="new-password"
        type="password"
        name="new-password"
        :label="passwordLabel || $t('account.auth.new_password')"
        :autocomplete="Autocompletes.PASSWORD_NEW"
        :autoFocus="autoFocus"
        required
        :minlength="minlength"
        v-model="password"
      />
      <Input
        id="confirm-password"
        type="password"
        name="confirm-password"
        :label="confirmPasswordLabel || $t('account.auth.confirm_new_password')"
        :autocomplete="Autocompletes.PASSWORD_NEW"
        required
        :minlength="minlength"
        class="NewPasswordInputs__confirmPassword"
        v-model="confirmPassword"
      />
      <Collapse>
        <div v-if="showNotMatching">
          <Alert :level="AlertLevel.WARNING" hideIcon class="NewPasswordInputs__alert">
            {{ $t('account.auth.message.match_error') }}
          </Alert>
        </div>
      </Collapse>
    </div>
  </Validator>
</template>

<script>
import Validator from '@containers/Validator';
import Collapse from '@transitions/Collapse';
import Alert, { AlertLevel } from '@components/Alert';
import Input, { Autocompletes } from '@scenes/SelfserviceScene/components/Input';

export default {
  name: 'NewPasswordInputs',

  components: {
    Validator,
    Collapse,
    Alert,
    Input,
  },

  enums: {
    AlertLevel,
    Autocompletes,
  },

  props: {
    passwordLabel: {
      type: String,
      default: '',
    },
    confirmPasswordLabel: {
      type: String,
      default: '',
    },
    autoFocus: {
      type: Boolean,
      default: false,
    },
    minlength: {
      type: Number,
      default: 6,
    },
  },

  data() {
    return {
      password: '',
      confirmPassword: '',
      showNotMatching: false,
    };
  },

  computed: {
    valid() {
      return this.password === this.confirmPassword;
    },
  },

  watch: {
    password() {
      this.showNotMatching = false;
      this.$emit('input', this.password);
    },
    confirmPassword() {
      this.showNotMatching = false;
    },
    valid(to) {
      this.$refs.validator.updateItem('newPasswordInputs', to);
    },
  },

  mounted() {
    this.$refs.validator.addItem('newPasswordInputs', false, () => {
      this.showNotMatching = !this.valid;
    });
  },

  beforeDestroy() {
    this.$refs.validator.removeItem('newPasswordInputs');
  },

  methods: {
    clear() {
      this.password = '';
      this.confirmPassword = '';
    },
  },
};
</script>

<style lang="scss" scoped>
.NewPasswordInputs {
  display: flex;
  flex-direction: column;

  .NewPasswordInputs__confirmPassword,
  .NewPasswordInputs__alert {
    margin-top: 1em;
  }
}
</style>
