<template>
  <transition name="fade">
    <div v-show="active" class="Slide">
      <slot name="default" />
    </div>
  </transition>
</template>

<script>
export default {
  name: 'Slide',

  props: {
    id: {
      type: String,
      required: true,
    },
  },

  computed: {
    active() {
      return this.slides.currentSlideId === this.id;
    },
  },

  inject: {
    slides: {
      default() {
        console.error('Missing Slides');
        return;
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.fade-enter-active {
  transition: opacity 0.8s;
}

.fade-leave-active {
  transition: opacity 0.6s;
  position: absolute;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>
