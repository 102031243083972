<template>
  <div :class="['NavigationDrawer', { 'is-open': isOpen }]">
    <div class="NavigationDrawer__drawer">
      <div class="NavigationDrawer__content">
        <component :is="currentContent"></component>
      </div>
      <button class="NavigationDrawer__control" @click="close">{{ $t('close') }}</button>
    </div>
    <div class="NavigationDrawer__backdrop" @click="close"></div>
  </div>
</template>

<script>
import AutopartsGroups from './components/AutopartGroups';

export default {
  name: 'NavigationDrawer',

  components: {
    AutopartsGroups,
  },

  data() {
    return {
      isOpen: false,
      currentContent: '',
    };
  },

  mounted() {
    const openNavLinks = document.querySelector('.vue-open-nav');
    if (openNavLinks) {
      openNavLinks.addEventListener('click', e => {
        this.isOpen = true;
        this.currentContent = 'AutopartsGroups';
      });
    }
  },

  methods: {
    close() {
      this.isOpen = false;
    },
  },
};
</script>
