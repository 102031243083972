<template>
  <Validator v-slot="{ valid }">
    <Form
      :title="$t('account.auth.forgot_password')"
      :locked="working"
      @submit="requestPasswordChange"
    >
      <template #header>
        <p>{{ $t('account.auth.message.forgot_password_instructions') }}</p>
        <Alert v-if="error" :level="AlertLevel.ERROR" closeable>
          {{ $t('error_messages.try_again') }}
        </Alert>
      </template>
      <template #default>
        <Input
          id="requestEmail"
          v-model="email"
          name="requestEmail"
          type="email"
          :label="$t('email')"
          autocomplete="username"
          autoFocus
          required
          validateOnBlur
          :disabled="working"
          class="LoginCard__input"
        />
      </template>
      <template #footer>
        <Button :size="ButtonSizes.FULL" :disabled="!valid" :working="working">
          {{ $t('account.auth.reset_password') }}
        </Button>
      </template>
    </Form>
  </Validator>
</template>

<script>
import Validator from '@containers/Validator';
import Form from '@layouts/Form';
import Alert, { AlertLevel } from '@components/Alert';
import { Button, sizes as ButtonSizes } from '@components/Buttons';
import Input from '@scenes/SelfserviceScene/components/Input';

export default {
  name: 'RequestPasswordResetForm',

  components: {
    Validator,
    Form,
    Alert,
    Button,
    Input,
  },

  enums: {
    AlertLevel,
    ButtonSizes,
  },

  data() {
    return {
      email: '',
      password: '',
      error: false,
      working: false,
    };
  },

  created() {
    this.authenticationContainer.clearStatus();
  },

  methods: {
    requestPasswordChange() {
      this.working = true;
      this.authenticationContainer
        .requestPasswordChange(this.email)
        .then(() => {
          this.error = true;
          this.$emit('submitted');
        })
        .catch(() => {
          this.working = false;
          this.error = true;
        });
    },
  },

  inject: {
    authenticationContainer: {
      default() {
        console.error('Missing AuthenticationContainer');
        return;
      },
    },
  },
};
</script>
