export const sizes = {
  LARGE: 'large',
  SMALL: 'small',
  MEDIUM2: 'medium2',
  EXTRA_SMALL: 'xsmall',
  FULL: 'full',
};

export const colors = {
  DEFAULT: '',
  SYSTEM: 'system',
  BRAND: 'brand',
  BRAND2: 'lightBrand',
  RED: 'red',
  WHITE: 'light',
};

export const icons = {
  ADD: 'far fa-plus',
  PEN: 'far fa-pen',
  REMOVE: 'far fa-times',
  TRASH: 'far fa-trash-alt',
  SEND: 'fal fa-paper-plane',
  PRINT: 'fal fa-print',
  DOWNLOAD: 'fas fa-arrow-alt-to-bottom',
  RETURN: 'far fa-undo',
  ADD_CART: 'far fa-cart-plus',
  LINK: 'far fa-external-link',
};

export const countPosition = {
  TOP_LEFT: 'top_left',
  INLINE: 'inline',
};
