<template>
  <div class="BranchCardLargeEmpty">
    <div class="BranchCardLargeEmpty__imageSection"></div>
    <div class="BranchCardLargeEmpty__content">
      <Button :color="colors.WHITE" @click="$emit('click')">
        <slot />
      </Button>
    </div>
  </div>
</template>

<script>
import { Button, sizes, colors } from '@components/Buttons';

export default {
  name: 'BranchCardLargeEmpty',

  components: {
    Button,
  },

  enums: {
    sizes,
    colors,
  },
};
</script>

<style lang="scss" scoped>
.BranchCardLargeEmpty {
  display: flex;
  flex-direction: column;

  min-width: 180px;
  max-width: 280px;
  width: 100%;
  text-decoration: none;
  border-radius: $border-radius;
  overflow: hidden;

  .BranchCardLargeEmpty__imageSection {
    width: 280px;
    height: 180px;
    background: $color-prim-grey;
  }

  .BranchCardLargeEmpty__content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    @include spacing-2('padding');
    border-left: 3px dashed;
    border-right: 3px dashed;
    border-bottom: 3px dashed;
    color: $color-prim-grey;
    height: 180px;
  }

  .BranchCardLargeEmpty__sectionContainer {
    display: flex;
    flex-direction: column;
  }
}
</style>
