<template>
  <div class="InputSkeleton">
    <SkeletonGraphic :width="80" :height="21" class="InputSkeleton__label" />
    <SkeletonGraphic width="100%" :height="44" :border-radius="3" />
  </div>
</template>

<script>
import SkeletonGraphic from '../SkeletonGraphic';

export default {
  name: 'InputSkeleton',

  components: {
    SkeletonGraphic,
  },
};
</script>

<style lang="scss" scoped>
.InputSkeleton {
  display: flex;
  flex-direction: column;

  .InputSkeleton__label {
    margin-bottom: 7px;
  }
}
</style>
