<template>
  <Validator :errorVisible="authenticationContainer.loginStatus === Progress.ERROR">
    <div class="LoginCard">
      <!--<Icon :status="statusLevel" :icon="iconType" />-->
      <Alert v-if="urlParm === URL_PARM.NOT_LOGGED_IN" :level="AlertLevel.INFO" closeable>
        {{ $t('account.auth.message.not_logged_in') }}
      </Alert>

      <Alert v-if="urlParm === URL_PARM.EMAIL_SEND" :level="AlertLevel.INFO" closeable>
        {{ $t('account.auth.message.password_requested') }}
      </Alert>

      <Alert v-if="urlParm === URL_PARM.ACCOUNT_CREATED" :level="AlertLevel.INFO" closeable>
        {{ $t('account.auth.message.account_created') }}
      </Alert>

      <Alert v-if="urlParm === URL_PARM.CHANGED" :level="AlertLevel.INFO" closeable>
        {{ $t('account.auth.message.password_changed') }}
      </Alert>

      <Alert v-if="urlParm === URL_PARM.ERROR" :level="AlertLevel.ERROR" closeable>
        {{ $t('account.auth.message.invalid_login') }}
      </Alert>

      <Alert v-if="urlParm === URL_PARM.EXPIRED_TOKEN" :level="AlertLevel.ERROR" closeable>
        {{ $t('account.auth.message.expired_token') }}
      </Alert>

      <Alert v-if="urlParm === URL_PARM.INVALID_TOKEN" :level="AlertLevel.ERROR" closeable>
        {{ $t('account.auth.message.invalid_token') }}
      </Alert>

      <!-- <FadeTransition :duration="0.35" :delay="0" transform-height> -->
      <LoginForm
        v-if="local.mode === Modes.LOGIN"
        :doReload="doReload"
        @clickOnForgotPassword="changeMode(Modes.REQUEST_PASSWORD_CHANGE)"
      />
      <SignupForm
        v-else-if="local.mode === Modes.SIGNUP"
        @submitted="changeMode(Modes.LOGIN), setUrlParm(URL_PARM.ACCOUNT_CREATED)"
      />
      <CompleteSignupForm v-else-if="local.mode === Modes.COMPLETE_SIGNUP" />
      <RequestPasswordResetForm
        v-else-if="local.mode === Modes.REQUEST_PASSWORD_CHANGE"
        @submitted="changeMode(Modes.LOGIN), setUrlParm(URL_PARM.EMAIL_SEND)"
      />
      <ResetPasswordForm v-else-if="local.mode === Modes.PASSWORD_CHANGE" />
      <!-- </FadeTransition> -->
    </div>
  </Validator>
</template>

<script>
import Progress from '@types/Progress';
import Validator from '@containers/Validator';
import Icon, { Icons } from '../Icon';
import Alert, { AlertLevel } from '@components/Alert';
import FadeTransition from '@transitions/Fade';
import LoginForm from '../LoginForm';
import SignupForm from '../SignupForm';
import CompleteSignupForm from '../CompleteSignupForm';
import RequestPasswordResetForm from '../RequestPasswordResetForm';
import ResetPasswordForm from '../ResetPasswordForm';

export const Modes = {
  LOGIN: 'LOGIN',
  SIGNUP: 'SIGNUP',
  COMPLETE_SIGNUP: 'COMPLETE_SIGNUP',
  REQUEST_PASSWORD_CHANGE: 'REQUEST_PASSWORD_CHANGE',
  PASSWORD_CHANGE: 'PASSWORD_CHANGE',
};

const StatusLevel = {
  IDLE: 'IDLE',
  COMPLETE: 'COMPLETE',
  ERROR: 'ERROR',
};

const URL_PARM = {
  CHANGED: 'changed',
  EMAIL_SEND: 'notified',
  ERROR: 'error',
  ACCOUNT_CREATED: 'account_created',
  NOT_LOGGED_IN: 'not_logged_in',
  EXPIRED_TOKEN: 'expired_token',
  INVALID_TOKEN: 'invalid_token',
};

export default {
  name: 'Login',

  components: {
    Validator,
    Icon,
    Alert,
    AlertLevel,
    FadeTransition,
    LoginForm,
    SignupForm,
    CompleteSignupForm,
    RequestPasswordResetForm,
    ResetPasswordForm,
  },

  enums: {
    Progress,
    Modes,
    URL_PARM,
    Icons,
    AlertLevel,
  },

  props: {
    mode: {
      type: String,
      default: Modes.LOGIN,
    },
    doReload: {
      type: Boolean,
      default: true,
    },
    requireAcceptTerms: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      email: '',
      password: '',
      local: { mode: this.mode },
      urlParm: '',
      showError: false,
    };
  },

  computed: {
    status() {
      switch (this.local.mode) {
        case Modes.LOGIN:
          return this.authenticationContainer.loginStatus;
        case Modes.REQUEST_PASSWORD_CHANGE:
          return this.authenticationContainer.requestPasswordChangeStatus;
        case Modes.PASSWORD_CHANGE:
          return this.authenticationContainer.changePasswordStatus;
        default:
          return '';
      }
    },
    statusLevel() {
      if (this.status === Progress.COMPLETE) return StatusLevel.COMPLETE;
      if (this.status === Progress.WORKING) return StatusLevel.IDLE;
      if (this.status === Progress.ERROR) return StatusLevel.ERROR;
      return StatusLevel.IDLE;
    },
    iconType() {
      if (this.status === Progress.COMPLETE) return Icons.CHECK;
      if (this.status === Progress.WORKING) return Icons.SPINNER;
      if (this.status === Progress.ERROR) return Icons.TIMES;
      return Icons.DEFAULT;
    },
  },

  watch: {
    mode(to, from) {
      if (to !== from) this.local.mode = to;
    },
  },

  created() {
    this.urlParm = this.$route.query.flash;
  },

  methods: {
    setUrlParm(urlParm) {
      this.urlParm = urlParm;
    },
    changeMode(mode) {
      this.loginScene.changeMode(mode);
    },
  },

  inject: {
    authenticationContainer: {
      default() {
        console.error('Missing AuthenticationContainer');
        return;
      },
    },
    loginScene: {
      default() {
        return;
      },
    },
  },
};
</script>

<style lang="scss">
.LoginCard {
  background-color: #ffffff;
  padding: 50px 50px 30px 50px;
  position: relative;
  width: 100%;

  @include maxBreakpoint($laptopS-numeric) {
    padding: 40px 40px 30px 40px;
  }

  @include maxBreakpoint($mobileL-numeric) {
    padding: 30px 20px 30px 20px;
  }

  .LoginCard__input {
    text-align: left;
  }

  .controls {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .request-password-change,
    .cancel {
      font-style: italic;
      a {
        color: #999999;
      }
    }
  }

  .Alert {
    margin-block: 10px;
  }
}
</style>
