import StoreModule, { Getters, Actions } from './vuex-module';
import { mapGetters, mapActions } from 'vuex';

let store = null;
let namespace = 'feature-toggle';
const storeErrorMessage = 'Please initialise feature-toggle plugin with a Vuex store.';

function storePath(name) {
  return `${namespace}/${name}`;
}

/*
 TODO:
 - default redirect route? 
*/

export default {
  install(Vue, options) {
    if (!options || !options.store) {
      throw new Error(storeErrorMessage);
    }
    if (options.namespace !== undefined) {
      namespace = options.namespace;
    }
    store = options.store;
    store.registerModule(namespace, StoreModule);

    if (options.features) {
      store.dispatch(storePath(Actions.ADD_FEATURES), options.features);
    }

    Vue.mixin({
      computed: {
        ...mapGetters(namespace, {
          $isEnabled: Getters.IS_ENABLED,
        }),
      },
      beforeRouteEnter(to, from, next) {
        if (to.meta.featureRequired) {
          const { key, redirect = '/', not = false } = to.meta.featureRequired;
          if (not !== !store.getters[storePath(Getters.IS_ENABLED)](key)) {
            //return next(redirect); // Disabled to fix bug with account type not ready
          }
        }
        return next();
      },
    });
  },
};

export function addFeatures(features) {
  if (!store) {
    throw new Error(storeErrorMessage);
  }
  store.dispatch(storePath(Actions.ADD_FEATURES), features);
}
export function removeFeatures(features) {
  if (!store) {
    throw new Error(storeErrorMessage);
  }
  store.dispatch(storePath(Actions.REMOVE_FEATURES), features);
}
