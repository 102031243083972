<template>
  <div
    :class="[
      'Step',
      `Step--${alignment}`,
      active && 'Step--active',
      complete && 'Step--complete',
      touched && 'Step--touched',
      lastTouched && 'Step--lastTouched',
      isClickable && 'Step--clickable',
    ]"
    :key="id"
  >
    <div class="Step__bar">
      <div class="Step__iconFrame" @click="stepClick">
        <p v-if="showNumber" class="Step__number">{{ stepIndex + 1 }}</p>
        <i v-else :class="['Step__icon', icon]" />
      </div>
    </div>
    <div class="Step__content">
      <div class="Step__label">
        <span v-if="kicker" class="Step__kicker" @click="stepClick">{{ kicker }}</span>
        <span v-if="title" class="Step__title" @click="stepClick">{{ title }}</span>
        <span v-if="subtitle" class="Step__subtitle" @click="stepClick">{{ subtitle }}</span>
      </div>
      <slot name="default" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'Step',

  props: {
    id: {
      type: String,
      required: true,
    },
    icon: {
      type: String,
      default: 'far fa-check',
    },
    kicker: {
      type: String,
      default: '',
    },
    title: {
      type: String,
      default: '',
    },
    subtitle: {
      type: String,
      default: '',
    },
    clickable: {
      type: Boolean,
      default: undefined,
    },
  },

  data() {
    return {
      stepIndex: -1,
    };
  },

  computed: {
    alignment() {
      return this.stepProgressBar.alignment;
    },
    showNumber() {
      return this.stepProgressBar.numbered && !this.complete;
    },
    active() {
      return this.stepProgressBar.activeStep === this.id;
    },
    complete() {
      return this.stepProgressBar.completeSteps?.includes(this.id) ?? this.defaultComplete;
    },
    defaultComplete() {
      return this.stepProgressBar.activeIsComplete
        ? this.touched
        : this.touched && !this.lastTouched;
    },
    touched() {
      return this.stepProgressBar.indexOfLastTouched >= this.stepIndex;
    },
    lastTouched() {
      return this.stepProgressBar.indexOfLastTouched === this.stepIndex;
    },
    isClickable() {
      return this.clickable ?? this.stepProgressBar.clickable;
    },
  },

  methods: {
    stepClick() {
      if (this.isClickable && !getSelection().toString())
        this.stepProgressBar.emitStepClick(this.id);
    },
  },

  mounted() {
    this.stepProgressBar.update();
  },

  destroyed() {
    this.stepProgressBar.update();
  },

  inject: {
    stepProgressBar: {
      default() {
        console.error('Missing StepProgressBar');
        return;
      },
    },
  },
};
</script>

<style lang="scss" scoped>
$step-icon-diameter: 2em;
$step-icon-diameter-small: 1.5em;
$step-border-width: 0.2em;
$step-border-color-neutral: #d8d8d8;

.Step {
  flex-basis: $step-icon-diameter;
  flex-grow: 2;
}

.Step__bar {
  display: flex;
}
.Step__bar::before,
.Step__bar::after {
  content: '';
  height: $step-border-width;
  flex: 1;
  margin: auto;
  background-color: $step-border-color-neutral;
}
.Step:first-child .Step__bar::before,
.Step:last-child .Step__bar::after {
  visibility: hidden;
}

.Step__iconFrame {
  width: $step-icon-diameter;
  height: $step-icon-diameter;
  border-radius: 50%;
  background-color: $color-white;
  color: $color-white;
  border: $step-border-width solid $step-border-color-neutral;
  display: flex;
}

.Step__number {
  margin: auto;
  font-size: 1em;
  font-weight: 500;
  color: $step-border-color-neutral;
  user-select: none;
}

.Step__icon {
  margin: auto;
  font-size: 1em;
}

.Step__content {
  margin: 0.5em 0;
  color: $color-extra-trace;
}

.Step__label {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 0.5em;
  gap: 0.2em;
}

.Step__title {
  font-size: 1em;
  line-height: 1em;
}
.Step__kicker,
.Step__subtitle {
  font-size: 0.8em;
  line-height: 0.8em;
}

// Rules for vertical alignment of step
.Step--vertical {
  display: flex;
}
.Step--vertical .Step__bar {
  flex-direction: column;
}
.Step--vertical .Step__bar::after {
  height: unset;
  width: $step-border-width;
}
.Step--vertical:not(:last-child) .Step__bar::after {
  min-height: 0.7em;
}
.Step--vertical .Step__iconFrame {
  width: $step-icon-diameter-small;
  height: $step-icon-diameter-small;
}
.Step--vertical .Step__icon {
  font-size: 0.9em;
}
.Step--vertical .Step__content {
  margin: 0 0 0.5em 0.5em;
  flex: 1;
}
.Step--vertical .Step__label {
  min-height: $step-icon-diameter-small;
  margin-bottom: 0.2em;
}

// Text alignment for .Step__content in various alignments
.Step--justify:first-child .Step__content {
  text-align: left;
}

.Step--center .Step__content,
.Step--justify .Step__content {
  text-align: center;
}

.Step--right .Step__content,
.Step--justify:last-child .Step__content {
  text-align: right;
}

// Text alignment for .Step__label in various alignments
.Step--justify:first-child .Step__label {
  align-items: flex-start;
}

.Step--center .Step__label,
.Step--justify .Step__label {
  align-items: center;
}

.Step--right .Step__label,
.Step--justify:last-child .Step__label {
  align-items: flex-end;
}

// Margin and border radius for lines in various alignments
.Step--left .Step__bar::after,
.Step--right .Step__bar::before {
  border-radius: $step-border-width;
  margin: auto 1em;
}
.Step--vertical .Step__bar::after {
  border-radius: $step-border-width;
  margin: 0.2em auto;
}
.Step--center .Step__bar::before,
.Step--justify .Step__bar::before {
  border-top-right-radius: $step-border-width;
  border-bottom-right-radius: $step-border-width;
  margin-right: 1em;
}
.Step--center .Step__bar::after,
.Step--justify .Step__bar::after {
  border-top-left-radius: $step-border-width;
  border-bottom-left-radius: $step-border-width;
  margin-left: 1em;
}

// Changes step size of first and last step in justify alignment
.Step--justify:first-child,
.Step--justify:last-child {
  flex-grow: 1;
}

// Hides lines in various aligments
.Step--left .Step__bar::before,
.Step--justify:first-child .Step__bar::before,
.Step--vertical .Step__bar::before,
.Step--right .Step__bar::after,
.Step--justify:last-child .Step__bar::after {
  content: none;
}

// Coloring in various stages
.Step--touched .Step__content {
  color: $color-prim-black;
}
.Step--touched .Step__iconFrame {
  border-color: $color-cta;
}
.Step--touched .Step__number {
  color: $color-prim-black;
}
.Step--touched .Step__bar::before,
.Step--touched .Step__bar::after {
  background-color: $color-cta;
}
.Step--lastTouched .Step__bar::after {
  background-color: $step-border-color-neutral;
}
.Step--active .Step__iconFrame {
  border-color: $color-prim-blue;
}
.Step--active .Step__label {
  font-weight: bold;
}
.Step--complete .Step__iconFrame {
  // border-color: $color-cta;
  background-color: $color-cta;
}

// Pointer curser if clickable
.Step--clickable .Step__iconFrame,
.Step--clickable .Step__kicker,
.Step--clickable .Step__title,
.Step--clickable .Step__subtitle {
  cursor: pointer;
}
</style>
