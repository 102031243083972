<template>
  <div
    v-observe-visibility="visibilityChanged"
    :class="['ProductCard', { 'ProductCard--extended': settings.mode === 'b2b' }]"
  >
    <Labels
      :label="product.label"
      :popularity="product.popularity"
      :demo="product.is_demo"
      :news="product.is_new"
      :price="product.price"
      :compare-at-price="product.compare_at_price"
      :label-type="labelType"
      :is-on-sale="isOnSale"
      :is-clearance="isClearance"
      :sale-percent="salePercent"
    />

    <div class="ProductCard__image">
      <a
        class="ProductCard__imageInner"
        :href="product.url"
        :title="product.title"
        @click="$emit('view', product)"
      >
        <ImageComponent ref="image" preload="NONE" :src="product.image" :alt="product.title" />
      </a>

      <div
        v-if="settings.mode === 'b2b' && productStatus && productStatus.priceCode"
        class="ProductCard__details"
      >
        {{ product.item_number }} <br />
        {{ productStatus.priceCode }}
      </div>
      <div v-else class="ProductCard__details">{{ product.item_number }}</div>
    </div>

    <a
      class="ProductCard__title"
      :href="product.url"
      :title="product.title"
      @click="$emit('view', product)"
    >
      {{ product.title }}</a
    >

    <div class="ProductCard__bottom">
      <ProductPrice
        :compare-at-price="product.compare_at_price"
        :product-price="product.price"
        :is-on-sale="isOnSale"
        :bundle-offers="bundleOffers"
        :product-id="product.node_id"
        :product-status="productStatus"
      />

      <Buy
        :product="product"
        :location="buttonLocation"
        :enable-amount="true"
        size="small"
        @addToCart="details => this.$emit('addToCart', details)"
      />

      <template v-if="productStatus">
        <div
          v-if="statusMarkup"
          class="ProductCard__status DeliveryStatus"
          v-html="statusMarkup"
        ></div>
        <div v-else-if="statusDetails" class="ProductCard__status DeliveryStatus">
          <a
            v-if="statusDetails.isInConcernStock"
            :href="statusUrl"
            class="InstockBtn"
            style="visibility:visible;"
          >
            <span class="stockstatus-icon"><i class="fa fa-check check-green"></i> </span>
            <span class="stockstatus-text"> På lager online</span>
          </a>
          <a
            v-else-if="statusDetails.isInDepartureStock"
            :href="statusUrl"
            class="InstockBtn"
            style="visibility:visible;"
          >
            <span class="stockstatus-icon"><i class="fa fa-check check-yellow"></i> </span>
            <span class="stockstatus-text"> Ikke på lager online</span>
          </a>
          <a v-else :href="statusUrl" class="InstockBtn" style="visibility:visible;">
            <span class="stockstatus-icon"><i class="fa fa-check check-yellow"></i> </span>
            <span class="stockstatus-text"> Se lagerstatus</span>
          </a>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import settings from '@settings';
import helpers from '@helpers';
import { mapGetters, mapActions } from 'vuex';

import { Buy } from '@components/Buttons';
import ImageComponent from '@components/ImageComponent';
import Price from '@components/Price';

import ProductPrice from './components/ProductPrice';
import Labels from './components/Labels';
import Features from '@types/Features';

export default {
  name: 'ProductCard',

  components: {
    Buy,
    Labels,
    ProductPrice,
    ImageComponent,

    // This is mounted here because they're being used in a functional child component
    Price,
  },

  props: {
    product: {
      type: Object,
      default() {
        return {};
      },
    },
    buttonLocation: {
      type: String,
      required: false,
      default: '',
    },
  },

  data() {
    return {
      checkProductStatusTimeout: undefined,
      isVisible: false,
      statusFetched: false,
      settings,
      isViewed: false,
    };
  },

  computed: {
    ...mapGetters({
      getProductStatus: 'products/getProductStatusByProductNode',
      getProductStatusStatus: 'products/getProductStatusStatus',
    }),
    labelType() {
      return helpers.product.labelType(this.product);
    },
    isClearance() {
      return (
        this.product.label && (this.product.label === 'OUTLET' || this.product.label === 'RESTSALG')
      );
    },
    bundleOffers() {
      return this.product.offers
        ? this.product.offers.filter(offer => offer['type'] === 'bundle')
        : [];
    },
    productStatus() {
      if (this.product && typeof this.product.node_id !== 'undefined') {
        return this.getProductStatus(this.product.node_id);
      }
      return null;
    },
    productStatusStatus() {
      if (this.product && typeof this.product.node_id !== 'undefined')
        return this.getProductStatusStatus(this.product.node_id);
      return '';
    },

    statusMarkup() {
      if (window.createStatusMarkup && this.productStatus && this.productStatus.stockInfoList) {
        return window.createStatusMarkup(JSON.parse(JSON.stringify(this.productStatus)));
      }
      return;
    },
    statusDetails() {
      //isInConcernStock: true
      //isInDepartureStock: true

      return {
        ...this.productStatus?.stockInfoList?.find(item => item.statusName === 'PickUp')
          ?.delivery_info,
      };
    },
    statusUrl() {
      if (this.product.node_id) return `/instockstatus.asp?pn=${this.product.node_id}`;
      return;
    },
    isOnSale() {
      return helpers.product.onSale(
        this.product.is_sale,
        this.product.price,
        this.product.compare_at_price,
      );
    },
    salePercent() {
      return Math.round((1 - this.product.price / this.product.compare_at_price) * 100);
    },
  },

  beforeDestroy() {
    /* Clear the timeout */
    clearTimeout(this.checkProductStatusTimeout);
  },

  methods: {
    ...mapActions({
      fetchProductStatus: 'products/fetchProductStatus',
    }),
    visibilityChanged(isVisible, entry) {
      this.isVisible = isVisible;
      if (!this.isViewed && isVisible) {
        this.isViewed = true;
        this.$tracking('productsImpression')(this.product, {
          itemListName: 'algolia',
          itemListId: 'algolia',
        });

        this.$refs.image.load();
      }
      if (this.$isEnabled(Features.PRODUCT_STATUS)) {
        this.checkFetchProductStatus();
      }
    },
    checkFetchProductStatus(timeout = 400) {
      /* Here we should minimise the amount of set value with a timeout */
      clearTimeout(this.checkProductStatusTimeout);

      if (this.isVisible) {
        /* Do the debounce */
        this.checkProductStatusTimeout = setTimeout(() => {
          this.fetchProductStatus(this.product.node_id); // Will check it the data is expired and then update
        }, timeout);
      }
    },
  },
};
</script>
