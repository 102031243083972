<template>
  <SkeletonGraphic :class="['ButtonSkeleton', 'ButtonSkeleton--' + size]" />
</template>

<script>
import SkeletonGraphic from '../SkeletonGraphic';
import { Sizes } from '../../index';

export default {
  name: 'ButtonSkeleton',

  components: {
    SkeletonGraphic,
  },

  props: {
    size: {
      type: String,
      default: Sizes.SMALL,
    },
  },
};
</script>

<style lang="scss" scoped>
.ButtonSkeleton {
  border-radius: 3px;

  &.ButtonSkeleton--small {
    width: 120px;
    height: 30px;
  }

  &.ButtonSkeleton--medium {
    width: 120px;
    height: 44.5px;
  }
}
</style>
