export const Mutation = {
  SET_FILTER_STATUS: 'SET_FILTER_STATUS 🚗',

  SET_FLASH_NOTIFICATION: 'SET_FLASH_NOTIFICATION 🚗',

  SET_FILTER: 'SET_FILTER 🚗',
  UPDATE_FILTER: 'UPDATE_FILTER 🚗',
  CLEAR_FILTER: 'CLEAR_FILTER 🚗',

  SET_SEARCH_STATUS: 'SET_SEARCH_STATUS 🚗',
  SET_SEARCH: 'SET_SEARCH 🚗',
  CLEAR_SEARCH: 'CLEAR_SEARCH 🚗',
};

export const Action = {
  EXAMPLE_MUTATION: 'TESTING',
};
