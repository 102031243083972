<template>
  <div class="SkeletonGraphic" :style="style" />
</template>

<script>
export default {
  name: 'SkeletonGraphic',

  props: {
    width: {
      type: [Number, String],
      default: null,
    },
    height: {
      type: [Number, String],
      default: null,
    },
    borderRadius: {
      type: [Number, String],
      default: null,
    },
  },

  computed: {
    style() {
      return {
        width: this.parseValue(this.width),
        height: this.parseValue(this.height),
        borderRadius: this.parseValue(this.borderRadius),
      };
    },
  },

  methods: {
    parseValue(value) {
      return typeof value === 'number' ? `${value}px` : value;
    },
  },
};
</script>

<style lang="scss" scoped>
.SkeletonGraphic {
  position: relative;
  background-color: #efefef;
  overflow: hidden;

  &::after {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    background: linear-gradient(90deg, transparent, rgba(255, 255, 255, 0.4), transparent);
    left: -100%;
    animation: loading 1.5s infinite;
  }
}

@keyframes loading {
  100% {
    left: 100%;
  }
}
</style>
