import { Api } from '../';

/**
 * Subscribe to the newsletter
 */
export function postSubscribers(email_address) {
  return new Promise((resolve, reject) => {
    Api.post('mailinglist/subscribers', { email_address })
      .then(response => {
        resolve(response.data.data);
      })
      .catch(error => {
        reject(error);
      });
  });
}
