/* global $s */
import Axios from 'axios';
import Cookies from 'js-cookie';
import settings from '@settings';
import { AxiosErrorHandler } from '@errorhandler';

const defaultHeaders = {
  Accept: 'application/json',
  'Content-Type': 'application/json',
  'X-Requested-With': 'XMLHttpRequest',
  c: Cookies.get('c') || window.globalvar_c, // '1108982712', // c cookie is a full domain (webdev.thansen.dk not working) specific cookie, so we need to add it as a header.
  cok: Cookies.get('cok') || window.globalvar_cok,
  'Ocp-Apim-Subscription-Key': settings.azureSubscription,
};

/** The primary generic thansen API (TESTING/DEVELOPMENT) */
const development = Axios.create({
  baseURL: 'http://v4.thansen.dk/api', // TODO: Change to production destination
  withCredentials: true, // Cookies are parsed along with the request
  responseType: 'json',
  headers: defaultHeaders,
});

/** The primary generic thansen API (PRODUCTION) */
const production = Axios.create({
  baseURL: 'https://xapi.thg.dk/', // TODO: Change to production destination
  withCredentials: true, // Cookies are parsed along with the request
  responseType: 'json',
  headers: defaultHeaders,
});
production.interceptors.response.use(response => response, AxiosErrorHandler);

/** XAL API */
const xal = Axios.create({
  baseURL: 'https://xalapi.thg.dk/', // TODO: Change to production destination
  withCredentials: true, // Cookies are parsed along with the request
  responseType: 'json',
  headers: {},
});
xal.interceptors.response.use(response => response, AxiosErrorHandler);

const legacy = Axios.create({
  baseURL: '/api/', // TODO: Change to production destination
  withCredentials: true, // Cookies are parsed along with the request
  responseType: 'json',
  headers: defaultHeaders,
});
legacy.interceptors.response.use(response => response, AxiosErrorHandler);

export const Api = production;
export const ApiTest = development;
export const XALApi = xal;
export const LegacyApi = legacy;

// https://github.com/axios/axios/issues/690

// https://xapi.thg.dk/brands
