<template>
  <div
    :class="['LoginScene', currentMode === Modes.SIGNUP && 'LoginScene--signup']"
    v-if="currentMode"
  >
    <div class="LoginScene__content">
      <Login
        class="LoginScene__form"
        :mode="currentMode"
        :doReload="doReload"
        :show-create-user-form="false"
      />
      <CreateUserBox
        :hideSignup="currentMode !== Modes.LOGIN"
        class="LoginScene__createUserBox"
        @clickOnSignup="changeMode(Modes.SIGNUP)"
      />
    </div>
  </div>
</template>

<script>
import { Modes } from '@components/Login';
import CreateUserBox from '@components/Login/components/CreateUserBox';
import Login from '@components/Login';

export { Modes };

export default {
  name: 'LoginScene',

  enums: {
    Modes,
  },

  components: {
    Login,
    CreateUserBox,
  },

  props: {
    mode: {
      type: String,
      default: undefined,
    },
    doReload: {
      type: Boolean,
      default: true,
    },
    routing: {
      type: Boolean,
      default: true,
    },
  },

  data() {
    return {
      local: { mode: this.mode },
    };
  },

  computed: {
    currentMode() {
      return this.routing ? this.$route.meta.mode : this.local.mode;
    },
  },

  methods: {
    changeMode(mode) {
      if (this.routing) {
        if (mode === Modes.LOGIN) this.routeTo('/login');
        else if (mode === Modes.SIGNUP) this.routeTo('/login/signup');
        else if (mode === Modes.REQUEST_PASSWORD_CHANGE) this.routeTo('/login/resetpassword');
        else if (mode === Modes.PASSWORD_CHANGE) this.routeTo('/login/password');
      } else {
        this.local.mode = mode;
      }
    },
    routeTo(path) {
      this.$router.push({ name: path });
    },
  },

  provide() {
    return {
      loginScene: this,
    };
  },
};
</script>

<style lang="scss" scoped>
.LoginScene {
  display: flex;
  text-align: left;

  .LoginScene__form {
    width: 460px;
  }
  .LoginScene__createUserBox {
    width: 460px;
    max-width: 420px;
  }
  .LoginScene__content {
    display: flex;
    flex-direction: row-reverse;
    justify-content: center;
    margin: 0 auto;
    border: 1px solid $color-prim-grey;
    border-radius: $border-radius;
    box-shadow: 0 1px 0 rgb(0 0 0 / 8%);
    margin-bottom: 20px;
    overflow: hidden;
    @include maxBreakpoint($tablet-numeric) {
      flex-direction: column;

      .LoginScene__form,
      .LoginScene__createUserBox {
        width: auto;
        max-width: 420px;
      }
    }
  }
  &--signup {
    .LoginScene__content {
      @include maxBreakpoint($tablet-numeric) {
        flex-direction: column-reverse;
      }
    }
  }
}
</style>
