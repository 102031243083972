<template>
  <div :class="['Details', `Details--${columnSize}`, flex && 'Details--flex']">
    <div v-for="i in items" :key="i" class="Details__item">
      <span class="Details__header">
        <SkeletonGraphic :width="70 + Math.random() * 50" :height="16" />
      </span>
      <span class="Details__label">
        <SkeletonGraphic :width="50 + Math.random() * 100" :height="16" />
      </span>
    </div>
  </div>
</template>

<script>
import SkeletonGraphic from '../SkeletonGraphic';

export const Sizes = {
  SMALL: 'small',
  MEDIUM: 'medium',
};

export default {
  name: 'DetailsSkeleton',

  components: {
    SkeletonGraphic,
  },

  props: {
    items: {
      type: Number,
      default: 5,
    },
    columnSize: {
      type: String,
      default: Sizes.MEDIUM,
    },
    flex: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss" scoped>
.Details {
  width: 100%;
  justify-content: space-between;

  &:not(.Details--flex) {
    justify-content: space-between;
    display: grid;
    grid-gap: 20px;
    &.Details--small {
      grid-template-columns: repeat(auto-fit, minmax(130px, 1fr));
    }
    &.Details--medium {
      grid-template-columns: repeat(auto-fit, minmax(160px, 1fr));
    }
  }

  &.Details--flex {
    display: flex;
    gap: 1em;
    justify-content: flex-start;
  }

  .Details__header {
    display: block;
    margin-bottom: 6px;
  }

  .Details__label {
    display: block;
  }
}
</style>
