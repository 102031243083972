import Vue from 'vue';
import Vuex from 'vuex';

import VuexPersistence from 'vuex-persist';

import cardata from './modules/cardata';
import tirefilter from './modules/wheels/tire-filter';
import products from './modules/products';
import xal from './modules/xal';
import cart from './modules/cart';
import account from './modules/account';

Vue.use(Vuex);

const debug = process.env.NODE_ENV !== 'production';

const vuexLocal = new VuexPersistence({
  storage: window.localStorage,
  reducer: state => ({
    cardata: state.cardata,
    products: {
      _version: state.products._version,
      productStatus: state.products.productStatus,
    },
  }),
  key: 'thg-0.2',
});

const modules = { cardata, tirefilter, products, xal, cart, account };

export default new Vuex.Store({
  modules,
  strict: debug,
  plugins: [vuexLocal.plugin],
  actions: {},
});
