<template>
  <div :class="`PanelItemSkeleton PanelItemSkeleton--${color}`">
    <div class="PanelItemSkeleton__content">
      <slot name="default" />
    </div>
    <div class="PanelItemSkeleton__controls">
      <slot name="controls" />
    </div>
  </div>
</template>

<script>
import { Modes } from '@scenes/SelfserviceScene/components/Panel/types';

export default {
  name: 'PanelItemSkeleton',

  props: {
    color: {
      type: String,
      default: Modes.LIGHT,
    },
  },
};
</script>

<style lang="scss" scoped>
.PanelItemSkeleton {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 10px;
  padding: 10px 20px;

  &--light {
    border-top: 2px solid #f6f6f6;
  }

  &--grey {
    border-top: 2px solid #ffffff;
    background-color: #f6f6f6;
  }

  &:first-child {
    border-top: 0px solid #efefef;
  }

  .PanelItemSkeleton__content {
    display: flex;
    align-items: center;
    gap: 10px;
  }

  .PanelItemSkeleton__controls {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 10px;
  }
}
</style>
