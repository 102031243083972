<template>
  <StoresContainer v-slot="{ status, stores, searchPoint, search }">
    <div :class="['Storefinder', scrollable && 'Storefinder--scroll']">
      <div class="Storefinder__header">
        <SearchField
          :placeholder="$t('branch.search_placeholder')"
          :value.sync="searchQuery"
          :debounce="500"
          :hint="searchPoint && searchPoint.formatted_address"
          @search="search"
        />
        <div class="Storefinder__searchMessage" />
      </div>
      <div ref="StoreFinderBody" class="Storefinder__body">
        <ProgressOverlay :progress="status === Progress.WORKING" :spinner-position="position.TOP">
          <StoreSelect
            :stores="stores"
            :selected.sync="selectedStore"
            class="Storefinder__stores"
            :preselect="preselectShop"
            :showAlerts="showAlerts"
          />
        </ProgressOverlay>
      </div>
    </div>
  </StoresContainer>
</template>

<script>
import { gsap } from 'gsap/all';
import StoresContainer from '@containers/StoresContainer';
import SearchField from '@components/SearchField';
import ProgressOverlay from '@components/ProgressOverlay';
import StoreSelect from './components/StoreSelect';
import Cookies from 'js-cookie';
import Progress from '@types/Progress';
import { position } from '@components/ProgressOverlay/types';

export default {
  name: 'StoreFinder',

  components: {
    StoresContainer,
    SearchField,
    ProgressOverlay,
    StoreSelect,
    Cookies,
  },

  enums: {
    Progress,
    position,
  },

  props: {
    onUpdate: {
      type: Function,
      default: null,
    },
    onUpdateFunction: {
      type: String,
      default: null,
    },
    scrollable: {
      type: Boolean,
      default: false,
    },
    preselect: {
      type: Object,
      default: null,
    },
    ignoreCookie: {
      type: Boolean,
      default: false,
    },
    showAlerts: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      searchQuery: '',
      selectedStore: null,
      preselectShop: null,
    };
  },

  watch: {
    searchQuery(to, from) {
      if (to !== from) this.scrollToTop();
    },
    selectedStore(to) {
      if (this.scrollable && to !== null) this.scrollToTop();
      if (typeof this.onUpdate === 'function') this.onUpdate(to);

      if (this.onUpdateFunction && typeof window[this.onUpdateFunction] === 'function')
        window[this.onUpdateFunction](to);
    },
  },

  created() {
    this.window = window;

    if (this.preselect) {
      this.preselectShop = this.preselect;
    } else if (!this.ignoreCookie && Cookies.get('favShopClosestsWebId')) {
      this.preselectShop = { slug: Cookies.get('favShopClosestsWebId') };
    }
    this.selectedStore = this.preselectShop;
  },

  methods: {
    scrollToTop() {
      gsap.to(this.$refs.StoreFinderBody, { duration: 0.8, scrollTo: { y: 0 } });
    },
  },
};
</script>

<style lang="scss" scoped>
.Storefinder {
  display: flex;
  flex-direction: column;
  position: relative;

  &.Storefinder--scroll {
    max-height: 100%;
    overflow: hidden;
  }

  .Storefinder__stores {
    min-height: 200px;
  }

  .Storefinder__header {
    flex-shrink: 0;
  }

  .Storefinder__body {
    position: relative;
    border-bottom: 1px solid $color-prim-grey;
  }

  &.Storefinder--scroll .Storefinder__body {
    flex: 1 1 auto;
    overflow-y: auto;
  }
}
</style>
