<template>
  <div class="UserFormSkeleton">
    <SkeletonGraphic :width="300" :height="28" />
    <div class="UserFormSkeleton__inputs">
      <InputSkeleton
        v-for="i in 12"
        :key="i"
        :class="['UserFormSkeleton__input', isWide(i) && 'UserFormSkeleton__input--wide']"
      />
    </div>
    <ButtonSkeleton :size="Sizes.MEDIUM" />
  </div>
</template>

<script>
import SkeletonGraphic from '../SkeletonGraphic';
import ButtonSkeleton from '../ButtonSkeleton';
import InputSkeleton from '../InputSkeleton';
import { Sizes } from '../../index';

export default {
  name: 'UserFormSkeleton',

  components: {
    SkeletonGraphic,
    ButtonSkeleton,
    InputSkeleton,
  },

  enums: {
    Sizes,
  },

  methods: {
    isWide(input) {
      return input === 3;
    },
  },
};
</script>

<style lang="scss" scoped>
.UserFormSkeleton {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: flex-start;
  @include maxBreakpoint(760) {
    align-items: stretch;
  }

  .UserFormSkeleton__inputs {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 20px;
  }

  .UserFormSkeleton__input {
    margin-top: auto;
    flex: 1;
    grid-column: span 1;
    &--wide {
      grid-column: span 2;
    }
  }
}
</style>
