<template>
  <div class="SkeletonTransition">
    <transition v-if="!startUp" @enter="enter" @leave="leave" mode="out-in">
      <div class="SkeletonTransition__inner" v-if="ready" key="default">
        <slot name="default" />
      </div>
      <div v-else key="skeleton" style="width:100%;">
        <slot name="skeleton" />
      </div>
    </transition>
    <div v-else-if="ready" key="default">
      <slot name="default" />
    </div>
    <div v-else key="skeleton" style="opacity: 0">
      <slot name="skeleton" />
    </div>
  </div>
</template>

<script>
import { gsap } from 'gsap/all';

export default {
  name: 'SkeletonTransition',

  props: {
    ready: {
      type: Boolean,
      required: true,
    },
  },

  data() {
    return {
      leaveHeight: 0,
      startUp: true,
      timeout: null,
    };
  },

  created() {
    this.timeout = setTimeout(() => (this.startUp = false), 200);
  },

  beforeDestroy() {
    clearTimeout(this.timeout);
  },

  methods: {
    enter(element) {
      const height = getComputedStyle(element).height;
      gsap.fromTo(
        element,
        { height: this.leaveHeight },
        {
          height,
          duration: 0.2,
          overwrite: true,
          ease: 'sine.out',
          onComplete() {
            element.style.height = null;
          },
        },
      );
    },
    leave(element) {
      this.leaveHeight = getComputedStyle(element).height;
    },
  },
};
</script>

<style lang="scss" scoped>
.SkeletonTransition {
  > div {
    width: 100%;
  }
  .v-enter-active {
    overflow: hidden;
    transition: opacity 0.2s cubic-bezier(0.25, 1, 0.5, 1);
  }
  .v-leave-active {
    overflow: hidden;
    transition: opacity 0.2s cubic-bezier(0.5, 0, 0.75, 0);
  }
  .v-enter,
  .v-leave-to {
    opacity: 0;
  }
}
</style>
