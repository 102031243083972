<template>
  <div class="TimelineSkeleton">
    <SkeletonGraphic class="TimelineSkeleton__line" :width="4" :height="60 * (nodes - 1)" />
    <div class="TimelineSkeleton__firstSkeletonEvent">
      <SkeletonGraphic :width="34" :height="34" borderRadius="50%" />
      <div class="TimelineSkeleton__items">
        <SkeletonGraphic :width="150 + Math.random() * 30" :height="21" />
        <SkeletonGraphic :width="90 + Math.random() * 30" :height="14" />
      </div>
    </div>
    <div v-for="(node, index) in nodes - 1" :key="index" class="TimelineSkeleton__skeletonEvent">
      <SkeletonGraphic :width="26" :height="26" borderRadius="50%" />
      <div class="TimelineSkeleton__items">
        <SkeletonGraphic :width="100 + Math.random() * 30" :height="19" />
        <SkeletonGraphic :width="90 + Math.random() * 30" :height="14" />
      </div>
    </div>
  </div>
</template>

<script>
import SkeletonGraphic from '@components/Skeleton/components/SkeletonGraphic';

export default {
  name: 'TimelineSkeleton',

  components: {
    SkeletonGraphic,
  },

  props: {
    nodes: {
      type: [Number, String],
      default: 2,
    },
  },
};
</script>

<style lang="scss" scoped>
.TimelineSkeleton {
  display: flex;
  flex-direction: column;
  gap: 24px;
  position: relative;

  .TimelineSkeleton__line {
    position: absolute;
    left: 15px;
    z-index: -1;
    margin-top: 20px;
  }

  .TimelineSkeleton__skeletonListEvent {
    display: flex;
    flex-direction: column;
    gap: 24px;
    position: relative;
  }

  .TimelineSkeleton__firstSkeletonEvent {
    display: flex;
    gap: 10px;
  }

  .TimelineSkeleton__skeletonEvent {
    display: flex;
    gap: 15px;
    margin-left: 3.5px;
  }

  .TimelineSkeleton__items {
    display: flex;
    flex-direction: column;
    gap: 3px;
  }
}
</style>
