import { dateIsBetween } from './dateIsBetween';
import { isNumber } from './isNumber';
import { isEmail } from './isEmail';
import { isPassword } from './isPassword';

export default {
  dateIsBetween,
  isNumber,
  isEmail,
  isPassword,
};
