<template>
  <component
    :is="tag"
    :class="[
      'Input',
      'Input--tight',
      type === 'password' && 'Input--password',
      errorVisible && !isValid && 'is-invalid',
    ]"
  >
    <label :for="id">
      <slot name="default">{{ label }}</slot>
    </label>
    <input
      :id="id"
      :value="value"
      :type="type == 'password' && showPassword ? 'text' : type"
      :required="required"
      :minlength="minlength"
      :maxlength="maxlength"
      v-bind="$attrs"
      @input="onInput"
      @blur="onBlur"
      @focus="e => $emit('focus', e)"
    />
    <i
      v-if="clearInput && value.length"
      :class="'far fa-times clear ' + clearIsHidden"
      @click="clear"
    />
    <button
      v-if="type == 'password'"
      id="toggle-password"
      type="button"
      :aria-label="$t('input.show_password_aria')"
      tabindex="-1"
      @click="togglePassword"
    >
      <i v-if="showPassword" class="far fa-eye-slash" :title="$t('input.hide_password')" />
      <i v-else class="Input__eyeIcon far fa-eye" :title="$t('input.show_password')" />
    </button>
    <span v-if="errorVisible && errorMessage" class="Input__message js-message">
      {{ errorMessage }}
    </span>
  </component>
</template>

<script>
import { isEmail } from '@helpers/validation/isEmail';

export { Autocompletes } from './types';

export default {
  name: 'Input',

  props: {
    tag: {
      type: String,
      default: 'div',
    },
    id: {
      type: String,
      default: '',
    },
    value: {
      type: [String, Number],
      default: '',
    },
    type: {
      type: String,
      default: 'text',
    },
    label: {
      type: String,
      default: '',
    },
    required: {
      type: Boolean,
      default: false,
    },
    minlength: {
      type: Number,
      default: null,
    },
    maxlength: {
      type: Number,
      default: null,
    },
    pattern: {
      type: String,
      default: '',
    },
    patternErrorMessage: {
      type: String,
      default: undefined,
    },
    clearInput: {
      type: Boolean,
      default: false,
    },
    validateOnBlur: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      errorVisible: false,
      clearIsHidden: 'is-hidden',
      showPassword: false,
    };
  },

  computed: {
    isEmpty() {
      return !this.value && this.value !== 0;
    },
    matchPattern() {
      if (this.pattern.length) {
        const regEx = new RegExp(this.pattern, 'g');
        if (!String(this.value).match(regEx)) return false;
      }
      return true;
    },
    isValid() {
      if (this.isEmpty && this.required) return false;
      if (this.isEmpty && !this.required) return true;
      if (this.minlength && this.value.length < this.minlength) return false;
      if (this.maxlength && this.value.length > this.maxlength) return false;
      if (this.type === 'email' && !isEmail(this.value)) return false;
      if (!this.matchPattern) return false;
      return true;
    },
    errorMessage() {
      if (this.isEmpty && this.required) {
        if (!this.label) return this.$t('input.field_is_required');
        return this.$t('input.field_is_required--named', { field: this.label });
      }
      if (this.isEmpty && !this.required) return '';
      if (this.minlength && this.value.length < this.minlength)
        return this.$t('input.min_length_required', { length: this.minlength });
      if (this.maxlength && this.value.length > this.maxlength)
        return this.$t('input.max_length_required', { length: this.maxlength });
      if (this.type === 'email' && !isEmail(this.value)) return this.$t('input.invalid_email');
      if (!this.matchPattern) return this.patternErrorMessage ?? this.$t('input.invalid_pattern');
      return '';
    },
  },

  watch: {
    isValid(to) {
      if (this.validator) this.validator.updateItem(this.validatorId, to);
      if (to) this.hideError();
    },
    errorMessage(to, from) {
      if (this.errorVisible && to !== from) {
        this.hideError();
      }
    },
  },

  created() {
    this.validatorId = this.id || this._uid;
    if (this.validator) this.validator.addItem(this.validatorId, this.isValid, this.showError);
  },

  beforeDestroy() {
    if (this.validator) this.validator.removeItem(this.validatorId);
  },

  methods: {
    clear() {
      this.$emit('input', '');
      this.clearIsHidden = '';
    },
    togglePassword() {
      if (this.type === 'password') this.showPassword = !this.showPassword;
    },
    onInput(e) {
      let newValue = e.target.value;
      this.hideError();
      this.$emit('input', newValue);
    },
    onBlur(e) {
      if (this.validateOnBlur && !this.isEmpty && !this.isValid) this.showError();
      this.$emit('blur');
    },
    showError() {
      this.errorVisible = true;
    },
    hideError() {
      this.errorVisible = false;
    },
  },

  inject: {
    validator: {
      default() {
        return; // having a default if no validator parent is avaliable
      },
    },
  },
};
</script>

<style>
i.clear {
  cursor: pointer;
  position: absolute;
  top: 43px;
  right: 15px;
}
</style>

<style lang="scss" scoped>
.Input {
  .Input__eyeIcon {
    color: #999999;
  }
}
</style>
