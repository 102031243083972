import { Api } from '../';

/**
 * Get drafts by type
 * @param {String} type
 * @param {Object} options
 */
export function getDrafts(type, options) {
  if (type === undefined) {
    console.error('No draft type declared');
    return;
  }
  return new Promise((resolve, reject) => {
    Api.get(`/drafts`, { params: { type } })
      .then(response => {
        resolve(response.data.data);
      })
      .catch(error => {
        reject(error.response.data);
      });
  });
}

/**
 * Get a specific draft including it's lines
 * @param {string} draftId
 */
export function getDraft(id) {
  return new Promise((resolve, reject) => {
    Api.get(`/drafts/${id}`)
      .then(response => {
        resolve(response.data.data);
      })
      .catch(error => {
        reject(error.response.data);
      });
  });
}

/**
 * Delete a specific draft including it's lines
 * @param {string} draftId
 */
export function deleteDraft(id) {
  return new Promise((resolve, reject) => {
    Api.delete(`/drafts/${id}`)
      .then(response => {
        resolve(response.data.data);
      })
      .catch(error => {
        reject(error.response.data);
      });
  });
}

/**
 * Add a specific draft to the barsket
 * @param {string} draftId
 */
export function draftToCart(id, options) {
  return new Promise((resolve, reject) => {
    Api.post(`/drafts/${id}/tocart`)
      .then(response => {
        resolve(response.data.data);
      })
      .catch(error => {
        reject(error.response.data);
      });
  });
}

/**
 * Transform a draft to another type
 * @param {*} id Draft ID
 * @param {*} type Draft type to transform to
 * @param {*} options Object:
 *                    "preserve_lines" : true, //Optional, default to false
 *                    "delete_donor" : true //Optional, default to true
 */
export function postTransformType(id, type, options) {
  return new Promise((resolve, reject) => {
    Api.post(`/drafts/${id}/to${type}`, { ...options })
      .then(response => {
        resolve(response.data.data);
      })
      .catch(error => {
        reject(error.response.data);
      });
  });
}

/**
 * Patch a specific draft
 * @param {string} draftId
 */
export function patchDraft(id, data) {
  return new Promise((resolve, reject) => {
    // { reg, description, customer refrence }
    Api.patch(`/drafts/${id}`, data)
      .then(response => {
        resolve(response.data.data);
      })
      .catch(error => {
        reject(error.response.data);
      });
  });
}
