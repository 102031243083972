import Cookies from 'js-cookie';
import * as signalR from '@microsoft/signalr';
import Progress from '@types/Progress';
import { Mutation } from './types';

let connection = null;
let start = Date.now();

export const Getter = {
  GET_CONNECTION: 'getConnection',
  GET_QUANTITY_BY_ITEM_NUMBER: 'getQuantityByItemNumber',
  GET_QUANTITY_BY_ITEM_ID: 'getQuantityByItemId',
  GET_TOTAL_QUANTITY: 'getTotalQuantity',
};

export const Action = {
  INITIALIZE_CONNECTION: 'initializeConnection',
  START_CONNECTION: 'startConnection',
  ADD_LINE: 'addLine',
  REMOVE_LINE: 'removeLine',
  UPDATE_LINE: 'updateLine',
};

export default {
  namespaced: true,
  state: {
    lines: [],
    status: '',
    updateStatus: '',
    // Filter is the used for the manuel car selector/search.
  },
  getters: {
    [Getter.GET_CONNECTION]: (state, getters) => {
      return connection;
    },
    [Getter.GET_QUANTITY_BY_ITEM_NUMBER]: state => itemNumber => {
      const foundLine = state.lines.find(
        line => line.item_number?.toString() === itemNumber.toString(),
      );

      return foundLine ? foundLine.quantity : 0;
    },
    [Getter.GET_QUANTITY_BY_ITEM_ID]: state => itemId => {
      const foundLine = state.lines.find(line => {
        return line.item_id?.toString() === itemId.toString();
      });
      return foundLine ? foundLine.quantity : 0;
    },
    [Getter.GET_TOTAL_QUANTITY]: (state, getters) => {
      return state.lines.reduce((quantity, line) => quantity + line.quantity, 0);
    },
  },
  actions: {
    [Action.INITIALIZE_CONNECTION]({ commit, dispatch }) {
      if (this.status === Progress.WORKING || this.status === Progress.COMPLETE) return;

      commit(Mutation.SET_STATUS, Progress.WORKING);
      connection = new signalR.HubConnectionBuilder()
        .withUrl(
          `https://xalapi.thg.dk/BasketHub?c=${Cookies.get('c')}&checksum=${Cookies.get('cok')}`,
        )
        .build();
    },
    [Action.START_CONNECTION]({ commit, dispatch }) {
      if (this.status === Progress.COMPLETE) return;

      async function start() {
        try {
          await connection.start();
          commit(Mutation.SET_STATUS, Progress.COMPLETE);
        } catch (err) {
          commit(Mutation.SET_STATUS, Progress.ERROR);
          setTimeout(() => start(), 5000);
        }
      }
      connection.onclose(async () => {
        await start();
      });
      connection.on('populateBasket', lines => {
        commit(Mutation.UPDATE_CART_LINES, lines);
      });
      connection.on('resetSession', e => {
        dispatch('xal/reset', null, { root: true });
      });
      start();
    },
    [Action.ADD_LINE]({ commit, state, dispatch }, item) {
      connection
        .invoke('AddLine', item)
        .then(lines => {
          commit(Mutation.UPDATE_CART_LINES, lines);
        })
        .catch(err => console.error(err));
    },
    [Action.REMOVE_LINE]({ commit, state, dispatch }, item) {
      connection
        .invoke('DeleteLine', item)
        .then(lines => {
          commit(Mutation.UPDATE_CART_LINES, lines);
        })
        .catch(err => console.error(err));
    },
    [Action.UPDATE_LINE]({ commit, state, dispatch }, item) {
      if (state.updateStatus === Progress.WORKING) return;
      commit(Mutation.SET_UPDATE_STATUS, Progress.WORKING);
      connection
        .invoke('SetLineQuantity', item)
        .then(lines => {
          commit(Mutation.UPDATE_CART_LINES, lines);
          commit(Mutation.SET_UPDATE_STATUS, Progress.COMPLETE);
        })
        .catch(err => {
          commit(Mutation.SET_UPDATE_STATUS, Progress.ERROR);
          console.error(err);
        });
    },
  },
  mutations: {
    [Mutation.UPDATE_CART_LINES](state, lines) {
      state.lines = lines;
    },
    [Mutation.SET_STATUS](state, status) {
      state.status = status;
    },
    [Mutation.SET_UPDATE_STATUS](state, status) {
      state.updateStatus = status;
    },
  },
};
