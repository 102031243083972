<template>
  <span v-if="!isNaN(parsedValue)" class="Price">
    {{ currency }} {{ $n(parsedValue, 'currency') }}
  </span>
</template>

<script>
export default {
  name: 'Price',

  props: {
    value: {
      default: 0,
      type: [Number, String],
    },
  },

  data() {
    return {
      currency: window.gloCurrencyCode ?? 'Kr.',
    };
  },

  computed: {
    parsedValue() {
      return parseFloat(this.value);
    },
  },

  methods: {
    isNaN(value) {
      return value !== value;
    },
  },
};
</script>
