import { Api } from '../';

/**
 * Lookup car
 * @param {string} nodeId
 */
export function fetchCardataByNode(nodeId) {
  return new Promise((resolve, reject) => {
    Api.post(
      `/cars/RequestNodeInfo/${nodeId}`,
      {},
      {
        errorHandlerIgnore(error) {
          // Currently we need to filter out "400" which can be a good response (will be refactored)
          return error.response.status === 400;
        },
      },
    )
      .then(response => {
        resolve(response.data.data);
      })
      .catch(error => {
        reject(error);
      });
  });
}

/**
 *
 * @param {string} nodeId
 */
export function getCardataById(vehicleId) {
  return new Promise((resolve, reject) => {
    Api.post(
      `/cars/RequestVehicleInfoById/${vehicleId}`,
      {},
      {
        errorHandlerIgnore(error) {
          // Currently we need to filter out "400" which can be a good response (will be refactored)
          return error.response.status === 400;
        },
      },
    )
      .then(response => {
        resolve(response.data.data);
      })
      .catch(error => {
        reject(error);
      });
  });
}

/**
 *
 * @param {string} licensePlate
 */
export function fecthCardataByLicensePlate(licensePlate) {
  return new Promise((resolve, reject) => {
    Api.post(
      `/cars/RequestVehicleInfo/${licensePlate}`,
      {},
      {
        errorHandlerIgnore(error) {
          // Currently we need to filter out "400" which can be a good response (will be refactored)
          return error.response.status === 400;
        },
      },
    )
      .then(response => {
        // TODO: Validate response
        resolve(response.data.data);
      })
      .catch(error => {
        if (error.response && error.response.data) {
          reject(error.response.data);
        } else {
          reject(error);
        }
      });
  });
}

/**
 *
 * @param {string} season
 */
export function fetchWheels({ season, vehicleId }) {
  return new Promise((resolve, reject) => {
    Api.get(`/wheels/GetWheels/${season}`, {
      params: {
        vehicleId,
      },
    })
      .then(response => {
        resolve(response.data.data);
      })
      .catch(error => {
        reject(error);
      });
  });
}
