import Vue from 'vue';
import Cookies from 'js-cookie';
import { Mutation } from './types';

import { closeSession } from '../../../services/thansen/xal';

import cart from './cart';

export default {
  namespaced: true,
  state: {
    status: '',
    sessionId: '',
  },
  getters: {},
  actions: {
    start({ commit, state, dispatch }, sessionId) {
      commit(Mutation.SET_SESSION_ID, sessionId);
    },
    stop({ commit, state, dispatch }) {
      if (state.sessionId) closeSession(state.sessionId);
      dispatch('reset');
    },
    reset() {
      Cookies.remove('c');
      window.location.href = '/';
    },
  },
  mutations: {
    [Mutation.SET_SESSION_ID](state, sessionId) {
      state.sessionId = sessionId;
    },
  },
  modules: { cart },
};
