<template>
  <AuthenticationContainer>
    <Login :mode="mode" :doReload="doReload" :requireAcceptTerms="requireAcceptTerms" />
  </AuthenticationContainer>
</template>

<script>
import AuthenticationContainer from '@containers/AuthenticationContainer';
import Login, { Modes } from './components/Login';

export { Modes };

export default {
  name: 'LoginWrapper',

  components: {
    AuthenticationContainer,
    Login,
  },

  props: {
    mode: {
      type: String,
      default: 'LOGIN',
    },
    doReload: {
      type: Boolean,
      default: true,
    },
    requireAcceptTerms: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss"></style>
