<template>
  <transition name="fade" @after-leave="afterLeave">
    <div v-if="isOpen" :class="['Lightbox', dark && 'Lightbox--dark']">
      <div
        class="Lightbox__content"
        @touchstart="onTouchstart"
        @mousedown="onMousedown"
        @click.self="onClick"
      >
        <slot name="default" :close="close" />
      </div>
    </div>
  </transition>
</template>

<script>
import { disableScrollBar, enableScrollBar } from '@helpers/viewport';

export default {
  name: 'Lightbox',

  props: {
    dark: {
      type: Boolean,
      default: false,
    },
    locked: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      isOpen: false,
      latestEvent: null,
    };
  },

  beforeDestroy() {
    enableScrollBar();
  },

  methods: {
    onTouchstart(event) {
      this.latestEvent = event;
    },
    onMousedown(event) {
      this.latestEvent = event;
    },
    onClick(event) {
      if (!this.locked && event.target === this.latestEvent.target) this.close();
    },
    open() {
      this.isOpen = true;
      disableScrollBar();
      this.$emit('open');
    },
    close() {
      this.isOpen = false;
      this.$emit('close');
    },
    afterLeave() {
      enableScrollBar();
    },
  },
};
</script>

<style lang="scss" scoped>
.Lightbox {
  position: fixed;
  z-index: 9999;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(255, 255, 255, 0.4);

  &--dark {
    background-color: rgba(0, 0, 0, 0.4);
  }

  .Lightbox__content {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    width: 100%;
    height: 100%;
    padding: 30px 20px;
    overflow-x: hidden;
    overflow-y: auto;
    @include maxBreakpoint($mobileL-numeric) {
      padding: 30px 10px;
    }
  }
}

.fade-enter-active {
  // Sets duration on an arbitrary property (width) so it waits for both transitions (Vue limitation)
  transition: opacity 0.15s linear, width 0.3s;
  .Lightbox__content {
    transition: transform 0.3s ease-out;
  }
}

.fade-leave-active {
  transition: opacity 0.1s linear 0.1s;
  .Lightbox__content {
    transition: transform 0.2s ease-in;
  }
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
  .Lightbox__content {
    transform: translate3d(0, -25%, 0);
  }
}
</style>
