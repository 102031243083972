<template>
  <router-link v-if="to" :to="to" v-bind="$attrs" class="DropdownButton">
    <slot name="default" />
  </router-link>
  <a v-else-if="href" :href="href" v-bind="$attrs" class="DropdownButton">
    <slot name="default" />
  </a>
  <button v-else class="DropdownButton" v-bind="$attrs" @click="e => $emit('click', e)">
    <slot name="default" />
  </button>
</template>

<script>
export default {
  name: 'DropdownButton',

  props: {
    href: {
      type: String,
      default: undefined,
    },
    to: {
      type: [String, Object],
      default: undefined,
    },
  },
};
</script>

<style lang="scss" scoped>
.DropdownButton {
  display: block;
  background-color: #ffffff;
  color: black;
  border: none;
  padding: 10px 20px;
  text-align: left;
  text-decoration: none;
  &:hover {
    background-color: $color-prim-grey;
  }
}
</style>
