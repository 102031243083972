<template>
  <a
    class="SearchGroupResult"
    :href="group.url"
    :title="group.title"
    @click="$emit('click', group)"
  >
    <div ref="container" class="SearchGroupResult__info">
      <div class="SearchGroupResult__title">
        <AisHighlight attribute="title" :hit="group" :class-names="{ 'ais-Highlight': '' }" />
      </div>
      <div v-if="group.breadcrumb" class="SearchGroupResult__breadcrumb">
        <ShortenedPath :path="path" :container-width="containerWidth" :divider="divider" />
      </div>
    </div>
    <div v-if="group.image" class="SearchGroupResult__image">
      <img v-if="group.image" :src="group.image" :alt="group.title" />
      <i v-else class="far fa-image"></i>
    </div>
  </a>
</template>

<script>
import { AisHighlight } from 'vue-instantsearch';
import ShortenedPath from './components/ShortenedPath';

export default {
  name: 'GroupCard',
  components: {
    AisHighlight,
    ShortenedPath,
  },
  props: {
    group: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      containerWidth: 0,
    };
  },
  computed: {
    //Removes the last category from the breadcrumb
    path() {
      return this.group.breadcrumb
        .split(this.divider)
        .slice(0, -1)
        .join(this.divider);
    },
  },
  created() {
    this.divider = ' / ';
  },
  mounted() {
    this.$nextTick(() => {
      this.updateContainerWidth();
      window.addEventListener('resize', this.updateContainerWidth);
    });
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.updateContainerWidth);
  },
  methods: {
    updateContainerWidth() {
      this.containerWidth = this.$refs.container.getBoundingClientRect().width;
    },
  },
};
</script>

<style lang="scss" scoped>
.SearchGroupResult {
  .SearchGroupResult__info {
    width: 100%;
  }
}
</style>
