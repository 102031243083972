<template>
  <AccountContainer ref="accountContainer" v-slot="{ account }">
    <div>
      <template v-if="!account">
        <div @click.prevent="$refs.modal.open(Modes.LOGIN)">
          <slot name="default" />
        </div>
        <LoginModal ref="modal" />
      </template>
      <template v-else-if="account && (!isTabletSize || minimal)">
        <UserDropdown :minimal="minimal">
          <slot name="default" />
        </UserDropdown>
      </template>
      <template v-else-if="account && isTabletSize">
        <div @click.prevent="$refs.drawer.open()">
          <slot name="default" />
        </div>
        <UserDrawer ref="drawer" />
      </template>
    </div>
  </AccountContainer>
</template>

<script>
import AccountContainer from '@containers/AccountContainer';
import LoginModal, { Modes } from '@components/Login/components/LoginModal';
import UserDropdown from './components/UserDropdown';
import UserDrawer from './components/UserDrawer';

export default {
  name: 'TopBarLoginButton',

  components: {
    AccountContainer,
    LoginModal,
    UserDropdown,
    UserDrawer,
  },

  props: {
    minimal: {
      type: Boolean,
      default: false,
    },
  },

  enums: {
    Modes,
  },

  computed: {
    isTabletSize() {
      return ['mobileS', 'mobileM', 'mobileL', 'tablet'].includes(this.$mq);
    },
  },
};
</script>
