<template>
  <span>
    <button class="LabelModalInline" @click="open">
      <slot name="default" />
    </button>
    <LabelModal ref="modal" :label="label" />
  </span>
</template>

<script>
import LabelModal from '../LabelModal';

export default {
  name: 'LabelModalInline',
  components: {
    LabelModal,
  },
  props: {
    label: {
      type: String,
      required: true,
    },
  },
  methods: {
    open() {
      this.$refs.modal.open();
    },
  },
};
</script>
<style lang="scss" scoped>
.LabelModalInline {
  display: inline;
  border: none;
  outline: none;
  background: none;
  text-decoration: underline;
  margin: 0;
  padding: 0;
}
</style>
