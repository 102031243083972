let mode = 'b2c';
let organization = 'THG';

if (window.globalvar_domainCode === 'VAU') {
  mode = 'b2b';
  organization = 'VAU';
}

const legacy = { ...window.gloSettings };

// Store versions should be used on all persistedstate store modules. A checkVersion action MUST be added to the module before adding it here.
const storeVersions = {
  products: '1.1',
};

const selfservicePath = () => {
  const regex = new RegExp('^/(mit-thansen|konto|min-thansen).*');
  return regex.test(location.pathname);
};

const settings = {
  mode,
  testmode: window.gloSettings && window.gloSettings.testmode,
  xalmode: window.gloSettings && window.gloSettings.xalmode,
  blackweek: false, // Enables "black" filters etc.
  azureSubscription: window.globalvar_azureSubscription,
  shp: false,
  search: {
    referenceSearchEnabled: false, //TODO: Fjern reference søgning. Algolia har alt data nu. MVN 2021
    indices: window.algoliaIndices,
  },
  selfservice: {
    useLegacyRoutes: false, //!selfservicePath(),
  },
  legacy,
  organization,
  storeVersions,
};

if (settings.testmode) {
  console.log('Settings', settings);
}

export default settings;
