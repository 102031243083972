<template>
  <div class="BoxLayout">
    <div class="BoxLayout__content">
      <div class="BoxLayout__top">
        <div class="BoxLayout__title">{{ title }}</div>
        <slot name="description" />
      </div>
      <slot name="default" />
    </div>
    <div v-if="hasControlsSlot()" class="BoxLayout__controls">
      <slot name="controls" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'BoxLayout',

  props: {
    title: {
      type: String,
      required: true,
    },
  },

  methods: {
    hasControlsSlot() {
      // $slots is not reactive. Therefore a method is necessary (vs computed property)
      return !!this.$slots['controls'] || !!this.$scopedSlots['controls'];
    },
  },
};
</script>

<style lang="scss" scoped>
.BoxLayout {
  display: flex;
  flex-direction: column;
  background-color: var(--color-prim);
  color: #ffffff;
  gap: 55px;
  width: 100%;
  font-size: 16px;
  padding: 50px;

  @include maxBreakpoint($laptopS-numeric) {
    padding: 40px 40px;
  }

  @include maxBreakpoint($mobileL-numeric) {
    padding: 30px 20px;
  }

  .BoxLayout__content {
    display: flex;
    flex-direction: column;
    gap: 31px;
    padding-bottom: 10px;
    @include maxBreakpoint($tablet-numeric) {
      gap: 20px;
    }
  }

  .BoxLayout__top {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 17px;
  }

  .BoxLayout__title {
    font-weight: bold;
    font-size: 30px;
    line-height: 1.2em;
    text-align: center;
    margin-bottom: 0.2em;
  }

  .BoxLayout__controls {
    display: flex;
    justify-content: space-evenly;
  }
}
</style>
