// https://github.com/Dotunj/vue-middleware-pipelines
// https://levelup.gitconnected.com/advanced-vue-js-internationalization-i18n-and-localization-l10n-using-vue-router-a94ecd83fecd
// https://medium.com/@disjfa/creating-navigation-using-vue-router-59d0b12ab75f

import Vue from 'vue';
import Router from 'vue-router';

import middlewarePipeline from './middlewarePipeline';

import i18n from '@/i18n';
import settings from '@settings';

import routes from './routes';

import tracking from '@tracking';

Vue.use(Router);

const router = new Router({
  //scrollBehavior(to, from, savedPosition) {}, //Removed until it works with native history back() MVN*/
  mode: 'history',
  base: '/',
  routes,
});

router.afterEach((to, from) => {
  if (from && from.name && from.name !== to.name) {
    /* 
      Hotfix. Scroll to top on router navigation.
      Router scrollBehavior could be used but since it disables the native scroll on history back/forward.
      */

    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 200);
  }

  if (to.name !== null) {
    window.disableAutomaticRefresh = true; // Disables the automatic refresh - check VAUDeliveryDeadlineCountdown (jslib.js)
  }

  // Track pageview. Currently the "/" check sould work unless the SPA is running on root /.
  if (from.path !== '/') {
    tracking.addPageview(to.path);
  }
  // Update the document title and add the root title as a suffix
  if (to.meta && to.meta.title) {
    const rootTitle = to.matched[0] && to.matched[0].meta && to.matched[0].meta.title;
    document.title =
      to.meta.title !== rootTitle ? `${to.meta.title} - ${rootTitle}` : to.meta.title;
  }
});

router.beforeEach((to, from, next) => {
  let middleware = [];

  if (to.meta.middleware) {
    middleware = to.meta.middleware;
  }

  if (middleware.length === 0) {
    return next();
  }

  const context = {
    to,
    from,
    next,
    //store,
  };

  return middleware[0]({
    ...context,
    next: middlewarePipeline(context, middleware, 1),
  });
});

export default router;
