import { Api } from '..';

export function postLogin(credentials, remember_me) {
  return new Promise((resolve, reject) => {
    const customer_id = credentials.email_address || credentials.email || credentials.customer_id;
    const password = credentials.password;

    if (!customer_id || password == undefined) {
      reject();
    }

    Api.post('/authentication/login', { customer_id, password, remember_me })
      .then(response => {
        //customer_id and password does not match
        if (!response.data.data || response.data.error || response.data.errors) {
          reject(response.data.error);
        }
        //login is successful
        resolve(response.data.data);
      })
      .catch(error => {
        reject(error);
      });
  });
}

export function postLogout() {
  return new Promise((resolve, reject) => {
    Api.post('/authentication/logout')
      .then(response => {
        resolve(response.data.data);
      })
      .catch(error => {
        reject(error);
      });
  });
}

export function postPasswordRequestChange(email) {
  return new Promise((resolve, reject) => {
    Api.post('/authentication/requestchange', { email_address: email })
      .then(response => {
        resolve(response.data.data);
      })
      .catch(error => {
        reject(error);
      });
  });
}

export function getToken() {
  return new Promise((resolve, reject) => {
    Api.get('/authentication/change/token')
      .then(response => {
        resolve(response.data.data.token);
      })
      .catch(error => {
        reject(error);
      });
  });
}

export function postPasswordChange(token, password, extra) {
  /*
    Possible extra data:
    old_password
    favorite_branch
    subscribe_newsletter
  */
  return new Promise((resolve, reject) => {
    const body = { token, password, ...extra };
    Api.post('/authentication/change', body)
      .then(response => {
        if (response.data.errors) reject(response.data.errors);
        resolve(response.data.data);
      })
      .catch(error => {
        reject(error);
      });
  });
}

export function getLoginHistory() {
  return new Promise((resolve, reject) => {
    Api.get('/authentication/history')
      .then(response => {
        resolve(response.data.data);
      })
      .catch(error => {
        reject(error);
      });
  });
}
