import { onSale } from './onSale';

export const labelType = product => {
  if (product.is_demo) {
    return 'demo';
  } else if (product.label === 'KAMPAGNE') {
    return 'campaign';
  } else if (onSale(product.is_sale, product.price, product.compare_at_price)) {
    return 'sale';
  } else if (product.is_new) {
    return 'new';
  }

  return '';
};
