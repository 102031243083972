import { InputType } from '@types/InputType';

export const getInputFormatByType = (value, inputType, scope) => {
  switch (inputType) {
    case InputType.TEXT:
    case InputType.EMAIL:
      return value;
    case InputType.CURRENCY:
      return scope.$n(value, 'currency');
    case InputType.NUMBER:
      return scope.$n(value, 'number');
  }
};
