<template>
  <PreventBodyScroll :prevent="isOpen && isActive">
    <div :class="['Drawer', isOpen && 'is-open', isActive && 'is-active']">
      <div class="Drawer__drawer">
        <div class="Drawer__content" @scroll="scrollTop = $event.target.scrollTop">
          <slot :isActive="isActive" :isOpen="isOpen" :scrollTop="scrollTop" />
        </div>
        <button class="Drawer__control" @click="close">
          {{ closeLabel }}
        </button>
      </div>

      <div class="Drawer__backdrop" @click="close"></div>
    </div>
  </PreventBodyScroll>
</template>

<script>
import PreventBodyScroll from '@components/PreventBodyScroll';
import { BlockCollapse } from '@components/UI';

const productFilterData = window['productFilterData'];

export default {
  name: 'Drawer',

  components: {
    PreventBodyScroll,
  },

  props: {
    closeLabel: {
      default: '',
      type: String,
    },
    toggleSelector: {
      // Button selector. Will listen to click events.
      // TODO:  This should be replaced by an vue event bus method of some sort.
      //        ToogleSelector event listener can still work as a way to reach non-vue triggers (buttons outside the vue wrapper)
      default: '',
      type: String,
    },
    activeWatchSelector: {
      default: '',
      type: String,
    },

    showFilters: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      isOpen: false,
      isActive: true,
      activeWatchEl: null,
      scrollTop: 0,
      isScrolled: false,
      attributes:
        productFilterData !== null && productFilterData !== undefined
          ? Object.keys(productFilterData)
              .map(key =>
                Object.keys(productFilterData[key]).map(child => 'sheets.' + key + '.' + child),
              )
              .flat()
          : [],
    };
  },

  watch: {
    isActive(newStatus, oldStatus) {
      // When active changes?
      // Resize eventlistener?
    },
    scrollTop(top, lastTop) {
      if (top !== lastTop) {
        if (!this.isScrolled && top > 10) {
          this.isScrolled = true;
        } else if (this.isScrolled && top <= 10) {
          this.isScrolled = false;
        }
      }
    },
    isScrolled(scrolled, lastScrolled) {
      if (scrolled !== lastScrolled) this.$emit('scrolled', scrolled);
    },
    $route(to, from) {
      if (this.isActive && this.isOpen && to.name !== from.name) this.close();
    },
  },

  mounted() {
    this.addToogleButtonListeners();

    if (this.activeWatchSelector) {
      this.activeWatchEl = document.body.querySelector(this.activeWatchSelector);
      window.addEventListener('resize', this.onResize);
      this.onResize();
    }
  },

  destroyed() {
    // Event listenres cleanup
    this.removeToogleButtonListeners();
  },

  methods: {
    close() {
      this.isOpen = false;
    },

    open() {
      this.isOpen = true;
      this.isActive = true;

      this.focusFirstInput();
    },

    // Applies focus to the first input or select
    focusFirstInput() {
      const firstInput = this.$el.querySelector('input, select');
      if (firstInput !== null) {
        firstInput.focus();
      }
    },

    addToogleButtonListeners() {
      // Add eventlistners if element and selector is present
      if (this.toggleSelector !== '') {
        const toogleButtons = document.querySelector(this.toggleSelector);

        if (toogleButtons) {
          toogleButtons.addEventListener('click', this.open);
        }
      }
    },

    removeToogleButtonListeners() {
      // Remove listeners
      if (this.toggleSelector !== '') {
        const toogleButtons = document.querySelector(this.toggleSelector);

        if (toogleButtons) {
          toogleButtons.removeEventListener('click', this.open);
        }
      }
    },

    onResize() {
      this.activeWatchEl = document.body.querySelector(this.activeWatchSelector);

      if (this.activeWatchEl && this.activeWatchEl.offsetParent === null) {
        this.isActive = false;
      } else if (this.activeWatchEl) {
        this.isActive = true;
      }
    },
  },
};
</script>
