<template>
  <Lightbox
    ref="lightbox"
    :dark="dark"
    :locked="isWorking"
    :class="[
      'Modal',
      `Modal--${size}`,
      isWorking && 'Modal--working',
      !title && 'Modal--hideHeader',
    ]"
    @open="$emit('open')"
    @close="$emit('close')"
  >
    <template #default>
      <div class="Modal__content">
        <div v-if="isWorking" class="Modal__loadingBar" />
        <Close v-if="!isWorking" class="Modal__close" @click="close" />
        <div v-if="title" class="Modal__header">
          <h1 class="Modal__title">{{ title }}</h1>
        </div>
        <div class="Modal__body">
          <slot name="default" :setWorking="setWorking" :working="isWorking" />
        </div>
        <div v-if="hasControlsSlot" class="Modal__footer">
          <slot name="controls" :close="close" :setWorking="setWorking" :working="isWorking" />
        </div>
      </div>
    </template>
  </Lightbox>
</template>

<script>
import Lightbox from '@components/Lightbox';
import { Close } from '@components/Buttons';
import { Sizes } from '../../types';

export default {
  name: 'Modal',

  components: {
    Lightbox,
    Close,
  },

  props: {
    title: {
      type: String,
      default: '',
    },
    size: {
      type: String,
      default: Sizes.SMALL,
    },
    dark: {
      type: Boolean,
      default: false,
    },
    working: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      isWorking: false,
    };
  },
  watch: {
    working: {
      handler(to) {
        this.isWorking = to;
      },
      immediate: true,
    },
  },
  computed: {
    hasControlsSlot() {
      return !!this.$scopedSlots.controls;
    },
  },

  methods: {
    open() {
      this.$refs.lightbox.open();
    },
    close() {
      this.$refs.lightbox.close();
    },
    setWorking(working) {
      this.isWorking = !!working;
    },
  },
};
</script>

<style lang="scss">
.Modal a {
  color: $color-black;
}
</style>

<style lang="scss" scoped>
.Modal {
  .Modal__content {
    position: relative;
    display: flex;
    flex-direction: column;
    overflow: hidden;

    background-color: #ffffff;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
    border: 1px solid #cccccc;
    border-radius: $border-radius;
    color: $color-black;
    text-align: left;
  }

  &--auto .Modal__content {
    width: auto;
  }

  &--small .Modal__content {
    width: 480px;
  }

  &--large .Modal__content {
    width: 860px;
  }

  &--working {
    .Modal__content {
      pointer-events: none;
    }
    .Modal__header,
    .Modal__body,
    .Modal__footer {
      opacity: 0.3;
    }
  }

  .Modal__loadingBar {
    position: absolute;
    overflow: hidden;
    height: 5px;
    top: 0;
    left: 0;
    right: 0;
    &::after {
      content: '';
      position: absolute;
      width: 100%;
      height: 100%;
      background-color: $color-extra-info;
      left: -100%;
      animation: loading 1.5s infinite;
    }
  }

  .Modal__close {
    position: absolute;
    top: 10px;
    right: 10px;
  }

  .Modal__header {
    border-bottom: 1px solid $color-prim-grey;
    padding: 20px;
  }

  .Modal__title {
    font-size: 24px;
    font-weight: normal;
    margin: 0;
    color: black;
  }

  .Modal__body {
    min-height: 200px;
    padding: 20px;
  }

  &--hideHeader .Modal__body {
    padding-top: 60px;
  }

  .Modal__footer {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 10px;
    padding: 20px;
  }
}

@keyframes loading {
  100% {
    left: 100%;
  }
}
</style>
