<template>
  <div class="UserCardSkeleton">
    <SkeletonGraphic :width="50" :height="50" :borderRadius="5" />
    <div class="UserCardSkeleton__details">
      <SkeletonGraphic :width="80" :height="20" />
      <SkeletonGraphic :width="140" :height="18" />
    </div>
  </div>
</template>

<script>
import SkeletonGraphic from '../SkeletonGraphic';

export default {
  name: 'UserCardSkeleton',

  components: {
    SkeletonGraphic,
  },
};
</script>

<style lang="scss" scoped>
.UserCardSkeleton {
  display: flex;
  align-items: center;
  gap: 20px;
  min-width: 240px;

  .UserCardSkeleton__details {
    display: flex;
    flex-direction: column;
    gap: 2px;
  }
}
</style>
