import { Api, LegacyApi } from '..';

/**
 *
 * @param {string} productNode
 */
export function getByRef(refQuery) {
  return new Promise((resolve, reject) => {
    Api.post(`/products/GetByRef/`, {
      id: refQuery,
    })
      .then(response => {
        resolve(response.data.data);
      })
      .catch(error => {
        reject(error);
      });
  });
}

/**
 *
 * @param {string} productNode
 */
export function getStatusByProductNode(productNode) {
  return new Promise((resolve, reject) => {
    LegacyApi.get(`/products/getproductstatus?productNode=${productNode}`)
      .then(response => {
        resolve(response.data.data);
      })
      .catch(error => {
        reject(error.response.data);
      });
  });
}

/**
 *
 * @param {*} vehicleId
 * @param {*} itemNumber
 */
export function getStatusByVehicle(vehicleId, itemNumber) {
  return new Promise((resolve, reject) => {
    LegacyApi.get(`/products/getproductstatus?tid=${vehicleId}&itemNo=${itemNumber}`)
      .then(response => {
        resolve(response.data.data);
      })
      .catch(error => {
        reject(error.response.data);
      });
  });
}
