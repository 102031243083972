<template>
  <div>
    <Validator v-slot="{ valid, showErrors }">
      <Form
        class="SignupForm"
        :title="$t('account.signup.action')"
        @submit="valid ? handleCreate() : showErrors()"
        :locked="working"
      >
        <template #header>
          <StepProgressBar
            :alignment="StepAlignment.JUSTIFY"
            :size="stepSize"
            :activeStep="stepId"
            numbered
          >
            <Step :id="SignupStep.FORM" :title="$t('account.signup.steps.form')" />
            <Step :id="SignupStep.BRANCH" :title="$t('account.signup.steps.branch')" />
            <Step :id="SignupStep.COMPLETE" :title="$t('account.signup.steps.done')" />
          </StepProgressBar>
          <Alert v-if="error" :level="AlertLevel.ERROR" closeable>
            {{ $t('error_messages.try_again') }}
          </Alert>
        </template>
        <template #default>
          <Slides ref="slides" class="SignupForm__slides" @change="id => (slideId = id)">
            <Slide id="form_slide">
              <div class="SignupForm__inputs">
                <Input
                  id="firstname"
                  v-model="firstName"
                  name="first-name"
                  type="text"
                  :label="$t('person.first_name')"
                  :autocomplete="Autocompletes.NAME_FIRSTNAME"
                  auto-focus
                  required
                  class="LoginCard__input"
                />
                <Input
                  id="lastname"
                  v-model="lastName"
                  name="last-name"
                  type="text"
                  :label="$t('person.last_name')"
                  :autocomplete="Autocompletes.NAME_LASTNAME"
                  required
                  class="LoginCard__input"
                />
                <Input
                  id="email"
                  v-model="email"
                  name="email"
                  type="email"
                  :label="$t('email')"
                  :autocomplete="Autocompletes.USERNAME"
                  required
                  class="LoginCard__input"
                />
                <Input
                  id="repeat_email"
                  v-model="repeatEmail"
                  :disabled="!repeatEmailEnabled"
                  :pattern="email"
                  :patternErrorMessage="$t('input.invalid_email_no_match')"
                  name="repeat_email"
                  autocomplete="off"
                  type="email"
                  :label="$t('input.email.repeat')"
                  required
                  class="LoginCard__input"
                />
                <Input
                  id="mobile"
                  v-model="mobile"
                  name="mobile"
                  type="tel"
                  :label="$t('person.mobile_phone_number')"
                  :autocomplete="Autocompletes.TEL_FULL"
                  required
                  :maxlength="10"
                  class="LoginCard__input"
                />
                <Input
                  id="password"
                  v-model="password"
                  name="password"
                  type="password"
                  :label="$t('password')"
                  :autocomplete="Autocompletes.PASSWORD_NEW"
                  required
                  class="LoginCard__input"
                  :minlength="5"
                />
                <ConditionsCheckbox
                  id="conditionsCheck"
                  required
                  class="SignupForm__conditionsCheckbox"
                />
                <NewsletterCheckbox
                  id="newsletterCheck"
                  class="SignupForm__newsletterCheckbox"
                  v-model="newsletter"
                />
                <Recaptcha id="recaptcha" />
              </div>
            </Slide>

            <Slide id="branch_slide">
              <div class="SignupForm__storefinder">
                <p>{{ $t('account.auth.choose_your_branch') }}</p>
                <StoreFinder scrollable :onUpdate="setBranch" ignoreCookie />
              </div>
            </Slide>
          </Slides>
        </template>

        <template #footer>
          <Button
            v-if="slideId === 'form_slide'"
            :size="ButtonSize.FULL"
            @click.prevent="valid ? next() : showErrors()"
          >
            {{ $t('account.signup.controls.next') }}
          </Button>
          <Button v-else :size="ButtonSize.FULL" :working="working">
            {{ $t('account.signup.action') }}
          </Button>
        </template>
      </Form>
    </Validator>
  </div>
</template>

<script>
import { postCreateAccount } from '@services/thansen/account';
import { postSubscribers } from '@services/thansen/mailinglist';

import Validator from '@containers/Validator';
import Form from '@layouts/Form';
import { StepProgressBar, Step, StepAlignment, StepSize } from '@components/StepProgressBar';
import { Slides, Slide } from '@components/Slides';
import Alert, { AlertLevel } from '@components/Alert';
import { ConditionsCheckbox, NewsletterCheckbox } from '@components/Checkbox';
import StoreFinder from '@components/StoreFinder';
import { Button, sizes as ButtonSize } from '@components/Buttons';
import Input, { Autocompletes } from '@scenes/SelfserviceScene/components/Input';
import Recaptcha from './components/Recaptcha';

const SignupStep = {
  FORM: 'form_step',
  BRANCH: 'branch_step',
  COMPLETE: 'complete_step',
};

export default {
  name: 'SignupForm',

  components: {
    Validator,
    Form,
    StepProgressBar,
    Step,
    Slides,
    Slide,
    Alert,
    ConditionsCheckbox,
    NewsletterCheckbox,
    StoreFinder,
    Button,
    Input,
    Recaptcha,
  },

  enums: {
    StepAlignment,
    AlertLevel,
    Autocompletes,
    ButtonSize,
    SignupStep,
  },

  data() {
    return {
      firstName: '',
      lastName: '',
      email: '',
      repeatEmail: '',
      mobile: '',
      password: '',
      branch: '',
      newsletter: false,

      slideId: '',
      stepId: SignupStep.FORM,

      repeatEmailEnabled: false,
      working: false,
      error: false,
    };
  },

  watch: {
    email(to) {
      if (!this.repeatEmailEnabled && to) {
        setTimeout(() => (this.repeatEmailEnabled = true), 200);
      }
    },
  },

  computed: {
    stepSize() {
      switch (this.$mq) {
        case 'mobileS':
          return StepSize.SMALL;
        case 'mobileM':
          return StepSize.MEDIUM;
        default:
          return StepSize.LARGE;
      }
    },
  },

  methods: {
    next() {
      this.$refs.slides.next();
      this.stepId = SignupStep.BRANCH;
      if (this.newsletter) postSubscribers(this.email);
    },
    setBranch(selectedStore) {
      this.stepId = selectedStore ? SignupStep.COMPLETE : SignupStep.BRANCH;
      this.branch = selectedStore ? selectedStore.slug : '';
    },
    handleCreate() {
      this.stepId = SignupStep.COMPLETE;
      this.working = true;
      this.error = false;
      postCreateAccount({
        first_name: this.firstName,
        last_name: this.lastName,
        email_address: this.email,
        phone_number: this.mobile,
        password: this.password,
        favorite_branch: this.branch,
      })
        .then(data => {
          this.$emit('submitted');
        })
        .catch(error => {
          this.working = false;
          this.error = true;
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.SignupForm {
  a {
    color: black;
  }
  .SignupForm__slides {
    padding-top: 20px;
  }

  .SignupForm__inputs {
    display: flex;
    gap: 1em;
    flex-direction: column;
  }

  .SignupForm__storefinder {
    height: 350px;
    overflow: hidden;
    padding-bottom: 30px;

    p {
      display: inline-block;
      font-weight: normal;
      margin-bottom: 7px !important;
      color: #000000;
      font-size: 15px;
    }
  }

  .SignupForm__conditionsCheckbox,
  .SignupForm__newsletterCheckbox {
    @include maxBreakpoint($tablet-numeric) {
      font-size: 13px;
    }
  }
}
</style>
