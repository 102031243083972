<template>
  <div class="TopbarSearch">
    <div class="TopbarSearch__content">
      <div class="TopbarSearch__input">
        <div class="Input Input--search Input--searchButton Input--medium Input--grey Input--tight">
          <div class="Input__fieldWrap">
            <button class="Input__searchButton"><i class="far fa-search"></i></button>
            <form autocomplete="off" method="post" action="">
              <input
                :placeholder="$t('search-placeholder')"
                type="search"
                name="search"
                autocomplete="off"
                autocorrect="off"
                autocapitalize="none"
                spellcheck="false"
                role="combobox"
              />
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style></style>
