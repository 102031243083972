// Bruges ikke i øjeblikket, men tanken er vores generalle passwords kan valideres via denne function

export const isPassword = n => {
  let status = true;

  // At least eight characters
  if (!/.{8,}/.test(n)) {
    status = false;
  }

  // At least one uppercase letter.
  if (!/.*[A-Z].*/.test(n)) {
    status = false;
  }

  // At least one lowercase letter
  if (!/.*[a-z].*/.test(n)) {
    status = false;
  }

  // At least one number
  if (!/.*[0-9].*/.test(n)) {
    status = false;
  }

  return status;
};
