<template>
  <div class="CreateUserBox">
    <BoxLayout :title="$t('account.signup.title')">
      <template #description> </template>
      <template #default>
        <ul class="CreateUserBox__list">
          <FeatureItem :title="$t('account.signup.message.easy_ordering')">
            {{ $t('account.signup.message.easy_ordering--detailed') }}
          </FeatureItem>
          <FeatureItem
            v-if="$isEnabled(Features.QR_SCANNERS)"
            :title="$t('account.signup.message.skip_line')"
          >
            {{ $t('account.signup.message.skip_line--detailed') }}
          </FeatureItem>
          <FeatureItem :title="$t('account.signup.message.track_delivery')">
            {{ $t('account.signup.message.track_delivery--detailed') }}
          </FeatureItem>
          <FeatureItem :title="$t('account.signup.message.order_history')">
            {{ $t('account.signup.message.order_history--detailed') }}
          </FeatureItem>
          <FeatureItem :title="$t('account.signup.message.easy_booking')">
            {{ $t('account.signup.message.easy_booking--detailed') }}
          </FeatureItem>
          <FeatureItem
            v-if="$isEnabled(Features.FIREWORKS_CLUB_BENEFITS)"
            :title="$t('account.signup.message.firework')"
            class="CreateUserBox__fireworkFeatureItem"
          >
            {{ $t('account.signup.message.firework--detailed') }}
          </FeatureItem>
        </ul>
      </template>
      <template v-if="!hideSignup" #controls>
        <Button tag="a" type="light" @click="$emit('clickOnSignup')">
          {{ $t('account.signup.action') }}
        </Button>
      </template>
    </BoxLayout>
    <a v-if="businessHref" :href="businessHref" class="CreateUserBox__bottomLink">
      {{ $t('account.signup.controls.business_customer') }}
    </a>
  </div>
</template>

<script>
import BoxLayout from './layout/BoxLayout';
import { Button } from '@components/Buttons';
import FeatureItem from './components/FeatureItem';
import Features from '@types/Features';
export default {
  name: 'CreateUserBox',

  components: {
    BoxLayout,
    Button,
    FeatureItem,
  },

  enums: {
    Features,
  },

  props: {
    hideSignup: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    businessHref() {
      if (this.$config.DOMAIN_CODE === 'THG') {
        return '/erhverv';
      } else if (this.$config.DOMAIN_CODE === 'TNO') {
        return '/kundeservice/bedriftsavtale/n1134439';
      }
      return null;
    },
  },
};
</script>

<style lang="scss" scoped>
.CreateUserBox {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  background-color: $color-prim-grey-dark;
  background-color: var(--color-prim);

  .CreateUserBox__description {
    text-align: center;
  }

  .CreateUserBox__list {
    display: flex;
    flex-direction: column;
    gap: 15px;
    display: flex;
    margin: 0 auto;
  }

  .CreateUserBox__fireworkFeatureItem {
    color: $color-price-sale;
  }

  .CreateUserBox__fireworkText {
    margin-top: 20px;
  }

  .CreateUserBox__bottomLink {
    display: block;
    background: $color-prim-blue;
    width: 100%;
    padding: 20px;
    text-align: center;
    color: white;
    font-size: 18px;
  }
}
</style>
