<template>
  <div class="DetailsCardSkeleton">
    <div class="DetailsCardSkeleton__body">
      <DetailsSkeleton :items="details" :columnSize="Sizes.SMALL" />
    </div>
  </div>
</template>

<script>
import DetailsSkeleton, { Sizes } from '../DetailsSkeleton';

export default {
  name: 'DetailsCardSkeleton',

  components: {
    DetailsSkeleton,
  },

  enums: {
    Sizes,
  },

  props: {
    details: {
      type: Number,
      default: undefined,
    },
  },

  computed: {
    hasHeaderSlot() {
      return !!this.$scopedSlots.header;
    },
    hasControlsSlot() {
      return !!this.$scopedSlots.controls;
    },
  },
};
</script>

<style lang="scss" scoped>
.DetailsCardSkeleton {
  display: flex;
  flex-direction: column;
  width: 100%;
  border: 1px solid $color-prim-grey;
  border-radius: $border-radius;
  padding: 20px;
  background: #ffffff;
  gap: 20px;

  .DetailsCardSkeleton__header {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
  }

  .DetailsCardSkeleton__body {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }

  .DetailsCardSkeleton__footer {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    border-top: 1px solid $color-prim-grey;
    gap: 10px;
    padding-top: 10px;
  }
}
</style>
