<template>
  <ResizeObserver
    :class="['Recaptcha', compact && 'Recaptcha--compact', showInvalid && 'Recaptcha--invalid']"
    @resize="size => (width = size.width)"
  >
    <vue-recaptcha
      v-if="rendered"
      :sitekey="$config.RECAPTCHA_SITE_KEY"
      loadRecaptchaScript
      class="Recaptcha__recaptcha"
      @verify="validate"
      @expired="invalidate"
      @error="onError"
    />
    <span v-if="showInvalid" class="Recaptcha__isRequiredMessage">
      {{ $t('recaptcha.message.is_required') }}
    </span>
  </ResizeObserver>
</template>

<script>
import VueRecaptcha from 'vue-recaptcha';
import ResizeObserver from '@components/ResizeObserver';

export default {
  name: 'Recaptcha',

  components: {
    VueRecaptcha,
    ResizeObserver,
  },

  props: {
    id: {
      type: String,
      default: '',
    },
  },

  data() {
    return {
      isValid: false,
      showInvalid: false,
      rendered: true,
      width: 0,
    };
  },

  computed: {
    compact() {
      return this.width < 306;
    },
  },

  watch: {
    isValid(to, from) {
      if (to && !from) this.showInvalid = false;
    },
  },

  created() {
    this.validatorId = this.id || this._uid;
    if (this.validator) {
      this.validator.addItem(this.validatorId, false, () => {
        if (!this.isValid) this.showInvalid = true;
      });
    }
  },

  beforeDestroy() {
    if (this.validator) this.validator.removeItem(this.validatorId);
  },

  methods: {
    onError() {
      // TODO - Handle error
    },
    validate() {
      this.isValid = true;
      if (this.validator) this.validator.updateItem(this.validatorId, this.isValid);
    },
    invalidate() {
      this.isValid = false;
      if (this.validator) this.validator.updateItem(this.validatorId, this.isValid);
    },
  },

  inject: {
    validator: {
      default() {
        return; // having a default if no validator parent is avaliable
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.Recaptcha {
  width: 100%;

  &--invalid {
    border: 1px solid $color-extra-error;
    border-radius: $border-radius;
    padding: 5px;
  }

  &--compact .Recaptcha__recaptcha {
    transform: scale(0.8);
    transform-origin: 0;
  }

  .Recaptcha__isRequiredMessage {
    color: $color-extra-error;
  }
}
</style>
