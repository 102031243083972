<template>
  <div class="StoreSelect">
    <ListTransition class="StoreSelect__list">
      <li v-for="store in sortedStores" :key="store.id" class="StoreSelect__store">
        <StoreOption
          :name="store.name"
          :address="store.address"
          :selected="selectedStore && selectedStore.id === store.id"
          :alertMessage="showAlerts ? getAlertMessage(store) : ''"
          @click="onStoreClick(store)"
        />
        <slot name="default" />
      </li>
    </ListTransition>
  </div>
</template>

<script>
import ListTransition from '@transitions/List';
import StoreOption from './components/StoreOption';

export default {
  name: 'StoreSelect',

  components: {
    ListTransition,
    StoreOption,
  },

  props: {
    stores: {
      type: Array,
      default() {
        return [];
      },
    },
    selected: {
      type: Object,
      default: null,
    },
    preselect: {
      type: Object,
      default: null,
    },
    showAlerts: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      selectedStore: null,
      preselectActive: true,
    };
  },

  computed: {
    sortedStores() {
      if (this.selectedStore) {
        const index = this.stores.findIndex(store => store.id === this.selectedStore.id);

        if (index !== -1) {
          const newStores = [...this.stores];
          newStores.unshift(newStores.splice(index, 1)[0]);
          return newStores;
        }
      }
      return this.stores;
    },
    preselectStore() {
      if (this.preselectActive && this.preselect && this.preselect.slug) {
        return this.stores.find(store => store.slug === this.preselect.slug);
      } else {
        return null;
      }
    },
  },

  watch: {
    preselectStore: {
      handler() {
        if (this.preselectActive && this.preselectStore) {
          this.selectedStore = this.preselectStore;
        }
      },
      deep: true,
      immediate: true,
    },
  },

  methods: {
    onStoreClick(store) {
      this.preselectActive = false;
      if (this.selectedStore && this.selectedStore.id === store.id) {
        this.selectedStore = null;
      } else {
        this.selectedStore = store;
      }
      this.$emit('update:selected', this.selectedStore);
    },
    getAlertMessage(store) {
      const hasMountingService = store.services.includes('car_lift');
      if (!hasMountingService) return 'Kun udlevering (ingen montage)';
      return '';
    },
  },
};
</script>

<style lang="scss" scoped>
.StoreSelect {
  color: black;

  .StoreSelect__list {
    margin: 0;
    padding: 0;
  }
  .StoreSelect__store {
    margin: 2px 0;
    background-color: #ffffff;
  }
}
</style>
