<template>
  <div class="NewsletterCheckbox">
    <Checkbox :id="id" :checked="checked" @change="e => $emit('change', e)">
      <div class="NewsletterCheckbox__label">
        {{ $t('account.auth.subscribe_to_newsletter') }}
      </div>
      <i18n path="account.auth.accept_newsletter" tag="div">
        <template #terms>
          <a
            href="/kundeservice/handelsbetingelser-og-privatliv/betingelser-thansens-nyhedsbrev/n-234278138/"
            target="_blank"
            :title="$t('account.auth.accept.newsletter')"
            @click.prevent="$refs.newsletterTermsModal.open()"
            >{{ $t('account.auth.requirements').toLowerCase() }}</a
          >
        </template>
        <template #privacyPolicy>
          <a
            href="/kundeservice/handelsbetingelser-og-privatliv/privatlivspolitik/n-236568175/"
            target="_blank"
            :title="$t('account.auth.accept.privacy_policy')"
            @click.prevent="$refs.privacyModal.open()"
            >{{ $t('account.auth.accept.privacy_policy').toLowerCase() }}</a
          >
        </template>
      </i18n>
    </Checkbox>
    <LabelModal ref="privacyModal" label="3605" />
    <LabelModal ref="newsletterTermsModal" label="3688" />
  </div>
</template>

<script>
import { LabelModal } from '@components/Modal';
import Checkbox from '../Checkbox';

export default {
  name: 'NewsletterCheckbox',

  components: {
    LabelModal,
    Checkbox,
  },

  model: {
    prop: 'checked',
    event: 'change',
  },

  props: {
    id: {
      type: String,
      required: true,
    },
    checked: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss" scoped>
.NewsletterCheckbox {
  .NewsletterCheckbox__label {
    font-weight: bold;
  }
}
</style>
