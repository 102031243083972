<template>
  <div
    v-if="showFallback || (status !== Status.NO_SOURCE && status !== Status.ERROR)"
    v-observe-visibility="observeVisibility ? visibilityChanged : false"
    :class="[
      'ImageComponent',
      status === Status.COMPLETE && 'ImageComponent--loaded',
      aspectRatio && 'ImageComponent--fixedAspectRatio',
      fit && 'ImageComponent--fit' + fit,
      clickable && 'ImageComponent--clickable',
    ]"
    :style="`padding-bottom: ${aspectRatioPercentage}%;`"
    @click="clickable && $emit('click')"
  >
    <transition>
      <i v-if="status === Status.NO_SOURCE" class="ImageComponent__inner far fa-image" />
      <SkeletonGraphic v-else-if="status === Status.LOADING" class="ImageComponent__inner" />
      <img
        v-else-if="status === Status.COMPLETE"
        :src="src"
        :alt="alt"
        class="ImageComponent__inner"
      />
      <i
        v-else-if="status === Status.ERROR"
        class="ImageComponent__inner far fa-exclamation-circle"
      />
    </transition>
  </div>
</template>

<script>
import { SkeletonGraphic } from '@components/Skeleton';

const Status = {
  NO_SOURCE: 'NO_SOURCE',
  PENDING: 'PENDING',
  LOADING: 'LOADING',
  COMPLETE: 'COMPLETE',
  ERROR: 'ERROR',
};

export const Preload = {
  AUTO: 'AUTO',
  NONE: 'NONE',
  TRUE: 'TRUE',
};

export default {
  name: 'ImageComponent',

  components: {
    SkeletonGraphic,
  },

  enums: {
    Status,
  },

  props: {
    src: {
      type: String,
      default: '',
    },
    alt: {
      type: String,
      required: true,
    },
    showFallback: {
      type: Boolean,
      default: true,
    },
    aspectRatio: {
      type: String,
      default: '',
      validator(value) {
        return !value || value.includes(':');
      },
    },
    preload: {
      type: [Boolean, String],
      default: Preload.AUTO,
    },
    fit: {
      type: String,
      default: '',
    },
    clickable: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      status: '',
    };
  },

  computed: {
    observeVisibility() {
      return this.preload === Preload.AUTO && this.status === Status.PENDING;
    },
    aspectRatioPercentage() {
      const terms = this.aspectRatio.split(':');
      return (terms[1] / terms[0]) * 100;
    },
  },

  mounted() {
    this.tryLoad();
  },

  methods: {
    load(src) {
      if (src) {
        this.src = src;
        this.tryLoad();
      } else if (this.status === Status.PENDING) {
        this.loadImage();
      }
    },
    visibilityChanged(isVisible) {
      if (isVisible) this.loadImage();
    },
    tryLoad() {
      if (!this.src) {
        this.status = Status.NO_SOURCE;
      } else if (
        this.preload === Preload.AUTO ||
        this.preload === Preload.NONE ||
        this.preload === false
      ) {
        this.status = Status.PENDING;
      } else if (this.preload === Preload.TRUE || this.preload === true) {
        this.loadImage();
      }
    },
    loadImage() {
      this.status = Status.LOADING;
      const image = new Image();
      image.onload = () => this.onLoad();
      image.onerror = () => this.onError();
      image.src = this.src;
    },
    onLoad() {
      this.status = Status.COMPLETE;
      this.$emit('load');
    },
    onError() {
      this.status = Status.ERROR;
      this.$emit('error');
    },
  },
};
</script>

<style lang="scss" scoped>
.ImageComponent {
  width: 100%;
  position: relative;
  font-size: 35px;
  color: $color-prim-grey;

  &--clickable {
    cursor: pointer;
  }

  .v-enter-active {
    transition: opacity 100ms linear;
  }

  .v-enter {
    opacity: 0;
  }

  &:not(.ImageComponent--loaded) {
    padding-bottom: 56.25%;
  }

  &--fixedAspectRatio,
  &:not(.ImageComponent--loaded) {
    .ImageComponent__inner {
      display: flex;
      justify-content: center;
      align-items: center;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      width: 100%;
      height: 100%;
    }
  }

  &--fitfill .ImageComponent__inner {
    object-fit: fill;
  }

  &--fitcontain .ImageComponent__inner {
    object-fit: contain;
  }

  &--fitcover .ImageComponent__inner {
    object-fit: cover;
  }

  &--fitnone .ImageComponent__inner {
    object-fit: none;
  }

  &--fitscale-down .ImageComponent__inner {
    object-fit: scale-down;
  }
}
</style>
