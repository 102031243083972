<template>
  <div
    :class="[
      'OpeningHoursStatus',
      status === Status.OPEN && 'OpeningHoursStatus--open',
      (status === Status.OPENS_SOON || status === Status.CLOSING_SOON) &&
        'OpeningHoursStatus--soon',
    ]"
  >
    <i class="far fa-clock" />
    <span v-if="status === Status.BEFORE_OPEN">
      {{ `${$t('opening_hours.status.open')} ${timeToday}` }}
    </span>
    <span v-else-if="status === Status.OPENS_SOON">
      <span class="OpeningHoursStatus__status">{{ $t('opening_hours.status.opens_soon') }}</span>
      {{ timeToday }}
    </span>
    <i18n v-else-if="status === Status.OPEN" path="opening_hours.status_until_time">
      <template #status>
        <span class="OpeningHoursStatus__status">{{ $t('opening_hours.status.open') }}</span>
      </template>
      <template #time>{{ specificationToday.closes }}</template>
    </i18n>
    <span v-else-if="status === Status.CLOSING_SOON">
      <span class="OpeningHoursStatus__status">{{ $t('opening_hours.status.closing_soon') }}</span>
      {{ specificationToday.closes }}
    </span>
    <template v-else-if="status === Status.CLOSED">
      <span v-if="closedAllWeek">
        {{ $t('opening_hours.status.closed_today') }}
      </span>
      <span v-else>
        {{ `${$t('opening_hours.status.open')} ${translateDay(nextOpenDay)} ${timeNextOpenDay}` }}
      </span>
    </template>
  </div>
</template>

<script>
const weekdays = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];

const Status = {
  BEFORE_OPEN: 'BEFORE_OPEN',
  OPENS_SOON: 'OPENS_SOON',
  OPEN: 'OPEN',
  CLOSING_SOON: 'CLOSING_SOON',
  CLOSED: 'CLOSED',
};

export default {
  name: 'OpeningHoursStatus',

  props: {
    openingHours: {
      type: Array,
      required: true,
    },
    refreshInterval: {
      type: Number,
      default: 1000,
    },
    threshold: {
      type: Number,
      default: 30,
    },
  },

  enums: {
    Status,
  },

  data() {
    return {
      dateNow: Date.now(),
      timeNow: null,
      timeThreshold: null,
      weekday: null,
    };
  },

  computed: {
    specificationToday() {
      return this.getSpecification(this.weekday);
    },
    nextOpenDay() {
      for (let i = 0; i < 6; i++) {
        const day = (i + this.weekday + 1) % 7;
        const specification = this.getSpecification(day);
        if (!this.isClosed(specification)) return day;
      }
      return null;
    },
    timeToday() {
      return this.specificationToday.opens + ' – ' + this.specificationToday.closes;
    },
    timeNextOpenDay() {
      if (this.nextOpenDay === null) return null;
      const specification = this.getSpecification(this.nextOpenDay);
      return specification.opens + ' – ' + specification.closes;
    },
    closedAllWeek() {
      return this.status === Status.CLOSED && this.nextOpenDay === null;
    },
    status() {
      if (this.timeThreshold < this.specificationToday.opens) {
        return Status.BEFORE_OPEN;
      } else if (
        this.timeNow < this.specificationToday.opens &&
        this.timeThreshold >= this.specificationToday.opens
      ) {
        return Status.OPENS_SOON;
      } else if (
        this.timeNow >= this.specificationToday.opens &&
        this.timeThreshold < this.specificationToday.closes
      ) {
        return Status.OPEN;
      } else if (
        this.timeNow < this.specificationToday.closes &&
        this.timeThreshold >= this.specificationToday.closes
      ) {
        return Status.CLOSING_SOON;
      }
      return Status.CLOSED;
    },
  },

  watch: {
    dateNow: {
      immediate: true,
      handler(to) {
        const now = new Date(to);
        const _weekday = now.getDay();
        if (_weekday !== this.weekday) this.weekday = _weekday;
        const _timeNow = this.dateToTime(now);
        if (_timeNow !== this.timeNow) {
          now.setMinutes(now.getMinutes() + this.threshold);
          const _timeThreshold = this.dateToTime(now);
          this.timeNow = _timeNow;
          this.timeThreshold = _timeThreshold;
        }
      },
    },
  },

  mounted() {
    this.updateInterval = setInterval(() => (this.dateNow = Date.now()), this.refreshInterval);
  },

  beforeDestroy() {
    clearInterval(this.updateInterval);
  },

  methods: {
    getSpecification(day) {
      const specification = this.openingHours.find(item =>
        item.days_of_week.includes(weekdays[day]),
      );
      return specification ?? { opens: '00:00', closes: '00:00' };
    },
    isClosed(specification) {
      return specification.opens === '00:00' && specification.closes === '00:00';
    },
    dateToTime(date) {
      return (
        (date.getHours() < 10 ? '0' : '') +
        date.getHours() +
        ':' +
        (date.getMinutes() < 10 ? '0' : '') +
        date.getMinutes()
      );
    },
    translateDay(day) {
      if (day === 0) return this.$t('sunday');
      else if (day === 1) return this.$t('monday');
      else if (day === 2) return this.$t('tuesday');
      else if (day === 3) return this.$t('wednesday');
      else if (day === 4) return this.$t('thursday');
      else if (day === 5) return this.$t('friday');
      else if (day === 6) return this.$t('saturday');
      return '';
    },
  },
};
</script>

<style lang="scss" scoped>
.OpeningHoursStatus {
  display: flex;
  align-items: center;
  gap: 5px;
  color: $color-prim-grey-dark;

  &--open .OpeningHoursStatus__status {
    color: $color-extra-success;
  }

  &--soon .OpeningHoursStatus__status {
    color: $color-extra-warning;
  }
}
</style>
