<template>
  <div
    :class="[
      'Icon',
      status === Levels.COMPLETE && 'Icon--success',
      status === Levels.ERROR && 'Icon--error',
    ]"
  >
    <!--<FadeTransition :duration="0.3" :delay="0">-->
    <i :class="setIconClass.icon" :key="setIconClass.key"></i>
    <!--</FadeTransition>-->
  </div>
</template>

<script>
import FadeTransition from '@transitions/Fade';
import { LoginStatus } from '@types/User';
import { gsap } from 'gsap/all';

const Icons = {
  TIMES: 'TIMES',
  DEFAULT: 'DEFAULT',
  ENVOLOPE: 'ENVOLOPE',
  SECRET: 'SECRET',
  CHECK: 'CHECK',
  SPINNER: 'SPINNER',
  CREATE: 'CREATE',
};
const Levels = {
  IDLE: 'IDLE',
  COMPLETE: 'COMPLETE',
  ERROR: 'ERROR',
};

export { Icons };
export default {
  name: 'Icon',
  components: {
    FadeTransition,
  },
  enums: {
    LoginStatus,
    Icons,
    Levels,
  },
  props: {
    status: {
      type: String,
      default: '',
    },
    icon: {
      type: String,
      default: 'LOGIN',
    },
  },

  data() {
    return {};
  },

  computed: {
    setIconClass() {
      switch (this.icon) {
        case 'DEFAULT':
          return { icon: 'fal fa-key', key: 1 };
          break;
        case 'TIMES':
          return { icon: 'fal fa-times', key: 2 };
          break;
        case 'ENVOLOPE':
          return { icon: 'fal fa-envelope', key: 3 };
          break;
        case 'SECRET':
          return { icon: 'fal fa-user-secret', key: 4 };
          break;
        case 'CHECK':
          return { icon: 'fal fa-check', key: 5 };
          break;
        case 'SPINNER':
          return { icon: 'fal fa-spinner-third fa-spin', key: 6 };
          break;
        case 'CREATE':
          return { icon: 'fal fa-user-plus', key: 7 };
          break;

        default:
          return { icon: 'fal fa-key', key: 0 };
          break;
      }
    },
  },

  methods: {
    animateOnIconChange(element, delay, scaleUp) {
      gsap.to(element, 0.2, { scale: scaleUp });
      gsap.to(element, 0.2, { delay: delay, scale: 1 });
    },

    animateOnIconError(element, delay, scaleUp) {
      this.animateOnIconChange(element, delay, scaleUp);
    },
  },

  watch: {
    mode(to, from) {
      console.log(to);
      if (to !== from) {
        this.animateOnIconChange(this.$el, 0.9, 1.2);
      }
    },

    status(to, from) {
      if (to !== from) {
        if (to == this.Levels.ERROR) {
          this.animateOnIconError(this.$el, 0.2, 1.2);
        } else {
          this.animateOnIconChange(this.$el, 0.2, 1.2);
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.Icon {
  position: absolute;
  top: -45px;
  left: 50%;
  transform: translateX(-40px);
  width: 80px;
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  border-radius: 50%;
  background: #00529c;
  color: #fff;
  box-shadow: 0px 3px 5px 0px rgba(0, 0, 0, 0.2);
  transition: background-color 200ms ease;

  i {
    font-size: 32px;
    transition: opacity 500ms ease;
  }

  &.Icon--success {
    background-color: #5cb85c;
  }
  &.Icon--error {
    background-color: #d02828;
  }
}
</style>
