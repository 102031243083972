export const Mutation = {
  SET_DIMENSIONS: 'SET_DIMENSIONS 🚗',
  CLEAR_FILTER: 'CLEAR_FILTER 🚗',
  CLEAR_ALL: 'CLEAR_ALL 🚗',
  SET_BRANDS: 'SET_BRANDS 🚗',
  UPDATE_BRANDS: 'UPDATE_BRANDS 🚗',
  SET_COUNT: 'SET_COUNT 🚗',
  SET_FILTRED_BRANDS: 'SET_FILTRED_BRANDS 🚗',
  SET_SSR_CHECKED: 'SET_SSR_CHECKED 🚗',
  SET_OFFER_CHECKED: 'SET_OFFER_CHECKED 🚗',
};

export const Actions = {};
