<template>
  <div :class="['ProgressOverlay', progress ? 'is-active' : undefined].join(' ')">
    <div :class="['ProgressOverlay__content', progress && hideContentOnProgress && 'HiddenLayout']">
      <slot name="default"></slot>
    </div>
    <transition name="fade">
      <div v-if="progress" class="ProgressOverlay__backdrop"></div>
    </transition>

    <div v-if="progress" class="ProgressOverlay__spinner">
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100" class="Spinner">
        <circle
          xmlns="http://www.w3.org/2000/svg"
          cx="50"
          cy="50"
          fill="none"
          stroke-width="4"
          r="30"
          stroke-dasharray="70"
        ></circle>
      </svg>
    </div>
  </div>
</template>

<script>
import { position } from './types';

export default {
  name: 'ProgressOverlay',

  props: {
    progress: {
      type: Boolean,
      default: false,
    },
    messagePosition: {
      type: String,
      default: position.CENTER,
    },
    hideContentOnProgress: {
      type: Boolean,
      default: false,
      required: false,
    },
  },

  data() {
    return {
      position,
    };
  },
};
</script>

<style lang="scss" scoped>
@keyframes spinnerRotation {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
    opacity: 1;
  }

  50% {
    opacity: 0.4;
  }

  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
    opacity: 1;
  }
}

.HiddenLayout {
  opacity: 0;
}

.Spinner {
  width: 50px;
  height: 50px;
  display: inline-block;
  vertical-align: middle;
  stroke: #2d4f8e;
  position: relative;
  circle {
    stroke: inherit;
    animation: spinnerRotation 0.8s linear infinite;
    transform-origin: center;
    transform-box: fill-box;
    will-change: transform;
  }
}
.ProgressOverlay {
  position: relative;

  .ProgressOverlay__spinner {
    z-index: 61;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  .ProgressOverlay__content {
    display: block;
    z-index: 59;
    //filter: blur(0px); // overflow position fixed bug
    transition: filter 300ms ease-out;
  }
  &.is-active .ProgressOverlay__content {
    filter: blur(1px);
  }
  .ProgressOverlay__backdrop {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 60;
    background: rgba(255, 255, 255, 0.7);
  }

  .fade-enter-active,
  .fade-leave-active {
    transition: opacity 0.5s;
  }
  .fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
    opacity: 0;
  }
}
</style>
