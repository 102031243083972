<template>
  <div :class="['Address', forceWrap && 'Address--forceWrap']">
    <span v-if="forceWrap" class="Address__line"> {{ addressline }}</span>
    <span v-else class="Address__line"> {{ addressline }}, </span>
    <span class="Address__line">{{ data.zip_code || data.postal_code }} {{ data.city }}</span>
    <span class="Address__country" v-if="showCountry && data.country">{{ data.country }}</span>
  </div>
</template>

<script>
export default {
  name: 'Address',

  props: {
    data: {
      type: Object,
      required: true,
    },
    showCountry: {
      type: Boolean,
      default: false,
    },
    forceWrap: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    addressline() {
      const addressline = this.data.addressline1 || this.data.address || this.data.street_address;
      return addressline.trim();
    },
  },
};
</script>

<style lang="scss" scoped>
.Address {
  display: flex;
  align-items: center;
  flex-wrap: wrap;

  .Address__line {
    margin-right: 0.2em;
  }

  &--forceWrap .Address__line {
    flex-basis: 100%;
  }

  .Address__country {
    margin-right: 0.2em;
    flex-basis: 100%;
  }
}
</style>
