import { mapState, mapActions } from 'vuex';
import StoresModule, { Actions } from '@store/modules/stores';

export default {
  name: 'StoresContainer',

  computed: {
    ...mapState('stores', ['status', 'stores', 'searchPoint']),
  },

  methods: {
    ...mapActions('stores', {
      fetchStores: Actions.FETCH_STORES,
      search: Actions.SEARCH,
    }),
  },

  created() {
    if (!this.$store.hasModule('stores')) {
      this.$store.registerModule('stores', StoresModule);
      this.fetchStores();
    }
  },

  render() {
    return this.$scopedSlots.default({
      status: this.status,
      stores: this.stores,
      searchPoint: this.searchPoint,
      search: this.search,
    });
  },

  provide() {
    return {
      storesContainer: this,
    };
  },
};
