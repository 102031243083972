<template>
  <ul class="OpeningHoursWeek">
    <li v-for="(group, index) in openingHoursGroups" :key="index" class="OpeningHoursWeek__group">
      <span v-if="group.from === group.to">{{ group.from }}</span>
      <span v-else>{{ group.from }} - {{ group.to }}</span>
      <span v-if="group.opens === '00:00' && group.closes === '00:00'">
        {{ $t('opening_hours.status.closed') }}
      </span>
      <span v-else>{{ group.opens }} – {{ group.closes }}</span>
    </li>
  </ul>
</template>

<script>
const weekdays = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];

export default {
  name: 'OpeningHoursWeek',

  props: {
    openingHours: {
      type: Array,
      required: false,
    },
  },

  computed: {
    openingHoursGroups() {
      // Puts monday into the first group
      const monday = 1;
      const mondaySpecification = this.getSpecification(monday);
      const groups = [
        {
          from: this.translateDay(monday),
          to: this.translateDay(monday),
          opens: mondaySpecification.opens,
          closes: mondaySpecification.closes,
        },
      ];
      // Puts the rest of the weekdays into groups
      for (let i = 0; i < 6; i++) {
        const day = (i + 2) % 7;
        const specification = this.getSpecification(day);
        const nextGroup = groups[groups.length - 1];
        if (nextGroup.opens === specification.opens && nextGroup.closes === specification.closes) {
          nextGroup.to = this.translateDay(day);
        } else {
          groups.push({
            from: this.translateDay(day),
            to: this.translateDay(day),
            opens: specification.opens,
            closes: specification.closes,
          });
        }
      }
      return groups;
    },
  },

  methods: {
    getSpecification(day) {
      const specification = this.openingHours.find(item =>
        item.days_of_week.includes(weekdays[day]),
      );
      return specification ?? { opens: '00:00', closes: '00:00' };
    },
    closedAllDay(group) {
      return group.opens === '00:00' && group.closes === '00:00';
    },
    translateDay(day) {
      if (day === 0) return this.$t('sunday');
      else if (day === 1) return this.$t('monday');
      else if (day === 2) return this.$t('tuesday');
      else if (day === 3) return this.$t('wednesday');
      else if (day === 4) return this.$t('thursday');
      else if (day === 5) return this.$t('friday');
      else if (day === 6) return this.$t('saturday');
      return '';
    },
  },
};
</script>

<style lang="scss" scoped>
.OpeningHoursWeek {
  display: flex;
  flex-direction: column;
  gap: 3px;

  .OpeningHoursWeek__group {
    display: flex;
    justify-content: space-between;
    width: 100%;
    @include spacing-1('gap');
  }
}
</style>
