<template>
  <div class="BlockCollapse" :aria-expanded="isExpanded ? true : isExpanded.toString()">
    <div class="BlockCollapse__header" @click="toggleExpand">
      <span class="BlockCollapse__title">{{ title }}</span>
    </div>

    <CollapseTransition>
      <div v-show="isExpanded">
        <div class="BlockCollapse__content">
          <slot></slot>
        </div>
      </div>
    </CollapseTransition>
  </div>
</template>

<script>
import { CollapseTransition } from '@transitions';

export default {
  name: 'BlockCollapse',

  components: {
    CollapseTransition,
  },

  props: {
    title: {
      type: String,
      default: '',
    },
    expanded: {
      type: Boolean,
      default: false,
    },
  },

  watch: {
    expanded: function(expanded) {
      this.isExpanded = expanded;
    },
  },

  data() {
    return {
      isExpanded: false,
    };
  },

  created() {
    this.isExpanded = this.expanded;
  },

  methods: {
    toggleExpand() {
      this.isExpanded = !this.isExpanded;
    },
  },
};
</script>
