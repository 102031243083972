<template>
  <div class="FadeTransition" :style="cssProps">
    <transition @enter="enter" @leave="leave" mode="out-in">
      <slot name="default" />
    </transition>
  </div>
</template>

<script>
import { gsap } from 'gsap/all';

export default {
  name: 'FadeTransition',

  props: {
    duration: {
      type: Number,
      default: 0.2,
    },
    delay: {
      type: Number,
      default: 0,
    },
    transformHeight: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      leaveHeight: 0,
    };
  },

  methods: {
    enter(element) {
      if (this.transformHeight) {
        const height = getComputedStyle(element).height;

        gsap.fromTo(
          element,
          { height: this.leaveHeight },
          {
            height,
            duration: this.duration,
            overwrite: true,
            ease: 'sine.out',
            onComplete() {
              element.style.height = null;
            },
          },
        );
      }
    },
    leave(element) {
      if (this.transformHeight) {
        this.leaveHeight = getComputedStyle(element).height;
      }
    },
  },
  computed: {
    cssProps() {
      let overflow = this.transformHeight ? 'hidden' : 'visible';
      return {
        '--duration': this.duration + 's',
        '--delay': this.delay + 's',
        '--overflow': overflow,
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.FadeTransition {
  .v-enter-active {
    overflow: var(--overflow);
    transition: opacity var(--duration) cubic-bezier(0.25, 1, 0.5, 1);
  }
  .v-leave-active {
    overflow: var(--overflow);
    transition: opacity var(--duration) cubic-bezier(0.5, 0, 0.75, 0) var(--delay);
  }
  .v-enter,
  .v-leave-to {
    opacity: 0;
  }
}
</style>
