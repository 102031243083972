import navigatorLanguages from 'navigator-languages';
import dayjs from 'dayjs';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import './locales/nb';
import './locales/da';

dayjs.extend(localizedFormat);

export const formattedDatetime = ({
  datetime,
  format = '',
  showDate = false,
  showTime = false,
}) => {
  if (datetime !== undefined && datetime !== null && datetime !== '') {
    const preferredLanguage = navigatorLanguages()[0];
    let language = 'da';

    switch (preferredLanguage) {
      case 'no':
      case 'nb-NO':
        language = 'nb';
        break;
      case 'nn-NO':
        language = 'nn';
        break;
    }

    // Set locale
    dayjs.locale(language);

    if (format !== '' && format !== undefined) {
      // Handle if format is populated
      return dayjs(datetime).format(format);
    } else {
      // Handle if not and showDate/showTime is populated
      return [
        ...[showDate ? dayjs(datetime).format('L') : null],
        ...[showTime ? dayjs(datetime).format('LT') : null],
      ].join(' ');
    }
  }

  // Return an empty string if we're not served a date
  return '';
};
