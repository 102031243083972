<template>
  <div>
    <Button type="light" @click.prevent="openConfirmDialog">
      <slot name="default" />
    </Button>
    <Modal ref="openConfirmDialog" title="Nyt tilbud" :working="working">
      <template #default>
        <template v-if="askForName">
          <Input id="newDraftDescription" v-model="newDraftDescription" label="Tilbuddets navn" />
        </template>
        <p v-else>Er du sikker på du ville oprette et tilbud?</p>
      </template>
      <template #controls="{ close }">
        <Button type="light" @click.prevent="close">Fortryd</Button>
        <Button type="cta" @click.prevent="createQuote">Opret</Button>
      </template>
    </Modal>
  </div>
</template>

<script>
/** Flow:
 *  1. Open dialog
 *  2. Ask for name or confirmation
 *  3. Crate draft
 *  4. Patch draft
 *  5. Navigate to new draft
 */
import { postTransformType, patchDraft } from '@services/thansen/drafts';
import { Modal, Sizes as ModalSizes } from '@components/Modal';
import Input from '@scenes/SelfserviceScene/components/Input';
import DraftTypes from '@types/DraftTypes';
import {
  Button,
  sizes as ButtonSizes,
  colors as ButtonColors,
  icons as ButtonIcons,
} from '@components/Buttons';

export default {
  name: 'DraftTransformButton',

  components: {
    Button,
    Modal,
    Input,
  },

  enums: {
    ButtonSizes,
    ButtonColors,
    ButtonIcons,
    ModalSizes,
  },

  props: {
    draftId: {
      type: [String, Number],
      required: true,
    },
    transformTo: {
      type: String,
      required: true,
    },
    data: {
      type: Object,
      default() {
        return {};
      },
    },
    description: {
      type: String,
      default: '',
    },
  },

  data() {
    return {
      newDraftDescription: '',

      working: false,
    };
  },

  computed: {
    askForName() {
      return !this.data.description;
    },
  },

  created() {
    this.newDraftDescription = this.data.description;
  },

  methods: {
    getRegisterForErpData() {
      const data = { register_for_erp: $('#register_for_erp').prop('checked') };
      if (data.register_for_erp) {
        data['worksheet_number'] = $('#worksheetno').val();
        if ($('#erpsystype').val()) {
          data['system_name'] = $('#erpsystype option:selected').text();
          data['system_id'] = $('#erpsystype').val();
        }
      }

      return data;
    },
    openDialog() {
      this.$refs.dialog.open();
    },
    openConfirmDialog() {
      this.$refs.openConfirmDialog.open();
    },
    createQuote() {
      this.working = true;
      const { system_name, system_id, worksheet_number } = this.data;

      const postData = this.getRegisterForErpData();

      if (system_name) postData['system_name'] = system_name;
      if (system_id) postData['system_id'] = system_id;
      if (worksheet_number) postData['worksheet_number'] = worksheet_number;

      postTransformType(this.draftId, DraftTypes.QUOTE, postData)
        .then(draft => {
          const data = { description: this.newDraftDescription };
          if (this.data.customer_reference) {
            data.customer_reference = this.data.customer_reference;
          }
          return patchDraft(draft.draft_id, data).then(response => {
            return draft;
          });
        })
        .then(draft => {
          this.navigateToNewDraft(draft.draft_id);
        })
        .catch(error => {
          console.error(error);
        });
    },
    navigateToNewDraft(id) {
      let r = this.$router.resolve({
        name: '/selfservice/drafts/:id',
        params: { id },
      });
      window.location.assign(r.href);
    },
  },
};
</script>
