<template>
  <component :is="tag" v-observe-resize="onResize">
    <slot :width="width" :height="height" />
  </component>
</template>

<script>
export default {
  name: 'ResizeObserver',

  props: {
    tag: {
      type: String,
      default: 'div',
    },
  },

  data() {
    return {
      width: 0,
      height: 0,
    };
  },

  methods: {
    onResize({ width, height }) {
      // width, height, top, right, bottom, left, x, y
      this.width = width;
      this.height = height;
      this.$emit('resize', { width, height });
    },
  },
};
</script>
