var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('Drawer',{ref:"drawer",staticClass:"UserDrawer",attrs:{"closeLabel":_vm.$t('close')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var isActive = ref.isActive;
return [(isActive)?_c('div',[_c('a',{staticClass:"UserDrawer__userCard",attrs:{"href":_vm.$router.resolve({ name: '/selfservice/profile' }).href}},[_c('UserCard',{attrs:{"name":((_vm.accountContainer.account.first_name) + " " + (_vm.accountContainer.account.last_name)),"email":_vm.accountContainer.account.email_address,"clickable":""}}),_c('i',{staticClass:"UserDrawer__userCardIcon far fa-cog"})],1),_c('NavigationContainer',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var selfserviceRoutes = ref.selfserviceRoutes;
var isExactActive = ref.isExactActive;
return [_c('ul',_vm._l((selfserviceRoutes),function(route){return _c('li',{key:route.name},[_c('a',{class:[
                'UserDrawer__menuItem',
                isExactActive(route) && 'UserDrawer__menuItem--active' ],attrs:{"href":_vm.$router.resolve({ name: route.name }).href,"title":route.meta.title}},[_vm._v(" "+_vm._s(route.meta.title)+" ")])])}),0)]}}],null,true)}),_c('div',{staticClass:"UserDrawer__menuDivider"}),_c('button',{staticClass:"UserDrawer__menuItem",on:{"click":_vm.logout}},[_vm._v(" "+_vm._s(_vm.$t('account.auth.log_out'))+" ")])],1):_vm._e()]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }