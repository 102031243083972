import { isClearance } from './isClearance';
import { labelType } from './labelType';
import { onSale } from './onSale';
import { printIfHasUnit } from './printIfHasUnit';

export default {
  onSale,
  labelType,
  isClearance,
  printIfHasUnit,
};
