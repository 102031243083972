<template>
  <div class="ShortenedPath">
    <div v-if="!isPathVariantsCalculated">
      <span v-for="(node, index) in nodes" :key="index" ref="nodes" class="ShortenedPath__part">
        {{ node.name }}
      </span>
      <div class="ShortenedPath__part">
        <span>.</span>
        <span ref="ellipsis">{{ ellipsis }}</span>
        <span>.</span>
        <span ref="divider">{{ newDivider }}</span>
        <span>.</span>
      </div>
    </div>
    <span>{{ shortenedPath }}</span>
  </div>
</template>

<script>
export default {
  name: 'ShortenedPath',
  props: {
    path: {
      type: String,
      required: true,
    },
    //The available space in pixels for the path
    containerWidth: {
      type: Number,
      required: true,
    },
    //The string that divides the path
    //E.g. path = 'node1/node2/node3/node4/node5' should be divided by '/'
    divider: {
      type: String,
      default: '/',
    },
    //The string divides the nodes in the path variants
    //E.g. pathVariants[0] = 'node1 / node2 / node3 / node4 / node5' when newDivider = ' / '
    newDivider: {
      type: String,
      default: ' / ',
    },
    //The string that replaces hidden nodes
    //E.g. pathVariants[2] = 'node1 / ... / node4 / node5' when ellipsis = '...' and newDivider = ' / '
    ellipsis: {
      type: String,
      default: '...',
    },
  },
  data() {
    return {
      pathVariants: [], //Contains all path variants and their respective pixel width
      isPathVariantsCalculated: false,
    };
  },
  computed: {
    /**
     * Returns the most fitting path variant, depending on the reactive containerWidth
     */
    shortenedPath() {
      for (let i = 0; i < this.pathVariants.length; i++) {
        if (this.pathVariants[i].width < this.containerWidth) return this.pathVariants[i].string;
      }
      return '';
    },
  },
  created() {
    this.nodes = this.initializeNodes();
    this.dividerWidth = 0;
    this.ellipsisWidth = 0;
  },
  mounted() {
    //The pixel widths of the path variants can only be calculated when the HTML elements of the
    //nodes, the divider and the ellipsis exist
    this.$nextTick(() => {
      this.initializePathVariants();
    });
  },
  methods: {
    /**
     * Splits the path into an array using the divider
     */
    initializeNodes() {
      const nodes = [];
      const nodesArray = this.path.split(this.divider);
      for (let i = 0; i < nodesArray.length; i++) {
        nodes.push({ name: nodesArray[i], width: 0 });
      }
      return nodes;
    },
    /**
     * Creates different variants of path and their respective pixel width. These are added to
     * pathVariants ordered by their pixel width. Example of path variant strings:
     * 'node1 / node2 / node3 / node4 / node5'
     * 'node1 / ... / node3 / node4 / node5'
     * 'node1 / ... / node4 / node5'
     * 'node1 / ... / node5'
     * '... / node5'
     */
    initializePathVariants() {
      if (!this.nodes.length) {
        this.isPathVariantsCalculated = true;
        return;
      }
      //Get pixel width of nodes, divider and ellipsis
      //This must be done before calculating pixel width of all path variants
      this.updateNodeWidths();
      this.updateEllipsisWidth();
      this.updateNewDividerWidth();
      //Adds the full path incuding its pixel width
      this.pathVariants.push(this.getFullPath(this.nodes));
      //Adds shorter variants of path including their pixel width
      const firstNode = this.nodes[0];
      let subsequentNodes = this.nodes.slice(1);
      while (subsequentNodes.length) {
        this.pathVariants.push(this.getPathVariant(firstNode, subsequentNodes));
        subsequentNodes = subsequentNodes.slice(1);
      }
      //Adds the shortest path variant including its pixel width
      const lastNode = this.nodes[this.nodes.length - 1];
      this.pathVariants.push(this.getShortPath(lastNode));
      this.isPathVariantsCalculated = true;
    },
    updateNodeWidths() {
      const nodeElements = this.$refs.nodes;
      if (!nodeElements) return;
      for (let i = 0; i < nodeElements.length; i++) {
        this.nodes[i].width = nodeElements[i].getBoundingClientRect().width;
      }
    },
    updateEllipsisWidth() {
      this.ellipsisWidth = this.$refs.ellipsis.getBoundingClientRect().width;
    },
    updateNewDividerWidth() {
      this.dividerWidth = this.$refs.divider.getBoundingClientRect().width;
    },
    getFullPath(nodes) {
      let string = '';
      let width = 0;
      for (let i = 0; i < nodes.length; i++) {
        string += this.newDivider + nodes[i].name;
        width += this.dividerWidth + nodes[i].width;
      }
      string = string.substring(3);
      width -= this.dividerWidth;
      return { string: string, width: width };
    },
    getPathVariant(firstNode, subsequentNodes) {
      let string = firstNode.name + this.newDivider + this.ellipsis;
      let width = firstNode.width + this.dividerWidth + this.ellipsisWidth;
      for (let i = 0; i < subsequentNodes.length; i++) {
        string += this.newDivider + subsequentNodes[i].name;
        width += this.dividerWidth + subsequentNodes[i].width;
      }
      return { string: string, width: width };
    },
    getShortPath(node) {
      const string = this.ellipsis + this.newDivider + node.name;
      const width = this.ellipsisWidth + this.dividerWidth + node.width;
      return { string: string, width: width };
    },
  },
};
</script>

<style lang="scss" scoped>
.ShortenedPath {
  .ShortenedPath__part {
    position: absolute;
    opacity: 0;
  }
}
</style>
