<template>
  <div class="TableSkeleton">
    <div class="TableSkeleton__header"></div>
    <div class="TableSkeleton__rows">
      <div v-for="i in rows" :key="i" class="TableSkeleton__row">
        <slot name="default">
          <SkeletonGraphic :width="100" :height="20" />
          <SkeletonGraphic :width="100" :height="20" />
          <SkeletonGraphic :width="100" :height="20" />
          <SkeletonGraphic :width="100" :height="20" />
        </slot>
      </div>
    </div>
  </div>
</template>

<script>
import SkeletonGraphic from '../SkeletonGraphic';

export default {
  name: 'TableSkeleton',

  components: {
    SkeletonGraphic,
  },

  props: {
    rows: {
      type: Number,
      default: 4,
    },
  },
};
</script>

<style lang="scss" scoped>
.TableSkeleton {
  display: flex;
  flex-direction: column;

  .TableSkeleton__header {
    height: 50px;
    background-color: #f9f9f9;
  }

  .TableSkeleton__rows {
    display: flex;
    flex-direction: column;
  }

  .TableSkeleton__row {
    display: flex;
    justify-content: space-between;
    padding: 15px;
    border-top: 1px solid #efefef;
  }
}
</style>
