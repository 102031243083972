<template>
  <router-link
    v-if="routeTo !== undefined && disabled === false && working === false"
    :to="routeTo"
    class="ButtonWrapper"
    @click="clickButton"
  >
    <button
      :id="id"
      :class="['Button', active && 'is-active', getClassSize, getClassType]"
      :disabled="disabled || working"
    >
      <i v-if="icon" :class="icon"></i>
      <slot />
      <span
        v-if="count"
        :class="[
          'Button__count',
          countPosition === countPositionType.TOP_LEFT && 'Button__count--top-left',
          countPosition === countPositionType.INLINE && 'Button__count--inline',
        ]"
        >{{ count }}</span
      >
    </button>
  </router-link>

  <component
    :is="tag"
    v-else
    :id="id"
    :class="['Button', active && 'is-active', getClassSize, getClassType]"
    :disabled="disabled || working"
    @click="clickButton"
  >
    <div v-if="working === true" class="SpinnerWrapper">
      <Spinner class="SpinnerHandler" :width="7" />
    </div>
    <i v-if="icon" :class="icon"></i>
    <slot />
    <span
      v-if="count"
      :class="[
        'Button__count',
        countPosition === countPositionType.TOP_LEFT && 'Button__count--top-left',
        countPosition === countPositionType.INLINE && 'Button__count--inline',
      ]"
      >{{ count }}</span
    >
  </component>
</template>

<script>
import Spinner from '@components/Spinner';
import { countPosition as countPositionType } from './index';

export default {
  name: 'Button',
  components: {
    Spinner,
  },
  props: {
    tag: {
      type: String,
      default: 'button',
    },
    id: {
      type: String,
      default: '',
    },
    size: {
      // exported sized const from index.js used as ENUM here
      type: String,
      default: '',
    },
    count: {
      type: Number,
      default: 0,
    },
    countPosition: {
      type: String,
      default: countPositionType.TOP_LEFT,
    },
    type: {
      type: String,
      default: '',
    },
    color: {
      type: String,
      default: '',
    },
    icon: {
      type: String,
      default: '',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    working: {
      type: Boolean,
      default: false,
    },
    routeTo: {
      type: Object,
      required: false,
      default: undefined,
    },
    active: {
      type: Boolean,
      defalt: false,
    },
  },

  data() {
    return {
      countPositionType,
    };
  },

  computed: {
    getClassSize() {
      return this.size ? 'Button--' + this.size : '';
    },
    getClassType() {
      if (this.type !== '') {
        return 'Button--' + this.type;
      } else if (this.color !== '') {
        return 'Button--' + this.color;
      }
      return '';
    },
  },

  methods: {
    clickButton(e) {
      if (this.disabled === false && this.working === false) {
        this.$emit('click', e);
      }
    },
  },

  types: {
    ...countPositionType,
  },
};
</script>

<style lang="scss">
.Button {
  position: relative;
  &--full {
    width: 100%;
  }
  @media print {
    visibility: hidden !important;
  }
}

.SpinnerWrapper {
  position: absolute;
  top: -1px;
  left: -1px;
  bottom: -1px;
  right: -1px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.4);
  padding: 3px;

  z-index: 1;

  > svg {
    position: absolute;
    height: calc(100% - 6px);
  }
}

.SpinnerWrapper > svg {
  position: absolute !important;
}

.ButtonWrapper {
  position: relative;
  display: inline-block;
}
</style>
