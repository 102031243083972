<template>
  <Validator v-slot="{ valid, showErrors }" ref="validator">
    <Form
      class="LoginForm"
      :title="$t('account.auth.log_in')"
      @submit="valid ? login() : showErrors()"
      :locked="working"
    >
      <template #header>
        <FadeTransition :duration="0.2" :delay="0" transform-height>
          <Alert v-if="showLoginError" :level="AlertLevel.ERROR" closeable @close="hideError">
            {{ $t('account.auth.message.invalid_login') }}
          </Alert>
        </FadeTransition>
      </template>
      <template #default>
        <Input
          id="userEmail"
          v-model="email"
          name="email"
          type="email"
          :label="$t('email')"
          autocomplete="username"
          :auto-focus="false"
          required
          class="LoginCard__input"
          validate-on-blur
        />
        <Input
          id="current-password"
          v-model="password"
          name="password"
          type="password"
          :label="$t('password')"
          :autocomplete="Autocompletes.PASSWORD"
          required
          class="LoginCard__input"
        />
        <Checkbox id="remember" v-model="remember" class="LoginCard__remember">
          {{ $t('account.auth.remember_me') }}
        </Checkbox>
      </template>
      <template #footer>
        <Button :size="ButtonSizes.FULL" :working="working">
          {{ $t('account.auth.log_in') }}
        </Button>
        <Button type="as-link" :size="ButtonSizes.SMALL" @click="$emit('clickOnForgotPassword')">
          {{ $t('account.auth.forgot_password') }}
        </Button>
      </template>
    </Form>
  </Validator>
</template>

<script>
import Validator from '@containers/Validator';
import Form from '@layouts/Form';
import FadeTransition from '@transitions/Fade';
import Alert, { AlertLevel } from '@components/Alert';
import { Checkbox } from '@components/Checkbox';
import { Button, sizes as ButtonSizes } from '@components/Buttons';
import Input, { Autocompletes } from '@scenes/SelfserviceScene/components/Input';

export default {
  name: 'LoginForm',

  components: {
    Validator,
    Form,
    FadeTransition,
    Alert,
    Checkbox,
    Button,
    Input,
  },

  enums: {
    AlertLevel,
    ButtonSizes,
    Autocompletes,
  },

  props: {
    doReload: {
      type: Boolean,
      default: true,
    },
  },

  data() {
    return {
      email: '',
      password: '',
      remember: false,
      showLoginError: false,
      working: false,
    };
  },

  watch: {
    email() {
      this.hideError();
    },
    password() {
      this.hideError();
    },
  },

  created() {
    this.authenticationContainer.clearStatus();
  },

  methods: {
    login() {
      this.working = true;
      this.authenticationContainer
        .login(
          {
            email: this.email,
            password: this.password,
          },
          {
            reload: this.doReload,
            remember: this.remember,
          },
        )
        .then(() => this.hideError())
        .catch(() => this.showError());
    },
    showError() {
      this.working = false;
      this.showLoginError = true;
    },
    hideError() {
      //this.working = false;
      this.showLoginError = false;
    },
  },

  inject: {
    authenticationContainer: {
      default() {
        console.error('Missing AuthenticationContainer');
        return;
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.error-message {
  transform: translate(0, 0);
  opacity: 1;
  transform-origin: left center;
  height: 30px;
}

.LoginCard__remember {
  //margin-bottom: -1em !important; // TODO: Trim Checkbox input instead
}
</style>
