import i18n from '@/i18n';
import settings from '@settings';
import selfserviceRoutes from './selfservice';
import selfserviceLegacyRoutes from './selfservice-legacy';

// Add Guarded middelware: https://nordschool.com/vue-router/
// Use scene:'SceneName' to overwrite the named scene default,
// this is usefull when working with multiply routes that uses the same scene.

/**
 * Translates a path
 * @param {String} path The path to translate
 * @param {String} basePath The base path is used in the case of child routes.
 */
function translatePath(path, basePath) {
  // Most of this functionality is here to support paths with multiply "/".
  const pathArr = path.split('/').filter(path => path !== '');

  const pathSections = [];
  let pathName = '';

  pathArr.forEach((path, i) => {
    if (i !== 0 || basePath === '') {
      pathName += '/';
    }
    pathName += path;
    pathSections.push(pathName);
  });

  // Looking up the translations for each part of the url.
  const localizedPath = pathArr.reduce((translatedPath, curPath, i) => {
    const startPath = translatedPath !== '' ? translatedPath + '/' : '';
    // Skips params - since they shouldn't be translated.
    if (curPath.indexOf(':') === -1) {
      if (i18n.te(`routes[${basePath}/${curPath}].slug`)) {
        return startPath + i18n.t(`routes[${basePath}/${curPath}].slug`);
      } else if (i18n.te(`routes[${basePath}/${pathSections[i]}].slug`)) {
        return startPath + i18n.t(`routes[${basePath}/${pathSections[i]}].slug`);
      }
    }
    return startPath + curPath;
  }, '');

  return path.charAt(0) == '/' ? '/' + localizedPath : localizedPath;
}

/**
 * Translates a route object
 * @param {Object} route A vue combatiable route object
 * @param {String} basePath The base path is used in the case of child routes.
 */
function translateRoute(route, basePath) {
  const pathId = basePath !== '' ? `${basePath}/${route.path}` : `${route.path}`;

  // Setting meta title with the routes translation
  let meta = {
    title: i18n.t(`routes[${pathId}].title`),
    ...(route.meta !== undefined ? route.meta : {}),
  };

  // Creates the generated and translated route object
  let translatedRoute = {
    name: route.name || pathId,
    path: translatePath(route.path, basePath),
    meta,
  };

  // If the current route has children then they are also translated.
  if (route.children !== undefined && route.children.length > 0) {
    translatedRoute.children = translateRoutes(route.children, pathId);
  }

  return { ...route, ...translatedRoute };
}

/**
 *
 * @param {Array} routes A list of vue combatiable route objects
 * @param {String} basePath The base path is used in the case of child routes.
 */
function translateRoutes(routes, basePath = '') {
  return routes.map(route => translateRoute(route, basePath));
}

// Addanmark legacy routes - will be refactored evantaully.
const legacyRoutes = [
  {
    path: '/kampagne',
    name: 'ad-campaigns',
    component: () => import('@components/legacy/ad-campaigns'),
  },
  {
    path: '/kampagne/:campaignid',
    name: 'ad-campaign',
    component: () => import('@components/legacy/ad-campaign'),
  },
  {
    path: '/nyheder',
    name: 'ad-news',
    component: () => import('@components/legacy/ad-news'),
  },
  {
    path: '/nyheder/:newsid',
    name: 'ad-newsItem',
    component: () => import('@components/legacy/ad-newsItem'),
  },
];

let routes = legacyRoutes;

if (selfserviceLegacyRoutes && settings.selfservice.useLegacyRoutes) {
  routes = [...selfserviceLegacyRoutes, ...routes];
} else if (selfserviceRoutes) {
  routes = [...translateRoutes(selfserviceRoutes), ...routes];
}

export default routes;
