import {
  getToken,
  postLogin,
  postLogout,
  postPasswordRequestChange,
  postPasswordChange,
} from '@services/thansen/authentication';
import Progress from '@types/Progress';

export default {
  name: 'AuthenticationContainer',

  data() {
    return {
      tokenStatus: '',
      loginStatus: '',
      requestPasswordChangeStatus: '',
      changePasswordStatus: '',
    };
  },

  methods: {
    clearStatus() {
      this.tokenStatus = '';
      this.loginStatus = '';
      this.requestPasswordChangeStatus = '';
      this.changePasswordStatus = '';
    },
    fetchToken() {
      return new Promise((resolve, reject) => {
        this.tokenStatus = Progress.WORKING;
        getToken()
          .then(token => {
            this.tokenStatus = Progress.COMPLETE;
            resolve(token);
          })
          .catch(error => {
            this.tokenStatus = Progress.ERROR;
            reject();
          });
      });
    },
    login(credentials, options = {}) {
      return new Promise((resolve, reject) => {
        this.loginStatus = Progress.WORKING;
        postLogin(credentials, !!options.remember)
          .then(data => {
            this.loginStatus = Progress.COMPLETE;
            if (options.reload) {
              if (this.$route.query.redirect) {
                window.location.replace(this.$route.query.redirect);
              } else {
                window.location.reload();
              }
            }
            resolve();
          })
          .catch(error => {
            this.loginStatus = Progress.ERROR;
            reject();
          });
      });
    },
    logout() {
      postLogout().then(() => {
        window.location.href = '/';
      });
    },
    requestPasswordChange(email) {
      return new Promise((resolve, reject) => {
        this.requestPasswordChangeStatus = Progress.WORKING;
        postPasswordRequestChange(email)
          .then(data => {
            this.requestPasswordChangeStatus = Progress.COMPLETE;
            resolve();
          })
          .catch(error => {
            this.requestPasswordChangeStatus = Progress.ERROR;
            reject();
          });
      });
    },
    changePassword(token, password, extra) {
      return new Promise((resolve, reject) => {
        this.changePasswordStatus = Progress.WORKING;
        postPasswordChange(token, password, extra)
          .then(data => {
            this.changePasswordStatus = Progress.COMPLETE;
            resolve();
          })
          .catch(errors => {
            this.changePasswordStatus = Progress.ERROR;
            reject(errors);
          });
      });
    },
  },

  render() {
    return this.$scopedSlots.default({
      loginStatus: this.loginStatus,
      requestPasswordChangeStatus: this.requestPasswordChangeStatus,
      changePasswordStatus: this.changePasswordStatus,
      login: this.login,
      logout: this.logout,
      requestPasswordChange: this.requestPasswordChange,
      changePassword: this.changePassword,
    });
  },

  provide() {
    return {
      authenticationContainer: this,
    };
  },
};
