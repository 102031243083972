import Vue from 'vue';
import Cookies from 'js-cookie';
import { fecthCardataByLicensePlate, fetchCardataByNode } from '../../../services/thansen/car';
import { Mutation } from './types';

const cookieB = new URLSearchParams(Cookies.get('b'));

const filterIndex = ['manufacturers', 'models', 'engines', 'production_years', 'variants'];

const expireTime = 30 * 60000;

export default {
  namespaced: true,
  state: {
    // Filter is the used for the manuel car selector/search.
    filter: {
      options: [],
      status: '',
      expires: new Date().getTime() + expireTime,
    },
    // Search
    search: {
      licenseplate: cookieB.get('r') || '',
      vin: '',
      status: '',
      result: {},
      expires: new Date().getTime() + expireTime,
    },
    // Flash notifications are used to parse notifications between pages (survives page refresh)
    flashNotification: '',
  },
  getters: {
    nextOptionType: (state, getters) => {
      let nextIndex = -1;
      const allSelected = filterIndex.every((key, i) => {
        nextIndex = i;
        return getters.getFilterSelectedOption(key);
      });

      if (!allSelected && nextIndex >= 0) {
        return filterIndex[nextIndex];
      }
      return '';
    },
    getFilterOptions: state => (category, filter) => {
      let options = state.filter.options[filterIndex.indexOf(category)] || [];

      if (filter && options.length !== 0) {
        options = options.filter(option => {
          return option[filter];
        });
        // Only filtred options are sorted.
        return [...options].sort((a, b) => {
          return b.favorite_priority - a.favorite_priority;
        });
      }
      return [...options].sort((a, b) => {
        if (a.name === b.name) return 0;
        return a.name < b.name ? -1 : 1;
      });
    },
    getFilterSelectedOption: state => category => {
      const options = state.filter.options[filterIndex.indexOf(category)] || [];

      if (options) {
        return options.find(option => {
          return option.is_selected;
        });
      }

      return null;
    },
    hasFilterOptions: (state, getters) => category => {
      return getters.getFilterOptions(category).length !== 0;
    },
    getSearchedLicensePlate: state => {
      return state.search.licenseplate || cookieB.get('r');
    },
    getSearchedVehicleId: state => {
      if (state.search.result && state.search.result.vehicle_id) {
        return state.search.result.vehicle_id;
      }
      return cookieB.get('t');
    },
    getSearchResult: state => {
      return state.search.result;
    },
    isSearchExact: (state, getters) => {
      return getters.getSearchResult.url && state.search.is_exact_match;
    },
    getSearchMatch: state => {
      if (
        state.search.result &&
        state.search.result.is_exact_match &&
        state.search.result.hasOwnProperty('url')
      ) {
        return state.search.result;
      }
      return null;
    },
    isFetching: state => {
      return state.filter.status === 'fetching' || state.search.status === 'fetching';
    },
    getFilterMatch: state => {
      let matches = [];
      if (!state.filter.options) {
        return '';
      }
      state.filter.options.forEach(filter => {
        if (filter) {
          matches = matches.concat(
            filter.filter(item => {
              return (
                item.is_selected && item.hasOwnProperty('vehicle_id') && item.hasOwnProperty('url')
              );
            }),
          );
        }
      });

      if (matches.length) {
        return matches[matches.length - 1];
      }
      return '';
    },
  },
  actions: {
    clearStatus({ commit }) {
      commit(Mutation.SET_FILTER_STATUS, 'idle');
      commit(Mutation.SET_SEARCH_STATUS, 'idle');
    },
    setFlashNotification({ commit }, notification) {
      commit(Mutation.SET_FLASH_NOTIFICATION, notification);
    },
    clearFlashNotification({ commit }) {
      commit(Mutation.SET_FLASH_NOTIFICATION, '');
    },
    clearSearch({ commit }) {
      commit(Mutation.CLEAR_SEARCH);
    },
    setupFilter({ commit, state, dispatch }, n) {
      if (state.filter.options.length === 0 && state.filter.status !== 'fetching') {
        dispatch('updateFilterByNode', { n });
      }
    },
    updateFilterByNode({ commit, dispatch }, { n, category, vid }) {
      const level = filterIndex.indexOf(category);

      if (typeof level !== 'undefined') {
        commit(Mutation.CLEAR_FILTER, level + 1);
      }

      return new Promise((resolve, reject) => {
        if (n !== '' && typeof n !== 'undefined') {
          if (typeof level !== 'undefined' && filterIndex.length <= level + 1) {
            commit(Mutation.UPDATE_FILTER, { n, level, vid });
            resolve();
          } else {
            commit(Mutation.SET_FILTER_STATUS, 'fetching');
            fetchCardataByNode(n)
              .then(data => {
                commit(Mutation.UPDATE_FILTER, { n, level, data, vid });
                // Checks if only one selection is avaliable and then loads the next level.
                if (data.length === 1 && filterIndex.length > level + 1) {
                  const nextCategory = filterIndex[level + 1];
                  dispatch('updateFilterByNode', {
                    n: data[0].n,
                    category: nextCategory,
                    vin: data[0].vehicle_id,
                  })
                    .then(nextData => {
                      commit(Mutation.SET_FILTER_STATUS, 'idle');
                      resolve(nextData);
                    })
                    .catch(err => {
                      commit(Mutation.SET_FILTER_STATUS, 'idle');
                      reject(err);
                    });
                } else {
                  commit(Mutation.SET_FILTER_STATUS, 'idle');
                  resolve(data);
                }
              })
              .catch(err => {
                commit(Mutation.SET_FILTER_STATUS, 'idle');
                reject(err);
              });
          }
        } else {
          commit(Mutation.UPDATE_FILTER, { n, level, vid });
          resolve();
        }
      });
    },
    getByLicensePlate({ commit, state, getters }, licenseplate) {
      return new Promise((resolve, reject) => {
        // Ignores stacked calls

        if (state.search.licenseplate === licenseplate && state.search.status === 'fetching') {
          resolve(state.search.status);
          return;
        }

        commit(Mutation.CLEAR_FILTER);

        // If an unexpired matching successful licenseplate result is in localstorage then use that
        if (
          state.search.licenseplate === licenseplate &&
          state.search.status === 'success' &&
          state.search.expires > new Date().getTime()
        ) {
          // If a filter (partial car match) is present at then add it
          if (getters.getSearchResult && getters.getSearchResult.filter) {
            commit(Mutation.SET_FILTER, { filter: getters.getSearchResult.filter });
          }

          if (state.search.status === 'success') {
            resolve(state.search.status);
            return;
          }
        }

        commit(Mutation.SET_SEARCH, { licenseplate, status: 'fetching' });

        fecthCardataByLicensePlate(licenseplate)
          .then(resp => {
            // Clear filter on match found
            if (resp.is_exact_match) {
              commit(Mutation.CLEAR_FILTER);
            }

            if (resp.vehicle_data) {
              const filter = [];
              Object.entries(resp.vehicle_data).forEach(([key, value]) => {
                if (filterIndex.indexOf(key) !== -1) {
                  filter[filterIndex.indexOf(key)] = value;
                }
              });

              commit(Mutation.SET_SEARCH, {
                licenseplate,
                status: 'success',
                result: { ...resp, filter: JSON.parse(JSON.stringify(filter)) }, // IMPORTANT: JSON.parse is used to make a deep copy of the array.
              });
              commit(Mutation.SET_FILTER, { filter });
            } else {
              commit(Mutation.SET_SEARCH, {
                licenseplate,
                status: 'success',
                result: resp,
                expires: new Date().getTime() + expireTime,
              });
            }

            resolve(state.search.status);
          })
          .catch(err => {
            if (err && err.error && err.error.status === 400) {
              commit(Mutation.SET_SEARCH, { licenseplate, status: 'notfound' });
            } else {
              commit(Mutation.SET_SEARCH, { licenseplate, status: 'error' });
            }
            reject(state.search.status);
          });
      });
    },
    /**
     *  Copy of lp search above
     *  @param {*} vin 17 chars stelnummer
     */
    getByVin({ commit, state, getters }, vin) {
      return new Promise((resolve, reject) => {
        // Ignores stacked calls
        commit(Mutation.CLEAR_FILTER);

        // If an unexpired matching successful licenseplate result is in localstorage then use that
        if (
          state.search.vin === vin &&
          state.search.status === 'success' &&
          state.search.expires > new Date().getTime()
        ) {
          // If a filter (partial car match) is present at then add it
          if (getters.getSearchResult && getters.getSearchResult.filter) {
            commit(Mutation.SET_FILTER, { filter: getters.getSearchResult.filter });
          }

          if (state.search.status === 'success') {
            resolve(state.search.status);
            return;
          }
        }

        commit(Mutation.SET_SEARCH, { vin, status: 'fetching' });

        fecthCardataByLicensePlate(vin) //  The endpoint accepts both VIN and lisenseplate at the moment
          .then(resp => {
            // Clear filter on match found
            if (resp.is_exact_match) {
              commit(Mutation.CLEAR_FILTER);
            }

            if (resp.vehicle_data) {
              const filter = [];
              Object.entries(resp.vehicle_data).forEach(([key, value]) => {
                if (filterIndex.indexOf(key) !== -1) {
                  filter[filterIndex.indexOf(key)] = value;
                }
              });

              commit(Mutation.SET_SEARCH, {
                vin,
                status: 'success',
                result: { ...resp, filter: JSON.parse(JSON.stringify(filter)) }, // IMPORTANT: JSON.parse is used to make a deep copy of the array.
              });
              commit(Mutation.SET_FILTER, { filter });
            } else {
              commit(Mutation.SET_SEARCH, {
                vin,
                status: 'success',
                result: resp,
                expires: new Date().getTime() + expireTime,
              });
            }

            resolve(state.search.status);
          })
          .catch(err => {
            if (err && err.error && err.error.status === 400) {
              commit(Mutation.SET_SEARCH, { vin, status: 'notfound' });
            } else {
              commit(Mutation.SET_SEARCH, { vin, status: 'error' });
            }
            reject(state.search.status);
          });
      });
    },
  },
  mutations: {
    [Mutation.SET_FLASH_NOTIFICATION](state, notification) {
      Vue.set(state, 'flashNotification', notification);
    },
    [Mutation.UPDATE_FILTER](state, { n, level, data, vid }) {
      const newFilter = [...state.filter.options];
      if (typeof level === 'undefined' || level === -1) {
        newFilter[0] = data;
      } else {
        newFilter[level].forEach((item, index) => {
          if (
            item.n.toString() === n.toString() &&
            (vid == null || item.vehicle_id.toString() === vid.toString())
          ) {
            item.is_selected = true;
          } else if (item.is_selected) {
            item.is_selected = false;
          }
        });

        if (data != null) newFilter[level + 1] = data;

        while (newFilter.length - 1 > level + 1) {
          newFilter.pop();
        }
      }
      newFilter && newFilter[0] && Vue.set(state.filter, 'options', newFilter);
    },
    [Mutation.SET_FILTER](state, { filter }) {
      filter && filter[0] && Vue.set(state.filter, 'options', filter);
    },
    [Mutation.CLEAR_SEARCH](state, start) {
      const defaultSearch = {
        licenseplate: '',
        status: '',
        result: {},
      };
      state.search = defaultSearch;
    },
    [Mutation.CLEAR_FILTER](state, start) {
      if (state.filter.options.length === 0) return;

      if (start) {
        state.filter.options.splice(start, 10);
      } else {
        // state.filter = state.filter.splice(1, 10); // Removes all but the first
        const newFilter = [state.filter.options[0]];
        newFilter[0] &&
          newFilter[0].forEach(item => {
            if (item.is_selected) {
              item.is_selected = false;
            }
          });

        newFilter && newFilter[0] && Vue.set(state.filter, 'options', newFilter);
      }
    },
    [Mutation.SET_SEARCH](state, search) {
      state.search = search;
    },
    [Mutation.SET_FILTER_STATUS](state, status) {
      state.filter.status = status;
    },
    [Mutation.SET_SEARCH_STATUS](state, status) {
      state.search.status = status;
    },
  },
};
