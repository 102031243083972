import { updateFeatures } from '@features';
import { deleteAccount, getAccount, getOverview, putAccount } from '@services/thansen/account';
import { getProfile, putProfile } from '@services/thansen/newsletter';
import { AccountType } from '@types/Account';
import Progress from '@types/Progress';
import Cookies from 'js-cookie';

export const Getters = {
  GET_ACCOUNT_TYPE: 'GET_ACCOUNT_TYPE',
  GET_BRANCH: 'GET_BRANCH',
  IS_DEALER: 'IS_DEALER',
  IS_PRIVATE: 'IS_PRIVATE',
  IS_VENDOR: 'IS_VENDOR',
  FULL_NAME: 'FULL_NAME',
  HAS_EMAIL: 'HAS_EMAIL',
};

export const Actions = {
  FETCH_ACCOUNT: 'FETCH_ACCOUNT',
  UPDATE_ACCOUNT: 'UPDATE_ACCOUNT',
  DELETE_ACCOUNT: 'DELETE_ACCOUNT',

  DELETE_EMAIL: 'DELETE_ACCOUNT_EMAIL',
  ADD_EMAIL: 'ADD_ACCOUNT_EMAIL',
  EDIT_EMAIL: 'EDIT_ACCOUNT_EMAIL',
  SET_PRIMARY_EMAIL: 'SET_PRIMARY_EMAIL',

  FETCH_PROFILE: 'FETCH_PROFILE',
  UPDATE_PROFILE: 'UPDATE_PROFILE',
  FETCH_OVERVIEW: 'FETCH_OVERVIEW',
  UPDATE_BRANCH: 'UPDATE_BRANCH',
  DELETE_BRANCH: 'DELETE_BRANCH',
};

const Mutations = {
  SET_HAS_FETCHED_ACCOUNT: 'SET_HAS_FETCHED_ACCOUNT',
  SET_ACCOUNT_STATUS: 'SET_ACCOUNT_STATUS',
  SET_ACCOUNT: 'SET_ACCOUNT',
  SET_ACCOUNT_UPDATING_STATUS: 'SET_ACCOUNT_UPDATING_STATUS',
  ASSIGN_ACCOUNT: 'ASSIGN_ACCOUNT',
  SET_PROFILE_STATUS: 'SET_PROFILE_STATUS',
  SET_PROFILE: 'SET_PROFILE',
  SET_PROFILE_UPDATING_STATUS: 'SET_PROFILE_UPDATING_STATUS',
  ASSIGN_PROFILE: 'ASSIGN_PROFILE',
  SET_OVERVIEW: 'SET_OVERVIEW',
};

let timeoutUpdateProfile = null;
const updateOverviewCooldown = 10000;

export default {
  namespaced: true,

  state: {
    isLoggedIn: window.logedIn,
    hasFetchedAccount: false,
    accountStatus: '',
    account: null,
    accountUpdatingStatus: '',
    profileStatus: '',
    profile: null,
    profileUpdatingStatus: '',
    overview: {},
    latestOverviewUpdate: 0,
  },

  getters: {
    [Getters.GET_ACCOUNT_TYPE](state) {
      return state.account ? state.account.type : '';
    },
    [Getters.IS_DEALER](state) {
      return state.account ? state.account.type === AccountType.DEALER : false;
    },
    [Getters.IS_PRIVATE](state) {
      return state.account ? state.account.type === AccountType.PRIVATE : false;
    },
    [Getters.IS_VENDOR](state) {
      return state.account ? state.account.type === AccountType.VENDOR : false;
    },
    [Getters.GET_BRANCH](state) {
      return state.account?.branch_slug ? state.account.branch : null;
    },
    [Getters.HAS_EMAIL](state) {
      return email => {
        email = email.toLowerCase();
        if (state.account.email_address === email) return true;
        if (!state.account?.secondary_email_addresses) return false;
        return state.account.secondary_email_addresses.some(emailObj => {
          return emailObj.email_address === email;
        });
      };
    },
    [Getters.FULL_NAME](state) {
      if (state.account) {
        if (state.account.first_name && state.account.last_name) {
          return state.account.first_name + ' ' + state.account.last_name;
        } else if (state.account.first_name) {
          return state.account.first_name;
        } else if (state.account.last_name) {
          return state.account.last_name;
        }
      }
      return '';
    },
  },

  actions: {
    [Actions.FETCH_ACCOUNT]({ commit }) {
      return new Promise((resolve, reject) => {
        commit(Mutations.SET_HAS_FETCHED_ACCOUNT, true);
        commit(Mutations.SET_ACCOUNT_STATUS, Progress.WORKING);
        getAccount()
          .then(account => {
            if (account.branch_slug && Cookies.get('favShopClosestsWebId') === undefined) {
              Cookies.set('favShopClosestsWebId', account.branch_slug);
            }

            updateFeatures(account.type);

            commit(Mutations.SET_ACCOUNT, account);
            commit(Mutations.SET_ACCOUNT_STATUS, Progress.COMPLETE);
            resolve();
          })
          .catch(error => {
            commit(Mutations.SET_ACCOUNT_STATUS, Progress.ERROR);
            reject();
          });
      });
    },
    [Actions.UPDATE_ACCOUNT]({ commit, dispatch }, account) {
      commit(Mutations.SET_ACCOUNT_UPDATING_STATUS, Progress.WORKING);
      putAccount(account)
        .then(data => {
          dispatch(Actions.FETCH_ACCOUNT);
          commit(Mutations.SET_ACCOUNT_UPDATING_STATUS, Progress.COMPLETE);
        })
        .catch(error => {
          commit(Mutations.SET_ACCOUNT_UPDATING_STATUS, Progress.ERROR);
        });
    },
    [Actions.DELETE_ACCOUNT]({ state }) {
      return new Promise((resolve, reject) => {
        if (!state.account?.current_user?.id) {
          reject();
          return;
        }
        deleteAccount(state.account.current_user.id)
          .then(data => {
            resolve();
          })
          .catch(error => {
            reject();
          });
      });
    },
    [Actions.DELETE_EMAIL]({ state, dispatch }, deleteEmail) {
      return new Promise((resolve, reject) => {
        if (!state.account.secondary_email_addresses) {
          reject();
        }
        const putData = {
          secondary_email_addresses: state.account.secondary_email_addresses.map(emailObj => {
            if (emailObj.email_address === deleteEmail) {
              return { ...emailObj, email_address: '' };
            }
            return emailObj;
          }),
        };
        putAccount(putData)
          .then(data => {
            dispatch(Actions.FETCH_ACCOUNT).finally(e => resolve());
          })
          .catch(error => {
            reject(error);
          });
      });
    },
    [Actions.SET_PRIMARY_EMAIL]({ state, dispatch }, email) {
      return new Promise((resolve, reject) => {
        if (!state.account.secondary_email_addresses) {
          reject();
        }
        const swapFrom = state.account.secondary_email_addresses.find(
          emailObj => emailObj.email_address === email,
        );
        const swapTo = state.account.secondary_email_addresses.find(
          emailObj => emailObj.order === 0,
        );
        if (!swapFrom || !swapTo) reject();
        const secondaryEmailAddresses = [
          { ...swapFrom, order: 0 },
          { ...swapTo, order: swapFrom.order },
        ];
        putAccount({ secondary_email_addresses: secondaryEmailAddresses })
          .then(data => {
            dispatch(Actions.FETCH_ACCOUNT).finally(e => resolve());
          })
          .catch(errors => reject(errors));
      });
    },
    [Actions.ADD_EMAIL]({ state, dispatch }, email) {
      return new Promise((resolve, reject) => {
        const secondaryEmailAddresses = [
          {
            email_address: email,
            order: state.account.secondary_email_addresses
              ? state.account.secondary_email_addresses.length
              : 0,
          },
        ];
        putAccount({
          secondary_email_addresses: secondaryEmailAddresses,
        })
          .then(data => {
            dispatch(Actions.FETCH_ACCOUNT).finally(e => resolve());
          })
          .catch(errors => reject(errors));
      });
    },
    [Actions.EDIT_EMAIL]({ state, dispatch }, { from, to }) {
      return new Promise((resolve, reject) => {
        const order = state.account.secondary_email_addresses.findIndex(emailObject => {
          return emailObject.email_address.toLowerCase() === from.toLowerCase();
        });
        if (order === -1) reject();

        const secondaryEmailAddresses = [
          {
            email_address: to,
            order,
          },
        ];
        putAccount({
          secondary_email_addresses: secondaryEmailAddresses,
        })
          .then(data => {
            dispatch(Actions.FETCH_ACCOUNT).finally(e => resolve());
          })
          .catch(errors => reject(errors));
      });
    },
    [Actions.FETCH_PROFILE]({ state, commit }) {
      if (!state.account || !state.account.email_address) return;
      commit(Mutations.SET_PROFILE_STATUS, Progress.WORKING);
      getProfile(state.account.email_address)
        .then(data => {
          const profile = Object.keys(data).length === 0 ? null : data;
          commit(Mutations.SET_PROFILE, profile);
          commit(Mutations.SET_PROFILE_STATUS, Progress.COMPLETE);
        })
        .catch(error => {
          commit(Mutations.SET_PROFILE_STATUS, Progress.ERROR);
        });
    },
    [Actions.UPDATE_PROFILE]({ state, commit }, profile) {
      if (!state.profile || !state.profile.email || state.profileStatus === Progress.WORKING)
        return;
      clearTimeout(timeoutUpdateProfile);
      commit(Mutations.ASSIGN_PROFILE, profile);
      commit(Mutations.SET_PROFILE_UPDATING_STATUS, Progress.WORKING);
      timeoutUpdateProfile = setTimeout(() => {
        putProfile(state.profile.email, state.profile)
          .then(data => {
            commit(Mutations.SET_PROFILE_UPDATING_STATUS, Progress.COMPLETE);
          })
          .catch(error => {
            commit(Mutations.SET_PROFILE_UPDATING_STATUS, Progress.ERROR);
            dispatch(Actions.FETCH_PROFILE);
          });
      }, 800);
    },
    [Actions.UPDATE_BRANCH]({ state, commit, dispatch }, branch) {
      // TODO: Remove the branch.webid fallback below when the new BranchFinder endpoints are ready and used.
      const slug = branch.slug || branch.webid;
      if (
        state.accountStatus === Progress.WORKING ||
        state.accountUpdatingStatus === Progress.WORKING ||
        state.account?.branch_slug === slug
      ) {
        return;
      }
      commit(Mutations.SET_ACCOUNT_UPDATING_STATUS, Progress.WORKING);
      putAccount({ branch_slug: slug })
        .then(data => {
          Cookies.set('favShopClosestsWebId', slug);
          dispatch(Actions.FETCH_ACCOUNT);
          commit(Mutations.SET_ACCOUNT_UPDATING_STATUS, Progress.COMPLETE);
        })
        .catch(error => {
          dispatch(Actions.FETCH_ACCOUNT);
          commit(Mutations.SET_ACCOUNT_UPDATING_STATUS, Progress.ERROR);
        });
    },
    [Actions.DELETE_BRANCH]({ state, commit }) {
      if (
        state.accountStatus === Progress.WORKING ||
        state.accountUpdatingStatus === Progress.WORKING ||
        !state.account?.branch_slug
      )
        return;
      commit(Mutations.SET_ACCOUNT_UPDATING_STATUS, Progress.WORKING);
      putAccount({ branch_slug: '' })
        .then(data => {
          commit(Mutations.ASSIGN_ACCOUNT, { branch: null, branch_slug: null });
          commit(Mutations.SET_ACCOUNT_UPDATING_STATUS, Progress.COMPLETE);
        })
        .catch(error => {
          commit(Mutations.SET_ACCOUNT_UPDATING_STATUS, Progress.ERROR);
        });
    },
    [Actions.FETCH_OVERVIEW]({ state, commit }, { ignoreCooldown = false }) {
      return new Promise((resolve, reject) => {
        // Don't fetch overview if not enough time has passed
        if (!ignoreCooldown && Date.now() < state.latestOverviewUpdate + updateOverviewCooldown) {
          resolve();
        }
        getOverview()
          .then(data => {
            commit(Mutations.SET_OVERVIEW, data.overview);
            resolve();
          })
          .catch(error => {
            reject(error);
          });
      });
    },
  },

  mutations: {
    [Mutations.SET_HAS_FETCHED_ACCOUNT](state, hasFetchedAccount) {
      state.hasFetchedAccount = hasFetchedAccount;
    },
    [Mutations.SET_ACCOUNT_STATUS](state, status) {
      state.accountStatus = status;
    },
    [Mutations.SET_ACCOUNT](state, account) {
      state.account = account;
    },
    [Mutations.SET_ACCOUNT_UPDATING_STATUS](state, status) {
      state.accountUpdatingStatus = status;
    },
    [Mutations.ASSIGN_ACCOUNT](state, account) {
      Object.assign(state.account, account);
    },
    [Mutations.SET_PROFILE_STATUS](state, status) {
      state.profileStatus = status;
    },
    [Mutations.SET_PROFILE](state, profile) {
      state.profile = profile;
    },
    [Mutations.SET_PROFILE_UPDATING_STATUS](state, status) {
      state.profileUpdatingStatus = status;
    },
    [Mutations.ASSIGN_PROFILE](state, profile) {
      Object.assign(state.profile, profile);
    },
    [Mutations.SET_OVERVIEW](state, overview) {
      state.overview = overview;
      state.latestOverviewUpdate = Date.now();
    },
  },
};
