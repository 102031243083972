<template>
  <div class="OpeningHours">
    <i class="far fa-clock" />
    <span>{{ `${dayString}: ${specification.opens} – ${specification.closes}` }}</span>
  </div>
</template>

<script>
const weekdays = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];

export default {
  name: 'OpeningHours',

  props: {
    openingHours: {
      type: Array,
      required: true,
    },
    date: {
      type: String,
      required: true,
    },
  },

  computed: {
    day() {
      return new Date(this.date).getDay();
    },
    dayString() {
      if (this.day === 0) return this.$t('sunday');
      else if (this.day === 1) return this.$t('monday');
      else if (this.day === 2) return this.$t('tuesday');
      else if (this.day === 3) return this.$t('wednesday');
      else if (this.day === 4) return this.$t('thursday');
      else if (this.day === 5) return this.$t('friday');
      else if (this.day === 6) return this.$t('saturday');
      return '';
    },
    specification() {
      const specification = this.openingHours.find(item =>
        item.days_of_week.includes(weekdays[this.day]),
      );
      return specification ?? { opens: '00:00', closes: '00:00' };
    },
  },
};
</script>

<style lang="scss" scoped>
.OpeningHours {
  display: flex;
  align-items: center;
  gap: 5px;
  color: $color-prim-grey-dark;
}
</style>
