<script>
import helpers from '@helpers';
import settings from '@settings';

export default {
  name: 'Select',
  functional: true,

  props: {
    items: {
      required: true,
    },
    label: {
      required: false,
      default: '',
    },
    unit: {
      required: false,
      default: '',
    },
    defaultChoice: {
      required: false,
      default: '',
    },
    integrated: {
      required: false,
      default: false,
      type: Boolean,
    },
    action: {
      default: value => {
        if (settings.testmode) {
          console.log(
            'Value recieved. Remember to apply a method on the "action" prop for handling value change. New value: ',
            value,
            '!',
          );
        }
      },
      type: Function,
    },
    disabled: {
      required: false,
      default: false,
    },
  },

  render(e, context) {
    const { parent } = context;
    const { label, items, action, unit, defaultChoice, integrated, disabled } = context.props;

    return (
      <div class={context?.data?.staticClass}>
        <div
          class={[
            'Input',
            'Input--tight',
            'Select',
            integrated && 'Input--integrated',
            disabled && 'Select--disabled',
          ]}
        >
          {label && <label>{label}</label>}

          <select disabled={disabled} onChange={e => action(e.target.value)}>
            {defaultChoice && <option value={defaultChoice}>{defaultChoice}</option>}
            {items.map((item, index) => {
              return (
                <option key={item.value} value={item.value} selected={item.isRefined}>
                  {item.label + helpers.product.printIfHasUnit(unit)}
                </option>
              );
            })}
          </select>

          <div class="fa fa-chevron-down Select__icon"></div>
        </div>
      </div>
    );
  },
};
</script>

<style lang="scss">
.Select {
  display: flex;
  flex-direction: column;
  color: $color-black;

  select {
    position: relative;
    height: 44px;
    appearance: none;
    padding-right: 32px;
  }

  &--disabled select {
    background-color: $color-prim-grey-light;
    opacity: 1;
    &:hover {
      box-shadow: none;
      color: #999999;
      border-color: #999999;
    }
  }
}

.Select__icon {
  position: absolute;
  bottom: calc(44px / 2);
  right: 10px;
  height: 16px;
  color: $color-prim-grey-dark;
  transform: translateY(50%);
  pointer-events: none;
}

.Input--integrated {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;

  display: flex;
  flex-flow: row;

  select {
    width: 100px;

    border: 0px;
  }

  &::after {
    content: ' ';
    height: calc(100% - 20px);
    width: 1px;
    background-color: $color-extra-trace;
    margin-top: 10px;
    flex-shrink: 0;
    position: absolute;
    right: -9px;
    top: 0;
  }
}

.InnerWrapper {
  position: relative;
}

@mixin printStyles() {
  .Select {
    select {
      border-color: transparent;
    }

    select:after {
      content: '';
    }
  }
}

@media print {
  @include printStyles();
}

.ForcedPrint {
  @include printStyles();
}
</style>
