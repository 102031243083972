import { Api } from '../';

/**
 * Get drafts by type

 */
export function getCart(options) {
  return new Promise((resolve, reject) => {
    Api.get(`/cart`)
      .then(response => {
        resolve(response.data.data);
      })
      .catch(error => {
        reject(error.response.data);
      });
  });
}
/**
 * Adds items to the cart.
 * NB: Currently only supporting 1 item at a time as a legacy method is used.
 * @param {*} items
 */
export function postItems(items) {
  const item = items[0];
  return new Promise((resolve, reject) => {
    $.ajax({
      url: '/shoppingcart/?uoa=1&item=' + item.item_number + '&a=' + item.quantity + '&asjson=1',
    }).done(data => {
      if (typeof UpdateFrontCart2 === 'function') UpdateFrontCart2();
      resolve();
    });
  });
}
