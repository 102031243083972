export const disableScrollBar = () => {
  const scrollBarWidth = window.innerWidth - document.body.clientWidth;
  document.body.style.overflowY = 'hidden';
  document.body.style.paddingRight = `${scrollBarWidth}px`;
  // legacy
  const topBarHolder = document.querySelector('.TopBarHolder.sticky-top');
  if (topBarHolder) topBarHolder.style.paddingRight = `${scrollBarWidth}px`;
};

export const enableScrollBar = () => {
  // style.overflowY = 'auto' is a compatibility fix for Internet Explorer
  document.body.style.overflowY = 'auto';
  document.body.style.overflowY = null;
  document.body.style.paddingRight = null;
  // legacy
  const topBarHolder = document.querySelector('.TopBarHolder');
  if (topBarHolder) topBarHolder.style.paddingRight = null;
};
