import Features from '@types/Features';

export default {
  name: 'NavigationContainer',

  computed: {
    routes() {
      const filterRoutes = routes => {
        return routes.reduce((filtred, route) => {
          if (
            route.meta?.hideFromNavigation ||
            (route.meta?.featureRequired?.key && !this.$isEnabled(route.meta.featureRequired.key))
          ) {
            return filtred;
          }

          if (route.children) {
            filtred.push({ ...route, children: filterRoutes(route.children) });
          } else {
            filtred.push({ ...route });
          }

          return filtred;
        }, []);
      };
      return filterRoutes(this.$router.options.routes);
    },
    selfserviceRoutes() {
      const baseRoute = this.routes.find(route => route.name === '/selfservice');
      if (baseRoute) return [baseRoute, ...baseRoute.children];
      return [];
    },
    isExactActive() {
      return route => this.$route.name === route.name;
    },
  },

  created() {},
  mounted() {},
  methods: {},

  render() {
    return this.$scopedSlots.default({
      routes: this.routes,
      selfserviceRoutes: this.selfserviceRoutes,
      isExactActive: this.isExactActive,
    });
  },

  provide() {
    return {
      navigationContainer: this,
    };
  },
};
