<template>
  <div :class="['StepProgressBar', `StepProgressBar--${alignment}`, `StepProgressBar--${size}`]">
    <slot name="default" />
  </div>
</template>

<script>
import { StepAlignment, StepSize } from '../../types';

export default {
  name: 'StepProgressBar',

  props: {
    activeStep: {
      type: String,
      default: '',
    },
    completeSteps: {
      type: Array,
      default: undefined,
    },
    activeIsComplete: {
      type: Boolean,
      default: false,
    },
    numbered: {
      type: Boolean,
      default: false,
    },
    alignment: {
      type: String,
      default: StepAlignment.LEFT,
    },
    size: {
      type: String,
      default: StepSize.LARGE,
    },
    clickable: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      indexOfLastTouched: -1,
    };
  },

  watch: {
    activeStep() {
      this.update();
    },
    completeSteps() {
      this.update();
    },
  },

  methods: {
    update() {
      this.updateStepIndexes();
      this.updateIndexOfLastTouched();
    },
    updateStepIndexes() {
      let step = this.firstStep();
      for (let i = 0; step; i++) {
        step.stepIndex = i;
        step = this.nextStep(step);
      }
    },
    updateIndexOfLastTouched() {
      let step = this.lastStep();
      while (step) {
        if (step.id === this.activeStep || this.completeSteps?.includes(step.id)) break;
        step = this.previousStep(step);
      }
      this.indexOfLastTouched = step?.stepIndex ?? -1;
    },
    firstStep() {
      return this.$children.find(x => !x.$el.previousElementSibling);
    },
    lastStep() {
      return this.$children.find(x => !x.$el.nextElementSibling);
    },
    previousStep(step) {
      return this.$children.find(x => x.$el === step.$el.previousElementSibling);
    },
    nextStep(step) {
      return this.$children.find(x => x.$el === step.$el.nextElementSibling);
    },
    emitStepClick(id) {
      this.$emit('click', id);
    },
  },

  provide() {
    return {
      stepProgressBar: this,
    };
  },
};
</script>

<style lang="scss" scoped>
.StepProgressBar {
  display: flex;
  width: 100%;
}
.StepProgressBar--vertical {
  flex-direction: column;
}
.StepProgressBar--small {
  font-size: 12px;
}
.StepProgressBar--medium {
  font-size: 14px;
}
.StepProgressBar--large {
  font-size: 16px;
}
</style>
