<template>
  <div class="Slides">
    <slot name="default" />
  </div>
</template>

<script>
import { gsap } from 'gsap/all';

export default {
  name: 'Slides',

  data() {
    return {
      currentSlideId: undefined,
    };
  },

  watch: {
    currentSlideId(to, from) {
      if (to !== from) this.$emit('change', to);
    },
  },

  mounted() {
    this.currentSlideId = this.$children[0]?.id;
  },

  methods: {
    slideTo(id) {
      this.currentSlideId = id;
    },
    next() {
      const children = this.$children.filter(x => x.$options.name === 'Slide');
      const currentSlide = children.find(x => x.id === this.currentSlideId);
      const nextSlide = children.find(x => x.$el === currentSlide.$el.nextElementSibling);
      this.currentSlideId = nextSlide?.id;
      this.scrollToTop();
    },
    scrollToTop() {
      gsap.to(window, { duration: 0.5, delay: 0.0, scrollTo: { y: 0 } });
    },
  },

  provide() {
    return {
      slides: this,
    };
  },
};
</script>

<style lang="scss" scoped>
.Slides {
  position: relative;
  overflow: hidden;
}
</style>
