function trackingMethodExists(method) {
  return window.tracking && window.tracking[method];
}
export default {
  install(Vue, options) {
    Vue.prototype.$tracking = (method, payload) => {
      if (trackingMethodExists(method)) {
        return window.tracking[method];
      } else {
        return () => {
          console.warn('tracking method not defined');
        };
      }
    };
  },
};
