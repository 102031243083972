<template>
  <div class="BranchCardEmpty">
    <div class="BranchCardEmpty__content">
      <Button :color="colors.WHITE" @click="$emit('click')">
        <slot />
      </Button>
    </div>
    <div class="BranchCardEmpty__image" />
  </div>
</template>

<script>
import { Button, sizes, colors } from '@components/Buttons';

export default {
  name: 'BranchCardEmpty',

  components: {
    Button,
  },

  enums: {
    sizes,
    colors,
  },
};
</script>

<style lang="scss" scoped>
.BranchCardEmpty {
  display: flex;
  justify-content: space-between;
  width: 100%;
  overflow: hidden;

  .BranchCardEmpty__content {
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 1;
    padding: 20px;
    border-top: 3px dashed;
    border-left: 3px dashed;
    border-bottom: 3px dashed;
    border-radius: 5px;
    color: $color-prim-grey;
  }

  .BranchCardEmpty__image {
    width: 124px;
    height: 124px;
    background-color: $color-prim-grey;
    border-radius: 0px 5px 5px 0px;
  }
}
</style>
