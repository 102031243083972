<template functional>
  <div :class="['TreeNavigation']">
    <ul class="TreeNavigation" role="tree" aria-labelledby="tree-navigation">
      <TreeNavigationItem
        v-for="(node, index) in props.nodes"
        :key="index"
        :item="node"
        :level="1"
      />
    </ul>
  </div>
</template>

<script>
// https://www.w3.org/TR/wai-aria-practices/examples/treeview/treeview-2/treeview-2a.html

export default {
  name: 'TreeNavigation',
  functional: true,

  props: {
    nodes: {
      type: Array,
      default() {
        return [];
      },
    },
  },
};
</script>
