export const rangeToValue = (value, range) => {
  let valueMin = value.min;
  let valueMax = value.max;

  // Check if values are either null or below the allowed range
  if (valueMin === null || valueMin === undefined || valueMin < range.min) {
    valueMin = range.min;
  }

  if (valueMax === null || valueMax === undefined || valueMax > range.max) {
    valueMax = range.max;
  }

  // Return value
  return [valueMin, valueMax];
};
