<template functional>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100" class="Spinner">
    <circle
      xmlns="http://www.w3.org/2000/svg"
      cx="50"
      cy="50"
      fill="none"
      :stroke-width="props.width"
      r="30"
      stroke-dasharray="70"
    ></circle>
  </svg>
</template>

<script>
export default {
  name: 'Spinner',
  props: {
    width: {
      type: Number,
      default: 4,
    },
  },
};
</script>

<style lang="scss" scoped>
@keyframes spinnerRotation {
  0% {
    transform: rotate(0deg);
    opacity: 1;
  }
  50% {
    opacity: 0.4;
  }
  100% {
    transform: rotate(360deg);
    opacity: 1;
  }
}

.Spinner {
  //transform-origin: 50% 50%;
  width: 100%;
  height: 100%;
  display: inline-block;
  vertical-align: middle;
  stroke: #2d4f8e;
  position: relative;

  circle {
    stroke: inherit;
    animation: spinnerRotation 0.8s linear infinite;
    transform-origin: center;
    transform-box: fill-box;
    will-change: transform;
  }
}
.Spinner--md {
  width: 30px;
  height: 30px;
}
.Spinner--lg {
  width: 40px;
  height: 40px;
}
.Spinner--xl {
  width: 60px;
  height: 60px;
}
</style>
