import { getNearestByQuery, getAll } from '@services/thansen/servicepoints';
import Progress from '@types/Progress';

export const Actions = {
  FETCH_STORES: 'FETCH_STORES',
  SEARCH: 'SEARCH',
};

const Mutations = {
  SET_STATUS: 'SET_STATUS',
  SET_STORES: 'SET_STORES',
  SET_SEARCHPOINT: 'SET_SEARCHPOINT',
  CLEAR_SEARCHPOINT: 'CLEAR_SEARCHPOINT',
};

export default {
  namespaced: true,

  state: {
    status: '',
    stores: [],
    searchPoint: null,
  },

  actions: {
    [Actions.FETCH_STORES]({ commit }) {
      commit(Mutations.SET_STATUS, Progress.WORKING);

      getAll()
        .then(stores => {
          commit(Mutations.SET_STORES, stores);
          commit(Mutations.SET_STATUS, Progress.COMPLETE);
        })
        .catch(err => {
          commit(Mutations.SET_STATUS, Progress.ERROR);
        });
    },
    [Actions.SEARCH]({ commit }, query) {
      commit(Mutations.SET_STATUS, Progress.WORKING);
      commit(Mutations.CLEAR_SEARCHPOINT);
      getNearestByQuery(query)
        .then(({ service_points, base_point }) => {
          commit(Mutations.SET_SEARCHPOINT, base_point);
          commit(Mutations.SET_STORES, service_points);
          commit(Mutations.SET_STATUS, Progress.COMPLETE);
        })
        .catch(err => {
          commit(Mutations.SET_STATUS, Progress.ERROR);
        });
    },
  },

  mutations: {
    [Mutations.SET_STATUS](state, status) {
      state.status = status;
    },
    [Mutations.SET_STORES](state, stores) {
      state.stores = stores;
    },
    [Mutations.SET_SEARCHPOINT](state, searchPoint) {
      state.searchPoint = searchPoint;
    },
    [Mutations.CLEAR_SEARCHPOINT](state) {
      state.searchPoint = null;
    },
  },
};
