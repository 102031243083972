const Tracking = {
  addEvent(category, action, label, value) {
    if (typeof window.thgGA !== 'undefined') {
      window.thgGA.event(category, action, label.toString(), value);
    }
  },
  addPageview(path) {
    if (typeof window.thgGA !== 'undefined') {
      window.thgGA.pageview(path);
    }
  },
};
export default Tracking;
