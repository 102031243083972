<template>
  <component
    :is="branch.url ? 'a' : 'div'"
    :href="branch.url ? branch.url : null"
    :title="branch.url ? branch.name : null"
    :class="['BranchCardSmall', branch.url && 'BranchCardSmall--clickable']"
  >
    <div v-if="!hideImage && branch.image" class="BranchCardSmall__image">
      <ImageComponent :src="branch.image" :alt="branch.name" aspect-ratio="1:1" fit="cover" />
    </div>
    <div class="BranchCardSmall__content">
      <span class="BranchCardSmall__title">{{ branch.name }}</span>
      <Address :data="branch.address" class="BranchCardSmall__address" />
      <template v-if="hasOpeningHours && !hideOpeningHours">
        <OpeningHoursStatus
          v-if="!date || dateIsTodayOrEarlier"
          :openingHours="branch.opening_hours_specification.opening_hours"
          class="BranchCardSmall__openingHours"
        />
        <OpeningHours
          v-else-if="dateIsWithinAWeekOrEarlier"
          :openingHours="branch.opening_hours_specification.opening_hours"
          :date="date"
          class="BranchCardSmall__openingHours"
        />
      </template>
    </div>
  </component>
</template>

<script>
import dayjs from 'dayjs';

import ImageComponent from '@components/ImageComponent';
import Address from '@components/Address';
import OpeningHoursStatus from '@components/OpeningHoursStatus';

import OpeningHours from './components/OpeningHours';

export default {
  name: 'BranchCardSmall',

  components: {
    ImageComponent,
    Address,
    OpeningHoursStatus,
    OpeningHours,
  },

  props: {
    branch: {
      type: Object,
      required: true,
    },
    hideImage: {
      type: Boolean,
      default: false,
    },
    hideOpeningHours: {
      type: Boolean,
      default: false,
    },
    date: {
      type: String,
      default: undefined,
    },
  },

  computed: {
    hasOpeningHours() {
      return this.branch.opening_hours_specification?.opening_hours;
    },
    daysUntilDate() {
      if (!this.date) return undefined;
      const today = dayjs().startOf('day');
      const date = dayjs(this.date).startOf('day');
      return date.diff(today, 'day');
    },
    dateIsTodayOrEarlier() {
      if (!this.date) return false;
      return this.daysUntilDate <= 0;
    },
    dateIsWithinAWeekOrEarlier() {
      if (!this.date) return false;
      return this.daysUntilDate <= 6;
    },
  },
};
</script>

<style lang="scss" scoped>
.BranchCardSmall {
  display: flex;
  gap: 13px;
  text-decoration: none;
  font-weight: normal;
  font-size: 14px;

  .BranchCardSmall__image {
    width: 55px;
  }

  .BranchCardSmall__content {
    display: flex;
    flex-direction: column;
    overflow: hidden;
  }

  .BranchCardSmall__title {
    font-weight: bold;
    color: $color-prim-blue;
    font-size: 1.1em;
    line-height: 1em;
  }

  &--clickable:hover .BranchCardSmall__title {
    text-decoration: underline;
  }

  .BranchCardSmall__address {
    color: $color-prim-grey-dark;
  }

  .BranchCardSmall__openingHours {
    margin-top: 0.2em;
  }
}
</style>
