export const Mutation = {
  UPDATE_PRODUCTSTATUS: 'UPDATE_PRODUCTSTATUS',
  ADD_PRODUCTSTATUS: 'ADD_PRODUCTSTATUS',
  SET_PRODUCTSTATUS_STATUS: 'SET_PRODUCTSTATUS_STATUS',
  CLEANUP_PRODUCTSTATUS: 'CLEANUP_PRODUCTSTATUS',
  RESET: 'RESET',
};

export const Action = {
  EXAMPLE_MUTATION: 'TESTING',
};
