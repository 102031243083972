<template>
  <component :is="tag" class="DropdownSection">
    <slot name="default" />
  </component>
</template>

<script>
export default {
  name: 'DropdownSection',

  props: {
    tag: {
      type: String,
      default: 'div',
    },
  },
};
</script>

<style lang="scss" scoped>
.DropdownSection {
  display: flex;
  flex-direction: column;
  margin: 0;
  border-top: 1px solid $color-prim-grey;
  padding: 5px 0px;
  &:first-child {
    border-top: none;
  }
}
</style>
