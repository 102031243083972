import da from './da';
import da_addanmark from './da-addanmark';
import no from './no';

import routes from './routes';

export default {
  da: {
    ...da,
    ...routes.da,
  },
  da_addanmark: {
    ...da,
    ...routes.da, //_addanmark,
  },
  no: {
    ...no,
    ...routes.no,
  },
};
