<template>
  <Dropdown :locked="locked" :toggleOnClick="minimal" class="UserDropdown">
    <template #default>
      <slot name="default" />
    </template>
    <template #content>
      <DropdownSection>
        <a :href="$router.resolve({ name: '/selfservice/profile' }).href">
          <UserCard
            :name="`${account.first_name} ${account.last_name}`"
            :email="account.email_address"
            :size="CardSize.SMALL"
            clickable
            class="UserDropdown__userCard"
          />
        </a>
      </DropdownSection>

      <DropdownSection v-if="!minimal">
        <div class="UserDropdown__branch">
          <div class="UserDropdown__branchHeader">
            <span>{{ $t('account.branch') }}</span>
            <button
              v-if="$isEnabled(Features.ACCOUNT_PROFILE_BRANCH) && branch"
              class="UserDropdown__branchEdit"
              @click="$refs.favoriteStoreSelect.open()"
            >
              <span>{{ $t('profile.controls.edit_branch--short') }}</span>
              <i class="far fa-edit" />
            </button>
          </div>
          <BranchCardSmall v-if="branch" :branch="branch" hideImage />
          <button
            v-else-if="$isEnabled(Features.ACCOUNT_PROFILE_BRANCH) && !branch"
            @click="$refs.favoriteStoreSelect.open()"
            class="UserDropdown__branchButton"
          >
            {{ $t('profile.controls.choose_branch') }}
          </button>
        </div>
      </DropdownSection>

      <NavigationContainer v-if="!minimal" v-slot="{ selfserviceRoutes }">
        <DropdownSection tag="ul">
          <li v-for="route in selfserviceRoutes" :key="route.name">
            <DropdownButton
              :href="$router.resolve({ name: route.name }).href"
              :title="route.meta.title"
            >
              {{ route.meta.title }}
            </DropdownButton>
          </li>
        </DropdownSection>
      </NavigationContainer>

      <DropdownSection>
        <DropdownButton @click="logout">{{ $t('account.auth.log_out') }}</DropdownButton>
      </DropdownSection>

      <FavoriteStoreSelect
        ref="favoriteStoreSelect"
        @open="locked = true"
        @close="locked = false"
      />
    </template>
  </Dropdown>
</template>

<script>
import Features from '@types/Features';
import { postLogout } from '@services/thansen/authentication';
import NavigationContainer from '@containers/NavigationContainer';
import Dropdown, { DropdownSection, DropdownButton } from '@components/Dropdown';
import { Button } from '@components/Buttons';
import { BranchCardSmall } from '@components/Cards';
import FavoriteStoreSelect from '@components/FavoriteStoreSelect';
import UserCard, { Sizes as CardSize } from '@scenes/SelfserviceScene/components/UserCard';

export default {
  name: 'UserDropdown',

  components: {
    NavigationContainer,
    Dropdown,
    DropdownSection,
    DropdownButton,
    Button,
    BranchCardSmall,
    FavoriteStoreSelect,
    UserCard,
  },

  enums: {
    Features,
    CardSize,
  },

  props: {
    minimal: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      locked: false,
    };
  },

  computed: {
    account() {
      return this.accountContainer.account;
    },
    branch() {
      return this.accountContainer.branch;
    },
  },

  methods: {
    logout() {
      postLogout().then(() => (window.location.href = '/'));
    },
  },

  inject: {
    accountContainer: {
      default() {
        console.error('Missing AccountContainer');
        return;
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.UserDropdown {
  .UserDropdown__userCard {
    padding: 10px 20px 10px 20px;
  }

  .UserDropdown__branch {
    display: flex;
    flex-direction: column;
    padding: 10px 20px;
    gap: 1px;
  }

  .UserDropdown__branchHeader {
    color: black;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .UserDropdown__branchEdit {
    display: flex;
    align-items: center;
    gap: 5px;
    padding: 2px 0px 2px 10px;
    font-style: italic;
    background: none;
    outline: none;
    border: none;
    cursor: pointer;
    color: #bbbbbb;
    &:hover {
      color: $color-prim-black-light;
    }
  }

  .UserDropdown__branchButton {
    display: flex;
    border: none;
    padding: 5px 0px 0px 0px;
    font-weight: bold;
    background: none;
    color: $color-prim-blue;
    &:hover {
      text-decoration: underline;
    }
  }
}
</style>
