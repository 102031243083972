import Features from '@types/Features';
import { Modes } from '@components/Login';
let routes = [
  {
    name: '/link/order/:id',
    path: '/link/order/:id',
    meta: { icon: 'file-spreadsheet', anonymous: true },
    component: () => import(`@scenes/SelfserviceScene/scenes/OrderScene`),
  },
  {
    name: '/link/wheelstorage/:id',
    path: '/link/wheelstorage/:id',
    meta: { icon: 'file-spreadsheet' },
    component: () => import(`@scenes/SelfserviceScene/scenes/WheelStorageWheelScene`),
  },
  {
    name: '/login',
    path: '/login',
    meta: { anonymous: true, mode: Modes.LOGIN },
    children: [
      {
        path: 'signup',
        meta: { mode: Modes.SIGNUP },
      },
      {
        path: 'resetpassword',
        meta: { mode: Modes.REQUEST_PASSWORD_CHANGE },
      },
      {
        path: 'password',
        meta: { mode: Modes.PASSWORD_CHANGE },
      },
      {
        path: 'complete_signup',
        meta: { mode: Modes.COMPLETE_SIGNUP },
      },
    ],
    component: () => import(`@scenes/LoginScene`),
  },

  {
    name: '/booking',
    path: '/booking',
    children: [
      {
        path: 'wheelstorage',
      },
    ],
    component: () => import(`@scenes/BookingScene/scenes/WheelStorageBookingScene`),
  },

  {
    name: '/club',
    path: '/club',
    meta: { anonymous: true, mode: Modes.SIGNUP },
    component: () => import(`@scenes/LoginScene`),
  },

  {
    // Name will be added automatically when the routes is translated/localized.
    path: '/selfservice', // It's an ID and it will be translated - see router/routes/index.js
    component: () => import('@scenes/SelfserviceScene'),
    meta: {
      // An local (translated) title will be added automatically. Check routes in the translations file.
      icon: 'home', // The icon will be used in navigation lists etc.
    },
    children: [
      {
        path: 'orders', // will be translated.
        meta: { icon: 'file-spreadsheet', previewToAnonymous: true },
        component: () => import(`@scenes/SelfserviceScene/scenes/OrdersScene`),
      },
      {
        path: 'orders/:id',
        meta: { hideFromNavigation: true },
        component: () => import(`@scenes/SelfserviceScene/scenes/OrderScene`),
      },
      {
        path: 'productcomplaint',
        meta: { icon: 'file-signature', featureRequired: { key: Features.ACCOUNT_COMPLAINT } },
        component: () => import(`@scenes/SelfserviceScene/scenes/ProductComplaintScene`),
      },
      {
        path: 'productcomplaint/:id',
        meta: {
          hideFromNavigation: true,
          featureRequired: { key: Features.ACCOUNT_COMPLAINT },
        }, // Hiding the route from navigation, since its a dynamic page (:id)
        component: () => import(`@scenes/SelfserviceScene/scenes/RmaScene`),
      },
      {
        path: 'productcomplaint/:id/print',
        props: { print: true },
        meta: {
          hideFromNavigation: true,
          featureRequired: { key: Features.ACCOUNT_COMPLAINT },
        }, // Hiding the route from navigation, since its a dynamic page (:id)
        component: () => import(`@scenes/SelfserviceScene/scenes/RmaScene`),
      },
      {
        path: 'returns',
        meta: { icon: 'undo', featureRequired: { key: Features.ACCOUNT_RETURNS } },
        component: () => import(`@scenes/SelfserviceScene/scenes/ReturnsScene`),
      },
      {
        path: 'returns/:id',
        meta: { hideFromNavigation: true, featureRequired: { key: Features.ACCOUNT_RETURNS } },
        component: () => import(`@scenes/SelfserviceScene/scenes/RmaScene`),
      },
      {
        path: 'returns/:id/print',
        props: { print: true },
        meta: { hideFromNavigation: true, featureRequired: { key: Features.ACCOUNT_RETURNS } },
        component: () => import(`@scenes/SelfserviceScene/scenes/RmaScene`),
      },
      {
        path: 'carspot',
        meta: { icon: 'car', featureRequired: { key: Features.ACCOUNT_CARSPOT } },
        component: () => import(`@scenes/SelfserviceScene/scenes/CarSpotScene`),
        beforeEnter(to, from, next) {
          // Put the full page URL including the protocol http(s) below
          window.location.href = '/varekatalog/carspot-intranet/n-237396475';
        },
      },
      {
        path: 'specialdeliveries',
        meta: {
          icon: 'truck',
          featureRequired: { key: Features.ACCOUNT_DELIVERY },
        },
        component: () => import(`@scenes/SelfserviceScene/scenes/SpecialDeliveryScene`),
      },
      {
        path: 'postings',
        meta: { icon: 'file-invoice', featureRequired: { key: Features.ACCOUNT_TRANSACTIONS } },

        component: () => import(`@scenes/SelfserviceScene/scenes/PostingsScene`),
      },
      {
        path: 'drafts',
        meta: { icon: 'shopping-cart', featureRequired: { key: Features.ACCOUNT_DRAFTS } },

        component: () => import(`@scenes/SelfserviceScene/scenes/DraftsScene`),
      },
      {
        path: 'drafts/:id',
        meta: { hideFromNavigation: true, featureRequired: { key: Features.ACCOUNT_DRAFTS } },

        component: () => import(`@scenes/SelfserviceScene/scenes/DraftScene`),
      },
      {
        path: 'favorites',
        meta: { icon: 'shopping-cart', featureRequired: { key: Features.ACCOUNT_FAVORITES } },

        component: () => import(`@scenes/SelfserviceScene/scenes/FavoritesScene`),
      },
      {
        path: 'favorites/:id',
        meta: { hideFromNavigation: true, featureRequired: { key: Features.ACCOUNT_FAVORITES } },

        component: () => import(`@scenes/SelfserviceScene/scenes/FavoritesScene`),
      },
      {
        path: 'wheelstorage',
        meta: { icon: 'tire', featureRequired: { key: Features.ACCOUNT_WHEELSTORAGE } },

        component: () => import(`@scenes/SelfserviceScene/scenes/WheelStorageScene`),
      },
      {
        path: 'bookings',
        meta: { icon: 'tire', featureRequired: { key: Features.ACCOUNT_BOOKINGS } },

        component: () => import(`@scenes/SelfserviceScene/scenes/BookingsScene`),
      },
      {
        path: 'garage',
        meta: {
          icon: 'car-garage',
          previewToAnonymous: true,
          featureRequired: { key: Features.ACCOUNT_GARAGE },
        },

        component: () => import(`@scenes/SelfserviceScene/scenes/GarageScene`),
      },

      {
        path: 'users',
        meta: { icon: 'users', featureRequired: { key: Features.ACCOUNT_USERS } },

        component: () => import(`@scenes/SelfserviceScene/scenes/UsersScene`),
      },
      {
        path: 'settings',
        meta: { icon: 'cogs', featureRequired: { key: Features.ACCOUNT_SETTINGS } },

        component: () => import(`@scenes/SelfserviceScene/scenes/SettingsScene`),
      },
      {
        path: 'profile',
        meta: { hideFromNavigation: true, featureRequired: { key: Features.ACCOUNT_PROFILE } },

        component: () => import(`@scenes/SelfserviceScene/scenes/ProfileScene`),
      },
    ],
  },
];

export default routes;
