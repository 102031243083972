export default {
  ACCOUNT_RETURNS: 'ACCOUNT_RETURNS',
  ACCOUNT_COMPLAINT: 'ACCOUNT_COMPLAINT',
  ACCOUNT_TRANSACTIONS: 'ACCOUNT_TRANSACTIONS',
  ACCOUNT_GARAGE: 'ACCOUNT_GARAGE',

  ACCOUNT_WHEELSTORAGE: 'ACCOUNT_WHEELSTORAGE',
  ACCOUNT_WHEELSTORAGE_TRANSFER: 'ACCOUNT_WHEELSTORAGE_TRANSFER',

  ACCOUNT_USERS: 'ACCOUNT_USERS',
  ACCOUNT_PROFILE: 'ACCOUNT_PROFILE',
  ACCOUNT_DRAFTS: 'DRAFTS',
  ACCOUNT_CARSPOT: 'ACCOUNT_CARSPOT',
  ACCOUNT_SETTINGS: 'ACCOUNT_SETTINGS',
  ACCOUNT_FAVORITES: 'ACCOUNT_FAVORITES',
  ACCOUNT_BOOKINGS: 'ACCOUNT_BOOKINGS',

  VEHICLE_ID_VISIBLE: 'VEHICLE_ID_VISIBLE',

  ACCOUNT_DELIVERY: 'ACCOUNT_DELIVERY',

  ACCOUNT_PROFILE_BRANCH: 'ACCOUNT_PROFILE_BRANCH',

  ACCOUNT_PROFILE_NEWSLETTER: 'ACCOUNT_PROFILE_NEWSLETTER',

  QR_SCANNERS: 'QR_SCANNERS',

  ORDER_TRANSFER: 'ORDER_TRANSFER',

  WHEELSTORAGE: 'WHEELSTORAGE',

  PRODUCT_STATUS: 'PRODUCT_STATUS',

  FIREWORKS_CLUB_BENEFITS: 'FIREWORKS_CLUB_BENEFITS',
  FIREWORKS_QUICK_NUMBER: 'FIREWORKS_QUICK_NUMBER',
};
