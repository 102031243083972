<template>
  <component
    :is="branch.url ? 'a' : 'div'"
    :href="branch.url ? branch.url : null"
    :title="branch.url ? branch.name : null"
    :class="['BranchCardLarge', branch.url && 'BranchCardLarge--clickable']"
  >
    <ImageComponent :src="branch.image" :alt="branch.name" fit="cover" />
    <div class="BranchCardLarge__content">
      <div>
        <span class="BranchCardLarge__title ft-h4">{{ branch.name }}</span>
        <Address :data="branch.address" class="ft-p3" />
      </div>

      <div v-if="branch.opening_hours_specification" class="BranchCardLarge__sectionContainer">
        <span class="ft-h6">{{ $t('opening_hours') }}</span>
        <OpeningHoursWeek
          class="ft-p3"
          :openingHours="branch.opening_hours_specification.opening_hours"
        />
      </div>
    </div>
  </component>
</template>

<script>
import ImageComponent from '@components/ImageComponent';
import Address from '@components/Address';
import OpeningHoursWeek from './components/OpeningHoursWeek';

export default {
  name: 'BranchCardLarge',

  components: {
    ImageComponent,
    Address,
    OpeningHoursWeek,
  },

  props: {
    branch: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.BranchCardLarge {
  border: 1px solid $color-prim-grey;
  min-width: 180px;
  max-width: 280px;
  width: 100%;
  text-decoration: none;
  border-radius: $border-radius;
  overflow: hidden;

  .BranchCardLarge__content {
    display: flex;
    flex-direction: column;
    @include spacing-2('gap' 'padding');
  }

  &--clickable:hover .BranchCardLarge__title {
    text-decoration: underline;
  }

  .BranchCardLarge__sectionContainer {
    display: flex;
    flex-direction: column;
    width: 200px;
  }
}
</style>
