/* global gloLanguage */
import Vue from 'vue';
import VueI18n from 'vue-i18n';
import helpers from '@helpers';
import danish from './translations/da.json';
import norwegian from './translations/no.json';
import vauda from './translations/vau-da.json';

// New locale
import locale from './locale';

Vue.use(VueI18n);

const numberFormats = {
  da: {
    currency: {
      style: 'decimal',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    },
    number: {
      style: 'decimal',
      minimumFractionDigits: 0,
      maximumFractionDigits: 2,
    },
  },
  no: {
    currency: {
      style: 'decimal',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    },
    number: {
      style: 'decimal',
      minimumFractionDigits: 0,
      maximumFractionDigits: 2,
    },
  },
};

let language = gloLanguage || 'da';

if (language === 'nb') {
  language = 'no';
} else if (window.globalvar_domainCode === 'VAU') {
  language = 'vau-da';
}

// Global messages
const productFilterData = window['productFilterData'];

// Fetch labels from generate JSON for filtering data
const labels =
  productFilterData !== undefined
    ? helpers.manipulation.flattenObject(
        Object.keys(productFilterData).map(key => ({
          ...Object.keys(productFilterData[key]).map(child => ({
            ['sheets.' + key + '.' + child]: productFilterData[key][child].name,
          })),
        })),
      )
    : {};

// Add same data to all languages, since the label is returned by the  server
const messages = {
  da: { ...danish, ...locale.da, ...labels },
  no: { ...danish, ...norwegian, ...locale.no, ...labels },
  'vau-da': { ...danish, ...vauda, ...locale.da_addanmark, ...labels },
};

export default new VueI18n({
  locale: language,
  fallbackLocale: 'da',
  messages,
  numberFormats,
});

// Maybe an idea for future handling: https://github.com/kazupon/vue-i18n/issues/677
