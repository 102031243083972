<template>
  <div :class="['StoreOption', selected && 'is-selected']" @click="$emit('click')">
    <div class="StoreOption__content">
      <div class="StoreOption__info">
        <div class="StoreOption__name">{{ name }}</div>
        <div v-if="address" class="StoreOption__address">
          <Address :data="address" />
        </div>
      </div>
      <div class="StoreOption__check">
        <i class="far fa-check" />
      </div>
    </div>
    <div class="StoreOption__alertWrapper">
      <div class="StoreOption__alert" v-if="alertMessage">
        <i class="far fa-exclamation-circle" />
        {{ alertMessage }}
      </div>
    </div>
  </div>
</template>

<script>
import Address from '@components/Address';

export default {
  name: 'StoreOption',

  components: {
    Address,
  },

  props: {
    name: {
      type: String,
      required: true,
    },
    address: {
      type: Object,
      default: null,
    },
    selected: {
      type: Boolean,
      default: false,
    },
    alertMessage: {
      type: String,
      default: '',
    },
  },
};
</script>

<style lang="scss" scoped>
.StoreOption {
  cursor: pointer;
  display: block;
  border: 1px solid $color-prim-grey;
  border-radius: 2px;
  padding: 0.8em 1.4em;
  transition: background 200ms ease-out;

  @include maxBreakpoint(768) {
    font-size: 12px;
  }

  .StoreOption__content {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .StoreOption__check {
    color: $color-prim-grey-light;
    font-size: 2em;
    opacity: 0;
    transition: all 100ms ease-out;
  }

  &:hover {
    border-color: $color-prim-grey-dark;
    .StoreOption__check {
      opacity: 0.7;
    }
  }

  &.is-selected {
    background: $color-prim-grey-light;
    border-color: $color-prim-grey-dark;
    .StoreOption__check {
      opacity: 1;
      color: $color-cta;
    }
  }

  .StoreOption__name {
    font-weight: bold;

    font-size: 1.2em;
  }

  .StoreOption__address {
    color: $color-prim-grey-dark;
  }

  .StoreOption__alertWrapper {
    display: flex;
  }

  .StoreOption__alert {
    background-color: #ffec00;
    padding: 2px 10px;
  }
}
</style>
