import { Api } from '..';

export function getAccountForms() {
  return new Promise((resolve, reject) => {
    Api.get(`/forms/account/countries`)
      .then(response => {
        resolve({ countries: response.data.data });
      })
      .catch(error => {
        reject(error);
      });
  });
}

export function getTexts(label) {
  return new Promise((resolve, reject) => {
    Api.get(`/forms/texts/${label}`)
      .then(response => {
        resolve(response.data.data);
      })
      .catch(error => {
        reject(error);
      });
  });
}
