<template>
  <div class="ProductCard__labels">
    <div>
      <template v-if="demo">
        <div class="ProductCard__label">
          <div :class="['PriceLabel', 'PriceLabel--demo']">
            {{ label || $t('label-demo') }}
          </div>
        </div>
        <div v-if="isOnSale" class="ProductCard__label">
          <div :class="['PriceLabel', 'PriceLabel--sale']">
            {{ $t('save') }}
            <span v-if="isOnSale && settings.mode !== 'b2b'">{{ salePercent }}%</span>
          </div>
        </div>
      </template>

      <div
        v-else-if="
          label.toLowerCase() === 'black week' ||
            label.toLowerCase() === 'black weekend' ||
            label.toLowerCase() === 'black friday'
        "
        class="ProductCard__label"
      >
        <div :class="['PriceLabel', 'PriceLabel--bf']">
          {{ label }}
          <span v-if="isOnSale && settings.mode !== 'b2b'">{{ salePercent }}%</span>
        </div>
      </div>

      <div v-else-if="labelType && labelType !== 'new'" class="ProductCard__label">
        <div v-if="isClearance" :class="['PriceLabel', 'PriceLabel--outlet']">
          {{ label || $t('label-clearance') }}
          <span v-if="isOnSale && settings.mode !== 'b2b'">-{{ salePercent }}%</span>
        </div>
        <div v-else :class="['PriceLabel', 'PriceLabel--' + labelType]">
          {{ label || $t('label-' + labelType) }}
          <span v-if="isOnSale && settings.mode !== 'b2b'">-{{ salePercent }}%</span>
        </div>
      </div>

      <div v-if="popularity && popularity > 0.991" class="ProductCard__label">
        <div :class="['PriceLabel', 'PriceLabel--popular']">
          {{ $t('popular') }}
        </div>
      </div>
    </div>

    <div v-if="news">
      <div class="ProductCard__label">
        <div :class="['PriceLabel', 'PriceLabel--new']">
          {{ $t('label-new') }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import settings from '@settings';
export default {
  name: 'ProductCardLabels',
  props: {
    label: {
      required: true,
    },
    popularity: {
      required: true,
    },
    demo: {
      required: true,
    },
    news: {
      required: true,
    },

    labelType: {
      required: true,
    },
    isOnSale: {
      required: true,
    },
    isClearance: {
      required: true,
    },
    salePercent: {
      required: true,
    },
  },
  data: function() {
    return {
      settings,
    };
  },
};
</script>
