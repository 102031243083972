<template>
  <CollapseTransition>
    <div v-if="show" class="Message" :class="['Message--' + type, { 'Message--overlay': overlay }]">
      <div class="Message__content">
        <div v-if="title" class="Message__title">{{ title }}</div>
        <slot />
        <button class="Message__close" @click="onClick"></button>
      </div>
    </div>
  </CollapseTransition>
</template>

<script>
import { CollapseTransition } from '@transitions';

export default {
  name: 'Message',

  components: {
    CollapseTransition,
  },

  props: {
    title: {
      type: String,
      default: '',
    },
    show: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      default: 'error',
    },
    overlay: {
      type: Boolean,
      default: false,
    },
  },

  methods: {
    onClick() {
      this.$emit('close');
    },
  },
};
</script>

<style></style>
