<template>
  <transition @beforeEnter="beforeEnter" @enter="enter" @leave="leave" mode="in-out">
    <slot />
  </transition>
</template>

<script>
import { gsap } from 'gsap/all';

export default {
  name: 'CollapseTransition',

  methods: {
    beforeEnter(element) {
      element.style.overflow = 'hidden';
      element.style.height = 0;
      element.style.opacity = 0;
    },
    enter(element, done) {
      requestAnimationFrame(() => {
        requestAnimationFrame(() => {
          gsap.to(element, {
            height: element.scrollHeight,
            opacity: 1,
            duration: 0.3,
            overwrite: true,
            onComplete() {
              element.style.height = null;
              element.style.overflow = null;
              element.style.opacity = null;
              done();
            },
          });
        });
      });
    },
    leave(element, done) {
      element.style.overflow = 'hidden';
      gsap.to(element, {
        height: 0,
        opacity: 0,
        duration: 0.3,
        overwrite: true,
        onComplete: done,
      });
    },
  },
};
</script>
