<template>
  <div class="PanelSkeleton">
    <div v-if="refinements" class="PanelSkeleton__refinements">
      <SkeletonGraphic v-for="i in refinements" :key="i" :width="100" :height="28" />
    </div>
    <div class="PanelSkeleton__items">
      <PanelItemSkeleton v-for="i in items" :key="i" :color="itemColor">
        <template #default>
          <slot name="itemContent" />
        </template>
        <template #controls>
          <slot name="itemControls" />
        </template>
      </PanelItemSkeleton>
    </div>
  </div>
</template>

<script>
import SkeletonGraphic from '../SkeletonGraphic';
import PanelItemSkeleton from './components/PanelItemSkeleton';
import { Modes } from '@scenes/SelfserviceScene/components/Panel/types';

export default {
  name: 'PanelSkeleton',

  components: {
    SkeletonGraphic,
    PanelItemSkeleton,
  },

  props: {
    items: {
      type: Number,
      default: 4,
    },
    refinements: {
      type: Number,
      default: 2,
    },
    itemColor: {
      type: String,
      default: Modes.LIGHT,
    },
  },
};
</script>

<style lang="scss" scoped>
.PanelSkeleton {
  display: flex;
  flex-direction: column;

  .PanelSkeleton__refinements {
    display: flex;
    gap: 40px;
    margin-bottom: 10px;
  }

  .PanelSkeleton__items {
    display: flex;
    flex-direction: column;
  }
}
</style>
