import config from '@config';
import { addFeatures } from '@plugins/feature-toggle';
import { AccountType } from '@types/Account';
import Features from '@types/Features';

const featuresSettings = [
  {
    key: Features.QR_SCANNERS,
    domains: ['THG'],
  },
  {
    key: Features.WHEELSTORAGE,
    domains: ['THG'],
  },
  {
    key: Features.ACCOUNT_RETURNS,
    domains: [],
    accountTypes: [AccountType.PRIVATE, AccountType.DEALER],
  },
  {
    key: Features.ACCOUNT_COMPLAINT,
    domains: [],
    accountTypes: [AccountType.PRIVATE, AccountType.DEALER],
  },
  {
    key: Features.ACCOUNT_TRANSACTIONS,
    domains: ['VAU', 'THG', 'TNO'],
    accountTypes: [AccountType.DEALER],
  },
  {
    key: Features.ORDER_TRANSFER,
    domains: [],
    accountTypes: [AccountType.PRIVATE, AccountType.DEALER],
  },
  {
    key: Features.ACCOUNT_WHEELSTORAGE_TRANSFER,
    domains: [],
    accountTypes: [AccountType.PRIVATE, AccountType.DEALER],
  },
  {
    key: Features.ACCOUNT_GARAGE,
    domains: [],
    accountTypes: [AccountType.PRIVATE],
  },
  {
    key: Features.ACCOUNT_WHEELSTORAGE,
    domains: ['THG'],
    accountTypes: [AccountType.PRIVATE],
  },
  {
    key: Features.ACCOUNT_USERS,
    domains: ['VAU'],
    accountTypes: [AccountType.DEALER],
  },
  {
    key: Features.ACCOUNT_PROFILE,
    domains: ['VAU', 'THG', 'TNO'],
    accountTypes: [AccountType.PRIVATE, AccountType.DEALER],
  },
  {
    key: Features.ACCOUNT_DRAFTS,
    domains: ['VAU', 'THG', 'TNO'],
    accountTypes: [AccountType.DEALER],
  },
  {
    key: Features.ACCOUNT_FAVORITES,
    domains: [],
    accountTypes: [AccountType.PRIVATE],
  },
  {
    key: Features.ACCOUNT_BOOKINGS,
    domains: [],
    accountTypes: [AccountType.PRIVATE],
  },
  {
    key: Features.ACCOUNT_CARSPOT,
    domains: ['VAU'],
    accountTypes: [AccountType.DEALER],
  },
  {
    key: Features.ACCOUNT_SETTINGS,
    domains: [],
    accountTypes: [AccountType.DEALER],
  },
  {
    key: Features.ACCOUNT_DELIVERY,
    domains: ['VAU'],
    accountTypes: [AccountType.DEALER],
  },
  {
    key: Features.ACCOUNT_PROFILE_BRANCH,
    domains: ['THG', 'TNO'],
    accountTypes: [AccountType.PRIVATE],
  },
  {
    key: Features.ACCOUNT_PROFILE_NEWSLETTER,
    domains: [],
    accountTypes: [AccountType.PRIVATE],
  },
  {
    // Skal denne være her?
    key: Features.VEHICLE_ID_VISIBLE,
    domains: ['VAU', 'THG', 'TNO'],
    accountTypes: [AccountType.DEALER],
  },
  {
    key: Features.FIREWORKS_QUICK_NUMBER,
    domains: ['THG'],
    accountTypes: [],
  },
];

export function getFeaturesByAccountType(type) {
  return featuresSettings.reduce((enabledFeatures, feature) => {
    if (feature.domains.includes(config.DOMAIN_CODE) && feature.accountTypes.includes(type)) {
      enabledFeatures.push(feature.key);
    }
    return enabledFeatures;
  }, []);
}

export function updateFeatures(accountType) {
  const features = featuresSettings.reduce((enabledFeatures, feature) => {
    if (feature.domains.includes(config.DOMAIN_CODE)) {
      if (!feature.accountTypes || feature.accountTypes.length === 0) {
        enabledFeatures.push(feature.key);
      } else if (accountType && feature.accountTypes.includes(accountType)) {
        enabledFeatures.push(feature.key);
      }
    }
    return enabledFeatures;
  }, []);

  // TODO: We need a way to handle features added by js variables. Comming from asp settings (settings-session.asp)
  if (window.globalvar_fireworks_club_benefits) {
    features.push(Features.FIREWORKS_CLUB_BENEFITS);
  }

  if (features.length > 0) addFeatures(features);
}
